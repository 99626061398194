const cn = {
    message: {
        common: {
            LogOut: '退出登录',
            revisePwd: '修改密码',
            title: '智能分析系统',
            confirm: '确定',
            cancel: '取消',
            save: "保存",
            delete: '删除',
            choose: '请选择',
            chosen: '已选择',
            input4_16: '请输入4-16个字符',
            submit: '提交',
            groupName: '分组名称',
            alertName: '识别分组',
            timeSpan: '时间段(00:00:00-00:00:00 全天有效)',
            timeSpan1: '(时间区间为：00:00-00:00 全天有效)',
            timeAny: '任意时间段',
            num: '序号',
            more: '操作',
            viewEdit: '编辑',
            default: '默认',
            success: '提交成功',
            loading: '等待响应中……',
            all: '全选',
            search: '搜索',
            description: '描述',
            enable: '开启',
            disable: '关闭',
            liveness: '活体识别',
            feature: '人脸特征入库',
            prev: '上一页',
            next: '下一页',
            page: ' 条/页',
            total: '共 ',
            from: '从',
            // goon: '条之后开始',
            base: '底库',
            exit: '退出',
            detail: '详情',
            item: '条',
            age: '年龄',
            gender: '性别',
            glass: '眼镜',
            mask: '口罩',
            headwear: '发型/帽子',
            male: '男',
            female: '女',
            less: '少发',
            short: '短发',
            long: '长发',
            ack: '有',
            deny: '无',
            pageToken: '按页浏览',
            pageOffset: '按条查询',
            groupBusy: '系统正在进行人脸库处理，请稍后重试...',
            errInput: '请输入有效字符',
            saveImg: '保存图片',
            hair: '发型',
            hair0: '光头',
            hair1: '少量头发（包含秃顶）',
            hair2: '短发',
            hair3: '长发',
            beard: '胡子',
            beard0: '无胡子或者胡子不明显',
            beard1: '嘴唇上面的胡子',
            beard2: '络腮胡',
            hat: '帽子',
            hat0: '无帽子',
            hat1: '安全帽',
            hat2: '厨师帽',
            hat3: '学生帽',
            hat4: '头盔',
            hat5: '01小白帽',
            hat6: '头巾',
            hat7: '其他帽子',
            respirator: '口罩',
            respirator0: '无口罩',
            respirator1: '有口罩',
            respirator2: '有口罩',
            respirator3: '有口罩',
            respirator4: '有口罩',
            /* 结构化 */
            glasses: '眼镜',
            glasses0: '无眼镜',
            glasses1: '深色框透明眼镜',
            glasses2: '普通透明眼镜',
            glasses3: '墨镜',
            skin_color: '肤色',
            skin_color0: '黄皮肤',
            skin_color1: '白皮肤',
            skin_color2: '黑皮肤',
            skin_color3: '棕色皮肤',
            unknown: '未知',
            resolution: '分辨率',
            cannotEmpty: '不能为空',
            version: '版本号',
            deleteFailed: '删除失败',
            deleteSuccess: '删除成功',
            minLessThanMax: '最小值必须小于最大值',
            pleaseInput: '请输入',
            deleteSelected: '删除选中',
            deleteAll: '删除全部',
            add: '添加',
            affirmDelete: "确认删除吗？",
            remove: '移除',
            removeSelected: '移除选中',
            notDelete: '暂不删除',
            immediateTrigger: '立即触发',
            bindQuery: '绑定查询',
            personFace: '人脸图',
            clearAll: '清空全部',
            enterNumber: '请正确输入数字',
            submitSuccess: '提交成功',
            submitFail: '提交失败',
            loginAccountSuc: '登录账号密码配置成功',
            loginConfigSuc: '登录系统参数配置成功',
            loginAccountFail: '登录账号密码配置失败',
            loginConfigFail: '登录参数配置失败',
            loginSettingAllSuc: '登录配置参数提交成功',
            noneData: '暂无数据',
            userManual: '用户手册',
            apiDoc: '接口文档',
            startTime: '开始时间',
            endTime: '结束时间',
            lastTabTips: '当前只剩下最后一个标签，不可关闭',
            operationFailedTips: '以下为操作失败项，请重试',
            submitting: '提交中',
        },
        login: {
            passwordGradeLow:'您当前设置的密码安全等级过低，请重新设置',
            passwordIrregular:"密码强度要求为“强/中/弱”，请修改后重新提交",
            required:"必填",
            passwordLengthLimit:"密码长度需在8~32个字符之间",
            strongPassword:"强: 必须包含大写字母、小写字母、数字、特殊符号的任意3种组合",
            centrePassword:"中: 必须包含大写字母、小写字母、数字的任意1种和特殊符号的组合",
            weakPassword:"弱: 必须包含大写字母、小写字母、数字的任意两种组合",
            normalPassword:"密码长度为8-32个字符",
            oldPasswordError: "旧密码错误",
            oldPassword: "旧密码",
            newPassword: "新密码",
            affirmnewPassword: "确认新密码",
            confirmExit: '确认退出登录？',
            title: '智能分析系统',
            productName: '旷视魔方',
            account: '用户名',
            save: '记住账号',
            pwd: '密码 ',
            rePwd: '确认密码',
            // activationTitle:'',
            login: '登录',
            reset: '取消',
            check: '请输入正确的账号(提示: admin)',
            loginTip: '请先登录',
            unknownCode: '未知错误，错误码为',
            error: '请求失败',
            password: '(密码至少8位，由数字、大小写字母、特殊符号中的至少两种组成)',
            noAPI: '接口不存在',
            serverError: '服务器连接错误，请稍后重试',
            activationTitle: '设备激活',
            activationTips: '首次使用设备，请先激活',
            activation: '激活',
            activationSuccess: '激活成功',
            freeze: '连续5次输入密码错误，冻结5分钟！',
            enterPassword: '请输入密码',
            enteroldPassword: "请输入旧密码",
            enterPasswordAgain: '请再次输入密码',
            diffPassword: '两次输入密码不一致',
            freezeTip1: '当前账号已锁定，请',
            freezeTip2: '分钟后重试',
            errorTip1: '用户名或密码错误，请重新输入，重试次数剩余',
            errorTip2: '次',
            welcome: '欢迎登录',
            rememberPwd: '记住密码',
            loginToMore: '登录官方网站，了解更多'
        },
        menu: {
            monitor: '实时监控',
            alarm: '识别查询',
            alarmBind: '识别绑定',
            capture: '抓拍查询',
            structureCapture: '脸人&结构化抓拍',
            alertCapture: '警戒抓拍',
            personCount: '人数统计',
            captureBind: '抓拍绑定',
            group: '人脸分组',
            base: '人脸库',
            camera: '布点设置',
            picStation: '抓拍机',
            surve: '视频设置',
            relay: '继电器配置',
            faceCmp: '智能应用',
            previl: '通行权限',
            single: '1：1比对',
            multi: '1：N搜索',
            viewResolver: '视图解析',
            device: '系统设置',
            params: '系统参数',
            setting: '硬件设置',
            switch: '智能模式切换',
            file: '文件管理',
            audio: '音频文件',
            dataRecv: '数据对接',
            gat1400: 'GA/T 1400',
            gbt28181: 'GB/T 28181',
            ftpUpload: 'FTP推图',
            autoUpload: '主动上报',
            videoStructure: '智能预览',
            logs: '日志',
            algorithm: '算法仓管理',
            authorManagement: '授权管理',
        },
        monitor: {
            alarmTime: '报警时间',
            alarmSoruce: '报警来源',
            seeBigImg: '查看大图',
            drawShield: '绘制/显示屏蔽区域',
            roiLable: 'ROI配置',
            moveLines: '移动线条',
            camera: '视频通道',
            channelName: '布点名称',
            addDev: '请添加前端设备',
            drawRect: '画框',
            screens: '4分屏',
            screen: '1分屏',
            rtAlarm: '实时识别',
            all: '查看全部',
            alarmed: '识别时间',
            captureTime: '抓拍时间',
            rtCapture: '实时抓拍',
            fullCap: '查看整体抓拍',
            viewFullCap: '整体抓拍',
            capture1: '抓拍详情',
            group: '分组',
            alert: '识别分组',
            remark: '备注',
            date: '日期',
            capture: '识别详情',
            getCamFailed: '获取视频列表失败',
            getPic1080: '抓拍机没有接入主码流',
            detail: '详细信息',
            basePic: '查看详细信息',
            tips: '点击开启或关闭告警声音',
            videoEx: '视频流接入个数已达上限,无法预览',
            imgEx: '图片流接入个数已达上限,无法预览',
            subStreamEx: '副码流分辨率超过D1/WD1,打开失败',
            streamEx: '码流分辨率超过1080P,可能获取不到抓拍结果',
            videoCfg: '当前通道未接入副码流！',
            vType: '当前视频流格式非H.264编码，视频无法播放！',
            pointLimit: '点击视频区域,以生成由3 - 5个点构成的ROI区域',
            saveRect: '保存',
            drawTips: '开始绘制',
            clearRect: '清除绘制',
            disabledROI: 'ROI功能未开启',
            videoErr3: '通道号范围有误',
            videoErr4: '错误的请求数据类型',
            videoErr5: '图片流未配置',
            videoErr6: '已被删除',
            videoErr7: '连接失败',
            videoErr8: '认证失败',
            videoErr9: '视频流格式错误',
            videoErr10: '超出最大分辨率',
            videoErr11: '解码失败',
            videoErr12: '超出最大解码能力',
            noDisplay: '抓拍存储功能未打开，无预览',
            noDisplay1: '未开启抓拍背景图开关',
            beyondLimit: '超过点数,清空,重新标注',
            noIntersection: '不能有交叉的区域',
        },
        alert: {
            alert: '识别查询',
            faceImg: '抓拍人脸',
            chnName: '布点名称',
            score: '对比分数',
            downVideo: '下载录像',
            delAlarm: '删除',
            detail: '详细信息',
            deduplicate: '去重',
            duplicate: '不去重',
            frequency: '频次',
            age: '年龄',
            gender: '性别',
            gender0: '男',
            gender1: '女',
            gender2: '未知',
            hair: '发型',
            hair0: '光头',
            hair1: '少量头发（包含秃顶）',
            hair2: '短发',
            hair3: '长发',
            hair4: '未知',
            beard: '胡子',
            beard0: '无胡子或胡子不明显',
            beard1: '嘴唇上面的胡子',
            beard2: '络腮胡',
            beard3: '未知',
            hat: '帽子',
            hat0: '无帽子',
            hat1: '安全帽',
            hat2: '厨师帽',
            hat3: '学生帽',
            hat4: '头盔',
            hat5: '01小白帽',
            hat6: '头巾',
            hat7: '其他帽子',
            hat8: '未知',
            respirator: '口罩',
            respirator0: '无口罩',
            respirator1: '有口罩',
            respirator2: '有口罩',
            respirator3: '有口罩',
            respirator4: '有口罩',
            respirator5: '未知',
            glasses: '眼镜',
            glasses0: '无眼镜',
            glasses1: '深色框透明眼镜',
            glasses2: '普通透明眼镜',
            glasses3: '墨镜',
            glasses4: '未知',
            glasses32: '未知',
            glasses33: '深色框透明眼镜',
            glasses34: '普通透明眼镜',
            glasses35: '墨镜',
            glasses36: '其他',
            memberAll: '全部',
            memberBase: '底库人员',
            memberNoBase: '非底库人员',
            faceTokenPlaceholder: '请输入face token',
            objTypesAll: '全部',
            objTypesVehicle: '机动车',
            objTypesCycle: '非机动车',
            objTypesHuman: '人员',
            // 3.0新增字段
            maskStatus: '口罩状态',
            glassesStatus: '眼镜状态',
            hairStatus: '头发状态',
            hatStatus: '帽子状态',
            areaClass_1: '单向单绊线',
            areaClass_2: '双向单绊线',
            areaClass_3: '单向双绊线',
            areaClass_4: '双向双绊线',
            areaClass_5: '区域',
            areaClass_crossline: '出入口绊线',
            areaType_SINGLE_LINE: '单向单绊线',
            areaType_SINGLE_LINE_BOTH: '双向单绊线',
            areaType_MULTI_LINE: '单向双绊线',
            areaType_MULTI_LINE_BOTH: '双向双绊线',
            areaType_POLYGON: '区域',
            tripwire_class: '越界类型',
            tripwire_class_1: '进入区域',
            tripwire_class_2: '离开区域',
            tripwire_class_3: '绊线进入',
            tripwire_class_4: '绊线离开',
            tripwire_class_5: '攀爬',
            tripwire_class_6: '翻墙检测',
            tripwire_class_7: '越界',
            // 3.2新上报参数
            subType: '越界类型',
            subType_PRE_TRIPWIRE: '越界',
            subType_PRE_CLIMB: '攀爬',
            subType_PRE_OVERWALL: '翻墙检测',
            subType_CROSS_LINE_IN: '绊线进入',
            subType_CROSS_LINE_OUT: '绊线离开',
        },
        capture: {
            capture: '抓拍查询',
            autoRefresh: '自动刷新',
            refresh: '刷新',
            chnName: '布点名称',
            captured: '抓拍时间',
            entered: '进入时间',
            departure: '离开时间',
            deleteCap: '删除抓拍',
            prev: '上一个',
            next: '下一个',
        },
        group: {
            groupName: '分组名称',
            facePos: '人脸位置',
            face: '人脸图',
            unbind: '解除绑定',
            group: '人脸分组',
            new: '新建分组',
            groupNum: '分组人数',
            delGroup: '删除分组',
            delGroupTip1: '不删除组中的人脸',
            delGroupTip2: '删除该组中所有的人脸，同时解除组中人脸与其它组中的关联关系',
            delGroupTip3: '清除分组中全部人脸，并保留当前分组',
            unbindFG: '此人脸将解除与该组的关联关系！',
        },
        library: {
            failList: '入库失败列表',
            clearList: '清空失败列表',
            imageName: '图片名称',
            detail: '失败原因',
            time: '时间',
            downImg: '下载人脸图',
            face: '人脸库',
            single: '单张入库',
            import: '提交入库',
            singleSend: '一次发送',
            top: '一次上限',
            bind: '关联分组',
            delOrig: '删除原始图片',
            image: '人像',
            imported: '返回信息',
            importing: '正在上传',
            facePos: '人脸位置',
            groupList: '分组列表',
            batchImport: '批量入库',
            batchChoose: '批量入库',
            clickChoose: '选择文件夹',
            clickGroup: '请点击右侧分组列表选择分组',
            groupSelected: '请选择分组',
            upProgress: '上传进度',
            process: '处理进度',
            term: '终止上传',
            done: '上传完毕',
            stop: '已终止',
            ctnue: '继续上传',
            success: '上传成功',
            fail: '上传失败',
            total: '图片总量',
            elapsed: '已用时',
            remain: '剩余时间',
            expected: '预上传总数',
            received: '实际接收个数',
            importSuccess: '入库成功',
            importFail: '入库失败',
            file: '文件',
            faces: '人脸图',
            group: '所属分组',
            bindGroup: '关联分组',
            confirmlearFailed: '确定要清空失败列表吗？',
            clearFailed: '清空失败列表',
            confirmInit: '该操作将删除所有入库的原始照片与人脸照片，并删除所有关联关系，无法查看历史记录！',
            init: '初始化人脸库',
            confirmClose: '入库处理还未完成，关闭弹窗后将无法查看处理结果，确认关闭弹窗吗？',
            tips: '提示',
            netInter: '网络或内部错误',
            unknownCode: '未知错误',
            serverError: '服务器连接错误，请稍后重试',
            upError: '请求失败',
            stopConfirm: '确认要终止正在上传的文件吗？',
            stopImport: '立即终止',
            upPrgressError: '获取入库处理进度失败',
            error: '请求失败',
            unbindAll: '该操作将解绑此人脸的所有分组！',
            delFace: '删除人脸',
            confirmDelImg: '删除原始图片后将无法恢复！',
            delOrigin: '删除原始图片',
            describe: '请输入描述',
            notice: '说明：',
            imgType: '图片类型：JPG、JPEG、PNG、JFIF或BMP',
            imgSize: '图片大小 ≤ 4MB',
            imgMax: '图片最大尺寸 ≤ 4096*4096',
            imgMin: '图片最小尺寸 ≥ 100*100',
            imgFormat: '图片格式不正确',
            imgLimit: '图片大小不能超过4M',
            imgNum: '每张图片中只能有一张人脸，如有多张脸取最大',
            INVALID_RESOLUTION: '无效分辨率',
            INVALID_TYPE: '无效类型',
            INVALID_SIZE: '无效大小',
            BLURNESS: '太模糊',
            POSE: '角度太大',
            BLACK_WHITE: '黑白比例过大',
            FACE_TOO_SMALL: '人脸太小',
            FACE_NOT_FOUND: '没有检测到人脸',
            IMAGE_ALREADY_EXIST: '人脸已经存在',
            SPACE_IS_FULL: '硬盘满或人脸个数超限',
            IMAGE_MD5_CHECK_FAIL: '图片md5校验失败',
            IMAGE_DECODE_FAIL: '图片解码失败',
            IMAGE_RESIZE_FAIL: '图片resize失败',
            READ_FILE_FAIL: '读取文件失败',
            FACE_ALREADY_EXIST: '人脸已经存在',
            FACE_TOKEN_ALREADY_EXIST: "人脸face_token已经存在",
            INNER_ERR: "通用错误",
            detectedFaceNum: '检测人脸数',
            compareFailedFaceNum: '比对失败数',
            checkFile: '请选择文件夹',
        },
        station: {
            editItem: '编辑布点',
            newItem: '新建布点',
            loading: '等待响应中……',
            info: '基本信息',
            chnNo: '通道号',
            chnName: '布点名称(通道名称)',
            chnNameTip: '最大长度50个英文字符，一个中文占3字符',
            channelNoTip: '对接云桥和昆仑平台11到13位建议使用131或132',
            chnNameEx: '超过字符最大限制',
            chnType: '布点类型',
            deviceNo: '设备编号',
            channelNo: '通道编号',
            chnAddress: '布点地址',
            user: '用户名',
            pwd: '密码',
            inputUser: '请输入用户名',
            inputPwd: '请输入密码',
            rotation: '旋转角度',
            filter: '抓拍过滤条件',
            paraRecog: '参数识别',
            roll: 'Roll旋转角度',
            yaw: 'Yaw水平角度',
            pitch: 'Pitch垂直角度',
            bright: '亮度',
            deviation: '对比度',
            blurness: '模糊度',
            faceMin: '最小人脸',
            faceMinTips: '(小于50或会影响识别精度)',
            thHigh: '识别高阈值',
            thLow: '识别低阈值',
            liveness: '活体阈值',
            bind: '绑定分组',
            config: '布点设置',
            total: '总数:  ',
            source: 'RTSP流地址',
            delete: '删除后将无法恢复！',
            deleteCam: '删除布点',
            triggerSet: '触发设置',
            triggerAdd: '增加设置',
            triggerDelete: '删除设置',
            triggerMode: '触发方式',
            triggerCapture: '抓拍触发',
            triggerRecognize: '识别触发',
            triggerCaptureRecognize: '抓拍&识别触发',
            triggerHttpMode: '通信方式',
            enable: '布控开关',
            paramTips: '白名单模式下高阈值推荐66,黑名单模式下高阈值推荐71',
            paramTips1: '高阈值推荐68',

            gbKinpol: '全局陌生人',
            onvifDetect: 'Onvif搜索',
            onvifList: '设备列表',
            onvifSelected: '地址',
            onvifGets: '获取RTSP地址',
            onlineState: '连接状态',
            onlineState0: '在线',
            onlineState1: '不在线',
            onlineState2: '其他错误',
            channelType: '通道类型',
            channelType0: '脸人绑定及识别',
            channelType1: '全结构化解析',
            channelType10: '周界警戒',
            channelType11: '行为警戒',
            channelType21: '周界警戒',
            channelType22: '行为警戒',
            // channelType12: '城管',
            channelType13: '安监',
            channelType19: '加油站',
            // channelType14: '警戒',
            channelType15: '明厨亮灶',
            channelType16: '物品',
            channelType17: '消防',
            channelType17s: '社区消防',
            channelType20: '人数统计',
            channelType24: '高空抛物',
            capSettings: '脸人&结构化规则配置',
            analyse: '解析目标属性',
            videoConStatus: '视频流连接状态',
            videoConStatus0: '连接成功',
            videoConStatus1: '连接失败',
            videoConStatus2: '认证失败',
            videoConStatus3: '视频流格式错误',
            videoConStatus4: '超出通道最大分辨率',
            videoConStatus5: '解码失败',
            videoConStatus6: '超出最大解码能力',
            videoConStatus7: 'H265多Tile模式不支持',
            registerStatus: '注册状态',
            registerStatus0: '未注册',
            registerStatus1: '已注册',
            bodyMin: '最小人形',
            livenessEnabled: '活体识别开关',
            bestStrangerUpload: '上报全局最优陌生人',
            siteSurveyGuideDocDownLoad: '工勘指导文档下载',
            siteSurveyGuideDocDownLoadTips: '提示：绘制规则可参考工勘指导文档',
            alarmConditionTextFall:
                '当目标人员出现以下行为时触发报警：<br/>推荐场景1：俯摔，腹部和手臂着地<br/>次推荐场景1：仰摔，臀部及以上身体部位着地<br/>次推荐场景2：趴摔，臀部和手臂着地',
            alarmConditionTextSmoke:
                '当目标人员出现以下行为时触发报警：<br/>推荐场景1：手持烟放置在嘴边，且烟在画面中清晰<br/>推荐场景2：嘴叼烟，烟在画面中清晰<br/>不推荐场景1：手持烟，未放置嘴边',
            alarmConditionTextCall:
                '当目标人员出现以下行为时触发报警：<br/>推荐场景1：手持手机放置在耳边，手机在画面中清晰<br/>不推荐场景1：手部遮挡手机，无法清晰的看到手机',
            alarmConditionTextWatchPhone:
                '当目标人员出现以下行为时触发报警：<br/>推荐场景1：手持手机放置在人员胸前，手机在画面中清晰<br/>不推荐场景1：画面中手机较小或不清晰',
            alarmConditionTextRun:
                '当目标人员出现以下行为时触发报警：<br/>推荐场景1：画面中人员清晰的显示手臂摆动且腿部弯曲<br/>不推荐场景1：手臂和腿部正对摄像头，无法判断是否是跑步姿势',
            alarmConditionTextSleep:
                '当目标人员出现以下行为时触发报警：<br/>推荐场景1：侧视视角，人员手臂放置在桌子上，头部接触手臂，且头，手，桌三者的接触关系无遮挡<br/>次推荐场景1：俯视视角，可能出现人员低头误识报警',
            alarmConditionTextGathering:
                '当目标人员出现以下行为时触发报警：<br/>推荐场景1：多个人员（下限为聚众人数设定值）目标框存在接触或重叠，且不发生移动<br/>不推荐场景1：多个人员目标框存在接触或重叠，但持续移动',
            alarmConditionTextFight:
                '当目标人员出现以下行为时触发报警：<br/>推荐场景1：有剧烈的攻击动作（持续击打，踢踹），且人员之间存在肢体碰撞；<br/>不推荐场景1：无肢体碰撞，无明显攻击动作',
            alertSettings: '规则配置',
            alertEnable: '警戒使能',
            alertType: '报警类型',
            alertSubType: '报警子类型',
            personPreOverWall: '翻墙检测预警',
            preOverWall: '翻墙预警',
            areaType: 'ROI类型',
            detectTarget: '检测目标',
            detectTargetMotorVehicle: '机动车',
            detectTargetNonMotorVehicle: '非机动车',
            clearAlertArea: '清除警戒线',
            clearMaskArea: '清除屏蔽区域',
            drawAreaTips: '提示: 多个子规则共享屏蔽区域，屏蔽区域最多2个且不能重叠',
            targetRange: '目标大小',
            timeRange: '布控时段',
            stayDuration: '延时报警',
            stayDuration2: '起火确认时间',
            cooldownDuration: '报警间隔',
            disappearDuration: '消警观察时间',
            humCross: '人员越界',
            humInva: '人员入侵',
            vehiCross: '车辆越界',
            vehiStop: '车辆禁停',
            vehiLeave: '离开',
            humWander: '人员徘徊',
            humWall: '人员翻墙',
            fireAlarm: '烟火报警',
            wallDetec: '翻墙检测',
            fireDetect: '烟火检测',
            singleDetec: '单绊线检测',
            intellWarn: '智能预警',
            imArea: '进入区域',
            exArea: '离开区域',
            imExArea: '进出区域',
            areaStay: '区域停留',
            single_line: '单向单绊线',
            single_line_both: '双向单绊线',
            multi_line: '单向双绊线',
            multi_line2: '单向多绊线',
            multi_line_both: '双向双绊线',
            polygon: '区域',
            fireExtinguisherNum: '消防设备个数',
            targetNum: '目标个数',
            objCount: '聚众人数',
            dutyRatio: '区域比例',
            range: '范围',
            hour: '时',
            minute: '分',
            second: '秒',
            needMultiLine: '双绊线必须绘制两条线',
            fillAlertTime: '请填写完整的报警时间',
            alarmThreshold: '报警阈值',

            allNo: '全部设备',
            capPic: '抓拍图',
            targetType: '目标类型',
            detectAlarmList: '全部报警类型',

            alertSubType0: '未知',
            alertSubType1: '单绊线检测',
            alertSubType2: '进入区域',
            alertSubType3: '离开区域',
            alertSubType4: '进出区域',
            alertSubType5: '区域停留',
            alertSubType6: '翻墙检测',
            alertSubType7: '烟火检测',
            alertSubType8: '绊线进入',
            alertSubType9: '绊线离开',

            verifyTime: '开始时间不能等于结束时间!',
            verifyTime2: '开始时间不能大于或等于结束时间!',
            verifyTime1: '请选择时间段!',

            areaType0: '未知',
            areaType1: '单向单绊线',
            areaType2: '双向单绊线',
            areaType3: '单向双绊线',
            areaType4: '双向双绊线',
            areaType5: '区域',

            ruleLabel: '规则',
            ruleEnable: '启用',
            ruleDisable: '未启用',
            subRuleLabel: '子规则',
            needAlertLine: '需要绘制警戒线',

            // 算法目标类型
            targetType_face: '人脸',
            targetType_person: '人体',
            targetType_car: '机动车',
            targetType_cycle: '非机动车',
            targetType_plate: '车牌',
            targetType_head: '人头',
            targetType_flame: '火焰',
            targetType_crowd: '聚众整体',
            targetType_fighter: '打架者',
            targetType_smog: '烟雾',
            targetType_oil_spill: '油品',
            targetType_fire_equipment: '消防设备',
            targetType_trashbin: '垃圾桶',
            targetType_mice: '老鼠',
            targetType_sundry: '杂物',

            targetType_chef_cloth: '厨师服',
            targetType_chef_hat: '厨师帽子',
            targetType_face_respirator: '口罩',
            targetType_glove: '橡胶手套',
            targetType_electric_bike: '电瓶车',
            targetType_goods: '物品',
            targetType_garbage: '垃圾',
            targetType_falling_goods: '抛物',

            targetType_motor: '机动车',
            targetType_indicator_flag: '静电线（指示旗）',
            targetType_oil_pipe: '输油管',
            targetType_oilpump_door: '油箱门',

            // 算法目标类型---大写
            targetType_FACE: '人脸',
            targetType_PERSON: '人体',
            targetType_CAR: '机动车',
            targetType_CYCLE: '非机动车',
            targetType_PLATE: '车牌',
            targetType_HEAD: '人头',
            targetType_FLAME: '火焰',
            targetType_CROWD: '聚众整体',
            targetType_FIGHTER: '打架者',
            targetType_SMOG: '烟雾',
            targetType_OIL_SPILL: '油品',
            targetType_FIRE_EQUIPMENT: '消防设备',
            targetType_TRASHBIN: '垃圾桶',
            targetType_MICE: '老鼠',
            targetType_SUNDRY: '杂物',

            targetType_CHEF_CLOTH: '厨师服',
            targetType_CHEF_HAT: '厨师帽子',
            targetType_FACE_RESPIRATOR: '口罩',
            targetType_GLOVE: '橡胶手套',
            targetType_ELECTRIC_BIKE: '电瓶车',
            targetType_GOODS: '物品',
            targetType_GARBAGE: '垃圾',
            targetType_FALLING_GOODS: '抛物',

            targetType_MOTOR: '机动车',
            targetType_INDICATOR_FLAG: '静电线（指示旗）',
            targetType_OIL_PIPE: '输油管',
            targetType_OILPUMP_DOOR: '油箱门',

            // 3.2完善目标类型
            targetType_WALLET: '钱包',
            targetType_ILLEGALBAG: '非法箱包',
            targetType_FIRE: '火焰',
            targetType_SMOKE: '烟雾',
            targetType_LADDER: '梯子',
            targetType_INDICATOR: '设备指示灯',
            targetType_GAS_CYLINDER: '气瓶',
            targetType_SLOGAN: '标语',

            // 3.0新增，按需加入
            targetType_PHONE: '手机',
            targetType_BAG: '包',

            // 目标类型定义参考：https://git-pd.megvii-inc.com/bistro/ai-paas-pb/-/blob/main/protobuf/aipaas/v1/algo_cabin_custom.proto
            // 对象类型/目标类型 3.0告警记录详情中使用，未在en.js添加
            // objectClass_1: '人脸',
            // objectClass_2: '人体',
            // objectClass_3: '机动车',
            // objectClass_4: '非机动车',
            objectClass_5: '车牌',
            objectClass_6: '人头',
            objectClass_7: '火焰',
            objectClass_8: '聚众整体',
            objectClass_9: '打架者',
            objectClass_10: '烟雾',
            objectClass_11: '油品',
            objectClass_12: '消防设备',
            objectClass_13: '垃圾桶',
            objectClass_14: '老鼠',
            objectClass_15: '杂物',
            objectClass_16: '未穿厨师服',
            objectClass_17: '未戴厨师帽',
            objectClass_18: '未戴口罩',
            objectClass_19: '未戴手套',
            objectClass_20: '静电线（指示旗）',
            objectClass_21: '卸油管',
            objectClass_22: '电瓶车',
            objectClass_23: '手',

            objectClass_25: '物品',
            objectClass_26: '油箱门',
            objectClass_27: '机动车/油罐车',
            objectClass_28: '社区垃圾',
            objectClass_29: '抛物',
            objectClass_30: '经营类',
            objectClass_31: '杂物类',
            objectClass_32: '普通垃圾',
            objectClass_33: '广告',
            objectClass_34: '地面积水',
            objectClass_35: '手机',
            objectClass_36: '包',
            objectClass_37: '叉车',
            objectClass_38: '遮挡',
            objectClass_39: '皮带',

            // 新---目标类型
            objectClass_1: '人体',
            objectClass_2: '人脸',
            objectClass_4: '人头',

            objectClass_3: '一帧的图片的原图',

            objectClass_1000: '牲畜',
            // 交通工具相关（1XXXX）
            // objectClass_10001: '',
            // objectClass_10002: '',
            objectClass_10003: '机动车',
            objectClass_10004: '非机动车',
            // objectClass_10005: '电动自行车',
            objectClass_10005: '电瓶车', // 测试建议修改
            // 和人相关的日常物品（2XXXX）
            objectClass_20001: '手机',
            objectClass_20002: '包',
            objectClass_20003: '钱包',
            objectClass_20004: '物品',
            objectClass_20005: '非法箱包',

            objectClass_30001: '火焰',
            objectClass_30002: '烟雾',
            objectClass_30003: '消防设施',
            objectClass_30004: '油品泄漏',
            objectClass_30005: '梯子',
            objectClass_30006: '设备指示灯',
            objectClass_30007: '气瓶',
            objectClass_30008: '杂物',
            objectClass_30009: '标语',
            objectClass_30010: '高空抛物',
            // 明厨亮灶（4XXXX）
            objectClass_40001: '垃圾桶',
            objectClass_40002: '老鼠',
            // 城管算法仓 (5XXXX)
            objectClass_50001: '店外经营',
            objectClass_50002: '流动小贩，无照经营游商',
            objectClass_50003: '占道经营',
            objectClass_50004: '杂物堆放',
            objectClass_50005: '暴露垃圾',
            objectClass_50006: '机动车乱停放',
            objectClass_50007: '非机动车乱停放',
            objectClass_50008: '垃圾满溢',
            objectClass_50009: '违规户外广告',
            objectClass_50010: '非法小广告',
            objectClass_50011: '沿街晾晒',
            objectClass_50012: '积存垃圾渣土',
            objectClass_50013: '道路不洁',
            objectClass_50014: '私搭乱建',
            objectClass_50015: '公共设施损坏（破损井盖）',
            objectClass_50016: '渣土车未密闭运输',
            objectClass_50017: '乱拉横幅，违规悬挂横幅',
            objectClass_50018: '道路破损',
            objectClass_50019: '道路积水',
            objectClass_50020: '水域不洁',
            objectClass_50021: '水域秩序',
            objectClass_50022: '绿地踩踏',
            objectClass_50023: '渣土车',
            // 加油站算法仓 (6XXXX)
            objectClass_60001: '指示旗',
            objectClass_60002: '油仓门',
            objectClass_60003: '导油管',

            // 工服颜色
            allowedColors: '工服颜色',
            allowedColors_red: '红色',
            allowedColors_blue: '蓝色',
            allowedColors_yellow: '黄色',
            allowedColors_gray: '灰色',
        },
        picStation: {
            editItem: '编辑布点',
            newItem: '新建布点',
            loading: '等待响应中……',
            info: '基本信息',
            chnNo: '通道号',
            chnName: '设备名称',
            chnNameTip: '最大长度50个英文字符，一个中文占3字符',
            chnNameEx: '超过字符最大限制',
            user: '用户名',
            pwd: '密码',
            devOnline: '在线状态',
            onlineY: '在线',
            onlineN: '离线',
            inputUser: '请输入用户名',
            inputPwd: '请输入密码',
            rotation: '旋转角度',
            filter: '抓拍过滤条件',
            paraRecog: '参数识别',
            roll: 'Roll旋转角度',
            yaw: 'Yaw水平角度',
            pitch: 'Pitch垂直角度',
            bright: '亮度',
            deviation: '对比度',
            blurness: '模糊度',
            faceMin: '最小人脸',
            faceMinTips: '(小于50或会影响识别精度)',
            bodyMin: '最小人体',
            thHigh: '识别高阈值',
            thLow: '识别低阈值',
            liveness: '活体阈值',
            bind: '绑定分组',
            config: '抓拍机设置',
            total: '总数:  ',
            source: '设备标识',
            delete: '删除后将无法恢复！',
            deleteCam: '删除布点',
            triggerSet: '触发设置',
            triggerAdd: '增加设置',
            triggerDelete: '删除设置',
            triggerMode: '触发方式',
            triggerCapture: '抓拍触发',
            triggerRecognize: '识别触发',
            triggerCaptureRecognize: '抓拍&识别触发',
            triggerHttpMode: '通信方式',
            enable: '布控开关',
            paramTips: '白名单模式下高阈值推荐66,黑名单模式下高阈值推荐71',
            copyOk: '复制成功',

            itemType: '布点类型',
            itemTypeTips: '请选择布点类型',
            deviceIdTips: '请填写设备标识',
            ipAddrTips: '请填写ip地址',
            portTips: '请填写端口号',
            deviceSnTips: '请填写SN号',
            privateAgreement: '私有协议',
            ipAddr: 'IP地址',
            port: '端口号',
            username: '用户名',
            password: '密码',
            ipAddr2: 'IP地址(*必填)',
            port2: '端口号(*必填)',
            username2: '用户名(*必填)',
            password2: '密码(*必填)',
            source2: '设备标识(*必填)',
            register: '主动注册',
            SN: 'SN号',
            SN2: 'SN号(*必填)',
        },
        structure: {
            portraitC: '人像抓拍',
            vehicleC: '机动车抓拍',
            nonVehicleC: '非机动车抓拍',
            noCapBig: '暂无抓拍大图',
            noCapDetail: '暂无抓拍详细信息',
            path: '路',

            videoStructure: 'Analyse',
            gender: '性别',
            gender1: '未知',
            gender2: '男',
            gender3: '女',

            eye: '眼睛',
            eye0: '未知',
            eye1: '左眼闭眼',
            eye2: '左眼睁开',
            eye16: '右眼闭眼未知',
            eye17: '右眼闭眼',
            eye18: '右眼睁开',

            mouth: '嘴部状态',
            mouth0: '未知',
            mouth1: '闭嘴',
            mouth2: '张嘴',

            skinColor: '肤色',
            skinColor0: '未知',
            skinColor1: '黄皮肤',
            skinColor2: '白皮肤',
            skinColor3: '黑皮肤',
            skinColor4: '棕色皮肤',

            hair: '发型',
            // hair0: '未知',
            // hair1: '光头',
            // hair2: '少量头发（包含秃顶）',
            // hair3: '短发',
            // hair4: '长发',
            // hair5: '其他发型',
            hair1: '未知', // 新增，en.js未添加
            hair2: '平头',
            hair3: '中分',
            hair4: '偏分',
            hair5: '额秃',
            hair6: '项秃',
            hair7: '全秃',
            hair8: '卷发',
            hair9: '波浪发',
            hair10: '麻花辫',
            hair11: '盘发',
            hair12: '披肩',
            hair13: '短发',
            hair14: '长发',

            beard: '胡子',
            // beard0: '未知',
            // beard1: '无胡子或者胡子不明显',
            // beard2: '小胡子，嘴唇上面的胡子',
            // beard3: '腮须',
            // beard4: '其他类型',
            beard1: '未知',
            beard2: '无胡子',
            beard3: '八字胡',
            beard4: '络腮胡',
            beard5: '一字胡',
            beard6: '淡胡子',
            beard7: '山羊胡',
            beard8: '花白胡',
            beard9: '一点胡',

            hat: '帽子',
            // hat0: '未知',
            // hat1: '戴帽子',
            // hat2: '不戴帽子',
            hat1: '未知',
            hat2: '不戴帽子',
            hat3: '戴帽子',

            hat16: '帽子颜色未知',
            hat17: '白色',
            hat18: '其他',
            hat48: '安全帽',
            hat49: '厨师帽',
            hat50: '学生帽',
            hat51: '头盔',
            hat52: '安防全人种01小白帽',
            hat53: '头巾',
            hat54: '其他帽子',
            hat55: '未知类型',

            respirator: '口罩',
            respirator1: '未知',
            respirator2: '不戴口罩',
            respirator3: '戴口罩',
            respirator16: '医用口罩',
            respirator17: '雾霾口罩',
            respirator18: '普通口罩',
            respirator19: '未知类型',
            respirator20: '厨房透明口罩',

            glasses: '眼镜',
            glasses1: '未知',
            glasses2: '不戴眼镜',
            glasses3: '戴眼镜',
            glasses16: '右眼是否戴眼镜未知',
            glasses17: '右眼戴眼镜',
            glasses18: '右眼不戴眼镜',
            glasses32: '左眼眼镜类型未知',
            glasses33: '左眼深色框透明眼镜',
            glasses34: '左眼普通透明眼镜',
            glasses35: '左眼太阳墨镜',
            glasses36: '左眼其他',
            glasses48: '右眼眼镜类型未知',
            glasses59: '右眼深色框透明眼镜',
            glasses50: '右眼普通透明眼镜',
            glasses51: '右眼太阳墨镜',
            glasses52: '右眼其他',

            clothes: '上衣颜色和款式',
            // clothes0: '上衣颜色未知',
            // clothes1: '黑色上衣',
            // clothes2: '白色上衣',
            // clothes3: '灰色上衣',
            // clothes4: '红色上衣',
            // clothes5: '橙色上衣',
            // clothes6: '黄色上衣',
            // clothes7: '绿色上衣',
            // clothes8: '深蓝色上衣',
            // clothes9: '浅蓝色上衣',
            // clothes10: '紫色上衣',
            // clothes11: '粉红色上衣',
            // clothes12: '棕色上衣',
            // clothes13: '彩色上衣',
            // clothes32: '长短袖未知',
            // clothes33: '长袖',
            // clothes34: '短袖',
            clothes1: '未知',  // 更换枚举类型，en.js未更换
            clothes2: '黑色',
            clothes3: '白色',
            clothes4: '灰色',
            clothes5: '红色',
            clothes6: '橙色',
            clothes7: '黄色',
            clothes8: '绿色',
            clothes9: '深蓝色',
            clothes10: '浅蓝色',
            clothes11: '紫色',
            clothes12: '粉色',
            clothes13: '棕色',
            clothes14: '彩色',
            clothes15: '蓝色',
            clothes16: '青色',

            coat_length1: "未知",
            coat_length2: "长袖",
            coat_length3: "短袖",
            coat_length4: "无袖",

            pants: '下身颜色和款式',
            // pants0: '未知',
            // pants1: '黑色下衣',
            // pants2: '白色下衣',
            // pants3: '灰色下衣',
            // pants4: '红色下衣',
            // pants5: '橙色下衣',
            // pants6: '黄色下衣',
            // pants7: '绿色下衣',
            // pants8: '深蓝色下衣',
            // pants9: '浅蓝色下衣',
            // pants10: '紫色下衣',
            // pants11: '粉红色下衣',
            // pants12: '棕色下衣',
            // pants13: '彩色下衣',
            // pants32: '长短裤未知',
            // pants33: '长裤',
            // pants34: '短裤',
            // pants48: '穿裙子未知',
            // pants49: '穿裙子',
            // pants50: '不穿裙子',
            pants1: '未知',  // 更换枚举类型，en.js未更换
            pants2: '黑色',
            pants3: '白色',
            pants4: '灰色',
            pants5: '红色',
            pants6: '橙色',
            pants7: '黄色',
            pants8: '绿色',
            pants9: '深蓝色',
            pants10: '浅蓝色',
            pants11: '紫色',
            pants12: '粉色',
            pants13: '棕色',
            pants14: '蓝色',
            pants15: '青色',

            pants_length1: '未知',
            pants_length2: '长裤',
            pants_length3: '短裤',

            bags: '背包状态',
            bags0: '未知',
            bags1: '背包',
            bags2: '不背包',
            bags32: '包类型未知',
            bags33: '双肩背包',
            bags34: '单肩包、斜挎包',
            bags35: '手提包',
            bags36: '拉杆箱',

            rideBike: '骑车状态',
            rideBike0: '未知',
            rideBike1: '骑车',
            rideBike2: '不骑车',

            umbrella: '打伞状态',
            umbrella0: '未知',
            umbrella1: '打伞',
            umbrella2: '不打伞',

            safetyHelmet: '安全帽',
            safetyHelmet0: '未知',
            safetyHelmet1: '戴安全帽',
            safetyHelmet2: '不戴安全帽',
            safetyHelmet16: '帽子颜色未知',
            safetyHelmet17: '白色',
            safetyHelmet18: '其他',
            safetyHelmet32: '安全帽',
            safetyHelmet33: '厨师帽',
            safetyHelmet34: '学生帽',
            safetyHelmet35: '头盔',
            safetyHelmet36: '安防全人种01小白帽',
            safetyHelmet37: '头巾',
            safetyHelmet38: '其他帽子',
            safetyHelmet39: '未知类型',

            action: '动作',
            action0: '未知',
            action1: '跌倒',
            action2: '没有跌倒',
            action16: '未知',
            action17: '吸烟',
            action18: '没有吸烟',
            action32: '未知',
            action33: '跑步',
            action34: '没有跑步',
            action48: '未知',
            action49: '打电话',
            action50: '没有打电话',
            action64: '未知',
            action65: '看手机',
            action66: '没有看手机',

            vsubClass: '车辆细分类',
            vsubClass0: '未知',
            vsubClass1: '小轿车',
            vsubClass2: 'SUV',
            vsubClass3: '面包车',
            vsubClass4: '中巴车和大巴车',
            vsubClass5: '皮卡车',
            vsubClass6: '卡车',
            vsubClass7: '其他',

            vColor: '颜色',
            vColor8191: '未知',
            vColor8192: '黑色',
            vColor8193: '白色',
            vColor8194: '深灰',
            vColor8195: '棕色',
            vColor8196: '红色',
            vColor8197: '蓝色',
            vColor8198: '黄色 ',
            vColor8199: '绿色',
            vColor8200: '花色',
            vColor8201: '银灰',
            vColor8202: '橙色',
            vColor8203: '青色',
            vColor8204: '粉色',
            vColor8205: '紫色',
            vColor8206: '香槟',

            vOrientation: '驾驶方向',
            vOrientation8224: '正向',
            vOrientation8225: '背向',
            vOrientation8226: '其他',

            csubClass: '非机动车细分类',
            csubClass0: '未知',
            csubClass1: '自行车',
            csubClass2: '电动车',
            csubClass3: '摩托车',
            csubClass4: '三轮车',
            csubClass5: '其他',

            type: '车牌类型',
            type0: '单行',
            type1: '双行',
            type2: '未知',

            color: '车牌颜色',
            color0: '蓝色',
            color1: '黄色',
            color2: '黑色',
            color3: '白色',
            color4: '绿色',
            color5: '小型新能源',
            color6: '大型新能源',
            color7: '缺省',

            plateRecog: '车牌识别',
            bindAttr: '绑定查询',
            alertAttr: '抓拍查询',
            brandname: '车辆品牌',
            bindFace: '绑定人脸',
            bindHuman: '绑定人形',
            bindCycle: '绑定非机动车',
            noBindings: '没有查询到绑定数据',
            picStationError: '抓拍机不支持绑定关系查询',

            baseInfo: '底库信息',
            alertInfo: '识别信息',
            aboveHigh: '陌生人',
            noDetected: '未检测到绑定人体',
            videoTips: '录制成功，录像文件存储在：',
            videoDownTips1: '视频正在录制中，无法切换窗口，请先结束录制',
            videoDownTips2: '视频正在录制中，无法切换通道，请先结束录制',
            overDown: '结束录制',

            // 目标框提示
            noIntelRectTips_1: '目标数量太多资源已超限，无法叠加目标框',
            noIntelRectTips_2: '未查找到匹配的智能帧目标框，请降低盒子负载',
        },

        audio: {
            deleteFile: "确认删除该文件？",
            fileRepeat: '文件名不能重复',
            upload: '音频上传',
            list: '音频列表',
            audioNo: '语音文件编号',
            audioName: '语音文件名称',
            alarm: '触发报警',
            deleteSuccess: '删除成功',
            success: '操作成功',
            select: '请选择音频文件',
            uploadSuccess: '上传成功',
            format: '音频格式不正确',
            size: 'PCM音频大小不能超过5M',
            size1: 'MP3音频大小不能超过1M',
            type_limit: '语音文件类型支持：PCM、MP3',
            size_limit: '语音文件大小限制：PCM文件 <= 5M，MP3文件 <= 1M',
            file_limit: '语音文件名后缀限制：.pcm  .mp3  .MP3',
            filename_limit: '语音文件名仅支持中文，英文大小写，数字，特殊字符（包括-_），文件名字整体长度大于4',
            audioConfig: '音频联动配置',
            isBindAudio: '是否联动语音绑定',
            audioFile: '音频文件',
            alarmNum: '报警次数(次)',
            triggerInterval: '连续触发间隔(ms)',
            alarmRange: '（范围：1-1000）',
            triggerRange: '（范围：500-50000）',
            priority: '优先级',
            high: '高',
            middle: '中',
            low: '低',
            saveSuccess: '保存成功',
            addAudio: '添加音频',
            addRule: "音频文件添加规则：",
            maximum20:"最多只能添加20个音频文件"
        },

        algorithm: {
            noneReduction:'暂无可还原的算法仓',
            upload: '上传',
            upgradeFile: '升级文件',
            algName: '算法仓名称',
            downloadTime: '上传时间',
            installTime: '安装时间',
            status: '状态',
            result: '结果',
            operation: '操作',
            delete: '删除',
            confirmDelete: '是否确定删除该记录？',
            install: '安装',
            installed: '已安装',
            uninstalled: '未安装',
            confirmInstall: '确认安装吗？',
            upgrade: '升级',
            uninstall: '卸载',
            confirmUninstall: '确认卸载吗？',
            failUninstall: '卸载失败',
            refresh: '还原',
            tips1: '算法包版本一致，无需还原',
            tips2: '当前算法包版本:',
            tips3: '固件算法包版本:',
            tips4: '算法包还原',
            tips5: '固件包中不存在此算法包,无法还原',
            tips6: '非白名单,不支持还原',

            status1: '下载开始',
            status2: '下载结束',
            status3: '安装开始',
            status4: '安装结束',
            status5: '卸载开始',
            status6: '卸载结束',
            status7: '升级开始',
            status8: '升级结束',
            result1: '成功',
            result2: '系统调用失败',
            result3: '算法适配器接口调用失败',
            result4: '算法仓类型重复',
            result5: '过程中断电',
            result6: ' 空间不足导致失败',
            result7: '算法仓版本与pack名称不匹配',
            result8: '行业标识校验失败',
            result9: '升级包文件MD5值校验失败',
            result10: '解密失败',
            result11: 'mount目录失败',
            result12: '算法仓占用',
            result13: '需要挂载安装包路径',
            result14: '移除文件失败',
            result15: '新算法仓和被升级算法仓版本不匹配',
            selectFile: '选择文件',
            progress: '进度',
            algorithmUpload: '算法仓上传',
            algorithmUpgrade: '算法仓升级',
            alg_version: '版本',
            alg_description: '描述',
            alg_identification: '算法仓版本',

            reset: '恢复出厂算法',
            confirmReset: '确认要恢复出厂算法吗？',
            auth_channel: '授权路数',
            auth_duedate: '授权到期时间',
            auth_status: '授权状态',
            unauthorized: '未授权',
            authorized: '已授权',
            expired: '已过期',
            view: '查看',
            notSupportTip: '当前算法仓版本不支持查看算法描述文件',
            devDescriptionFile: '开发者描述文件',
            algDescriptionFile: '算法描述文件',
            outInParams: '出入参数',
        },
        authorize: {
            switchAuthorization:'切换授权模式会导致原授权失效，是否确认切换？',
            customAuthorization:"自定义授权",
            standardAuthorization:'标准授权',
            import:"导入",
            exportSecretKey:"导出授权秘钥",
            importAuthorize: '导入授权',
            identification: '行业标识',
            status: '授权状态',
            activate: '激活',
            frozen: '冻结',
            name: '授权文件名称',
            version: '授权版本',
            time: '授权生效时间（无时区）',
            time1: '授权剩余时间',
            algo_name: '算法仓',
            description: '算法类型',
            routes: '授权路数',
            date: '授权有效期',
            activate_authorize: '激活授权',
            frozen_authorize: '冻结授权',
            frozenTips: '行业冻结后，所属行业授权的算法布控任务失效，且无法再新增对应算法布控，是否确认冻结？',
            activateTips1: '设备同一时间只能激活一个授权SKU，是否确认激活？',
            activateTips2: '是否确认冻结',
            authorize_info: '授权信息',
            authorize_type: '标识版本',
            authorize_name: '授权文件',
            authorize_confirm: '确认授权',
            has_frozen: '已冻结',

            force: '已冻结',
            active: '生效中',

            permanent: '永久',
        },
        container: {
            containerManagement: '容器管理',
            upload: '上传',
            addContainer: '添加容器',
            containerFile: '容器文件',
            confirm: '确定',
            containerName: '容器名称',
            containerStatus: '容器状态',
            runStatus: '运行状态',
            resourceStatus: '资源占用情况',
            dockerManagement: '镜像管理',
            dockerControl: '容器控制',
            startDownload: '开始下载',
            downloadEnd: '下载结束',
            resourceFirst: '首次分配资源开始',
            resourceEnd: '分配资源结束',
            startInstall: '安装开始',
            endInstall: '安装结束',
            startDelete: '删除开始',
            endDelete: '删除结束',
            startUpgrade: '升级开始',
            endUpgrade: '升级结束',
            uninstall: '未安装',
            installed: '已安装',
            stopRun: '已停止运行',
            runNormal: '运行正常',
            appError: '沙盒内程序异常',
            install: '安装',
            upgrade: '升级',
            upgradeContainer: '升级容器',
            delete: '删除',
            start: '启动',
            stop: '停止',
            set: '设置',
            containerSet: '容器设置',
            memory: '内存',
            storage: '存储',
            sumbit: '提交',
            restartCount: '重启次数',
            deleteSuccess: '删除成功',
            installSuccess: '安装成功',
            stopSuccess: '停止成功',
            upgradeSuccess: '升级成功',
            startSuccess: '启动成功',
            setSuccess: '设置成功',
            containerSelect: '请选择容器文件',
            failed: '失败',
            installIng: '正在安装，请等待',
            success: '操作成功',
            container_amount_limit: '容器已达到上限4个',
            img_verify: '镜像校验失败',
            img_duplicate: '重复上传镜像',
            not_intalled: '容器未安装',
            img_unpack: '镜像解压失败',
            img_not_found: '找到容器镜像记录',
            not_running: '容器未运行',
            running: '容器运行中',
            name_illegal: '容器镜像名不合规',
            state_get: '容器状态获取失败',
            source_not_enough: '可分配的存储、cpu、内存等资源不足',
            intalled: '容器已安装',
            upgrade_mismatch: '容器名无亲缘性，禁止升级',
            install_match: '容器名亲缘性，禁止上传',
            delete_container: '容器删除失败',
            install_container: '容器安装失败',
            upload_container: '容器上传失败',
            upgrade_container: '容器升级失败',
            start_container: '容器启动失败',
            stop_container: '容器停止失败',
            sourceset_container: '容器资源分配失败',
            query_container: '容器状态查询失败',
            stageset_container: '容器stage设置失败',
            cancelUpload: '取消上传',
        },
        logs: {
            logs: '日志',
            dw: '日志导出',
            dwc: '导出当前页',
            search: '查询',
            description: '描述',
            timestamp: '记录时间',
            logSeq: '日志序列',
            type: '类型',
            type0: '全部',
            type1: '操作',
            type2: '异常',
            type3: '系统',
            type4: '自定义',
            getLogsEmpty: '查询数据为空，不能日志导出',
            ip: '用户IP地址',
            dateRangeEx: '日期范围错误',
            ExportNull: '可导出数据为空',
            device_reboot: "设备重启",
            user_login: '用户登录',
            user_logout: "用户登出",
            client_connect_ok: "客户端连接成功",
            exception_event: "异常事件",
            exportSucceeded: "导出成功",
            exportFailed: "导出失败",
            device_access_operation: "远程设备管理操作",
            system_operation: "系统操作",
            data_operation: "数据操作",
            alg_warehouse: "算法仓操作",
            log_content: "日志内容",
            log_detail_content: "日志详细信息",
            // 日志内容包括
            // 异常事件包括：
            disk_full: '磁盘满异常',
            disk_error: '磁盘错误异常',
            network_disconnect: '网络断开异常',
            video_loss: '视频丢失异常',
            ip_conflict: "IP冲突",
            mac_conflict: 'MAC冲突',
            sd_card_abnormal: "SD卡异常",
            network_recovery: "网络恢复",
            ftp_alarm_error:" ftp上传报警记录失败",
            gat1400_alarm_error:"gat1400上传报警记录失败",
            http_alarm_error: 'http上传报警记录失败',
            // 系统操作包括：
            app_start: "应用程序启动",
            app_reboot: "应该用程序重启",
            app_exception_exit: "应用程序异常退出",
            sys_reboot: "系统重启",
            sys_upgrade: "系统升级",
            sys_modify_time: "修改系统时间",
            alarm_ftp_enable: "开启报警FTP服务器",
            alarm_ftp_disable: "关闭报警FTP服务器",
            enable_http_server:"开启http服务器",
            enable_https_server:'关闭http服务器',
            enable_digest_auth:'开启认证',
            disable_digest_auth:'关闭认证',
            enable_dynamic_face:'开启动态底库',
            disable_dynamic_face:'关闭动态底库',
            disable_ntp:'关闭ntp校时',
            enable_ntp:'开启ntp校时',
          
            // 配置操作包括：
            configuration_operation:"配置操作",
            import_cfg_file :"导入配置文件",
            export_cfg_file :"导出配置文件",
            modify_face_cfg : "修改人脸配置",
            modify_video_codes_cfg : "修改视频编码配置",
            modify_network_cfg : "修改网络配置",
            plug_in_sd_card :"插入sd存储卡",
            plug_out_sd_card: "拔除sd存储卡",
            // 数据操作包括：
            memory_card_formatting :"存储卡格式化",
            clear_all_date: "清空所有数据",
            clear_face_lib :"清空人脸数据库",
            build_personnel_data :"新建人员数据",
            modify_personnel_data :"修改人员数据",
            hot_swap :"热插拔",
            remove_personnel_group:'删除人员分脸',
            delete_alarm_record: '删除报警记录',
            // 录像操作包括：
            file_access_error :"文件访问错误",
            file_query :"文件查询",
            // 图片操作包括：
            access_file_error :"文件访问错误",
            query_file :"文件查询",
            // 算法仓操作包括：
            upload :"上传",
            install :"安装",
            uninstall :"卸载",
            remove :"删除",
            upgrade:'升级',
            reset:'算法仓恢复出厂',
            single_reset:"单个算法仓还原",
            // 用户管理包括：
            add_user :"添加用户",
            remove_user: "删除用户",
            modify_user :"修改用户",
            // 安全管理包括：
            security_manager:"安全管理",
            reset_password :"重置密码",
            ip_not_authorized  :"客户端 IP 地址未授权",
            violent_login :"暴力登录 （发生用户锁定3次及以上）",
            // 远程设备管理操作包括：
            add_device:'添加远程设备',
            remove_device:'删除远程设备',
            modify_device:'修改远程设备',
            // 标注状态
            calibrate_correct: '标注正确',
            calibrate_error: '标注错误',
            calibrate_unrecogized: '标注未识别',
            calibrate_timeout: '标注超时',
            // 日志类型
            cloud_edge_data_verify: '云边数据核验',
        },
        singleCompare: {
            singleCmp: '1:1比对',
            notice: '说明：',
            imgType: '图片类型：JPG、JPEG、PNG、JFIF或BMP',
            imgSize: '图片大小 ≤ 4MB',
            imgMax: '图片最大尺寸 ≤ 4096*4096',
            imgMin: '图片最小尺寸 ≥ 50*50',
            imgNum: '每张图片中只能有一张人脸，如有多张脸取最大',
            imgFace: '每张图片中只能有一张人脸，如有多张脸取最大',
            dragPut: '拖拽或点击上传人像',
            cmp: '开始比对',
            liveness: '活体评分',
            faceAnaly: '人脸分析参数',
            score: '活体分数',
            face: '人脸',
            leftFace: '左图',
            rightFace: '右图',
            left: '左边坐标',
            right: '右边坐标',
            top: '上边坐标',
            bottom: '下边坐标',
            roll: 'Roll',
            yaw: 'Yaw',
            pitch: 'Pitch',
            pictureQuality: '图片质量',
        },
        multiCompare: {
            search: '1:N搜索',
            result: '人脸搜索结果',
            searchGroup: '搜索分组',
            featureUpload: '特征数据',
        },
        structures: {
            targetImage: '目标小图',
            maxTarget: '每张图片最多检测12个目标',
            humanInfo: '人体信息',
            num: '编号',
            upClothesColor: '上衣款式和颜色',
            downClothesColor: '下身款式和颜色',
            faceInfo: '人脸信息',
            types: '分类',
            cycleInfo: '非机动车信息',
            plateInfo: '车牌信息',
            male: '男性',
            female: '女性',
            unkonwn: '性别未知',
            unkonwn1: '未知',
            color0: '颜色未知',
            unknown1: '上衣长短袖未知',
            long: '上衣长袖',
            short: '上衣短袖',
            pants1: '黑色裤子',
            pants2: '白色裤子',
            pants3: '灰色裤子',
            pants4: '红色裤子',
            pants5: '橙色裤子',
            pants6: '黄色裤子',
            pants7: '绿色裤子',
            pants8: '深蓝色裤子',
            pants9: '浅蓝色裤子',
            pants10: '紫色裤子',
            pants11: '粉红色裤子',
            pants12: '棕色裤子',
            pants13: '彩色裤子',
            color1: '花色',
            color2: '银灰色',
            color3: '深灰色',
            color4: '橙色',
            color5: '青色',
            color6: '粉色',
            color7: '紫色',
            color8: '香槟',
            exit: '退出',
            picture: '图片解析',
            plateNo: '车牌号码',
            imageId: 'imageId',
            faceToken: 'faceToken',
        },
        paramSystem: {
            activeAccess: '主动模式接入方式',
            digestClose0: "关闭认证",
            digestClose:
                "关闭认证模式存在安全风险且设备将会重新启动，确认关闭认证模式吗？",
            setting: "系统参数设置",
            settings: "功能设置",
            state: "状态",
            mode: "工作模式",
            sysname: "系统名称",
            logoUpload: '平台Logo',
            resetDefault: '恢复默认',
            sysnamePlaceholder: '请填写系统名称',
            sysnameEmptyTips: '系统名称不能为空',
            sysnameLenTips: '系统名称不能大于32个字节',
            upload: '上传',
            logoPreview: 'logo预览',
            imgType: '图片类型：JPG、JPEG、PNG、JFIF或BMP',
            imgSize: '图片大小 ≤ 4MB',
            security: "通信安全",
            http: "HTTP",
            https: "HTTPS",
            wl: "白名单",
            bl: "黑名单",
            devMode: "设备工作模式",
            video: "视频流",
            image: "图片流",
            pushMode: "推图模式",
            fast: "最快推图",
            best: "最优推图",
            interval: '间隔推图',
            storage: "抓拍存储",
            largeStorage: "抓拍背景图开关",
            fullPicDrawRectEnabled: "背景大图画框使能",
            liveness: "活体识别开关",
            feature: "人脸特征入库",
            retry: "二次识别次数(0-5000)",
            retryInterval: "二次识别的间隔时长(0-60秒)",
            strangerRetryCount: "陌生人重试次数(0~5)",
            minPushInterval: "最小推图间隔(500-10000毫秒)",
            pictureRetryInterval: "抓拍机最小识别间隔(0-60秒)",
            pictureRetryCount: "图片流二次识别次数(0~5000)",
            pictureStrangerRetryCount: "图片流陌生人判定次数(0-5)",
            advancedParamsConfig: "高级参数配置",
            advancedParamsConfigTip: '开启后【布点设置】展示全量算法参数',
            bigCap: '背景图质量系数(10-100)',
            alertReportBufferSize: '识别消息缓存(0-10000)',
            captureReportBufferSize: '抓拍消息缓存(0-50000)',
            algorithm: '算法版本',
            detect: '人脸检测模型版本',
            track: '人脸检测与跟踪的模型版本',
            pose: '人脸角度质量判断的模型版本',
            blur: '人脸模糊度质量判断模型版本',
            search: '人脸识别模型版本',
            live: '人脸活体检测模型版本',
            reboot: '该设备将重新启动',
            rebootTips: '改变认证使能状态,该设备将重新启动',
            delRbt: '该操作将删除所有入库的原始照片、人脸照片、所有关联关系，并重启设备！',
            delRbt10: '10万底库以上，',
            warning: '警告',
            relogin: '设置成功，系统正在重启，请勿关闭当前页面',
            success: '设置成功',
            auth: '接入页面认证',
            modifyPwd: '修改认证密码',
            input: '请输入新密码',
            inputConfirm: '请确认新密码',
            inputError: '两次输入密码不一致!',
            modifyOk: '修改成功！',
            lenTip: '密码长度最大为64位',
            tip: '打开活体识别要求最小人脸大于80',
            capStored: '抓拍人脸同时进行背景图抓拍与存储，会造成上报数据量加大',
            bgCompress:
                '质量系数将影响背景图清晰度，主要为压缩图片存储空间。设[100]为原图，最高质量，占用最大存储空间，设[10]为最大限度压缩，最低质量，占用最小存储空间',
            text1: '口令长度至少8个字符',
            text2: '口令必须包含以下至少两种字符的组合',
            text3: '至少一个大写字母',
            text4: '至少一个小写字母',
            text5: '至少一个数字',
            text6: '至少一个特殊字符:`~@#$%^&*()-_=+|[{}];:\'",<.>/?',
            digestExit: '认证退出',
            digestTips: '认证退出时，将会关闭当前页面',
            globalAlgorithm: '全局算法设置',
            sysConfig: '系统配置',
            accessAuth: '接入认证',
            loginOp: '登录选项',
            digestMaxAuthTries: '登录次数限制(3-10)',
            digestUnlockTime: '解锁时间(5-30分钟)',

            megConnect: '私有协议',
            megConnectEnable: '主动模式是否启用',
            megConnectUrl: '主动模式地址',
            megConnectPort: '被动模式端口号',

            megConnectUrlTip: '例如: 127.0.0.1:34567',
            formatError: '格式有误',

            guiSettings: '本地GUI配置',
            guiEnable: '是否使能',
            yes: '是',
            no: '否',
            window: '窗口',
            windowNum: '窗口数',
            windowChannel: '视频通道对应窗口',
            windowNo: '窗口编号',
            noChannel: '无',
            channelDeleted: '无布点',

            videoStructure: '视频结构化',
            attributeEnable: '启用属性分析',
            detectTargetList: '检测目标',
            detectTargetList0: '人脸',
            detectTargetList1: '人形',
            detectTargetList2: '机动车',
            detectTargetList3: '非机动车',
            detectTargetList4: '车牌',

            captureMode: '抓拍模式',
            modeType0: '全抓',
            modeType1: '高质量',
            modeType2: '自定义',

            faceQuality: '人脸综合质量',
            humanQuality: '人形综合质量 ',
            motorVehicleQuality: '机动车综合质量',
            nonMotorVehicleQuality: '非机动车综合质量',
            plateQuality: '车牌综合质量',

            pushStrage: '推图策略',
            pushMode1: '最优推图',
            pushMode2: '最快推图',
            pushModes0: '间隔推图',
            pushModes1: '最优推图',
            pushModes2: '最快推图',
            maxPushNumber: '最大推图张数(1~10)',
            pushInterval: '推图间隔(500-10000毫秒)',

            smartModes: '智能模式设置',
            smartMode: '智能模式',
            smartMode0: '全人脸通道',
            smartMode1: '混合通道',
            detectChange: '智能模式发生变化，系统会清除所有视频通道',
            portTip: 'port：RTSP端口，默认554; channel：通道, 1到N; 关闭接入认证之后，不需要输入用户名密码.',
            example: '示例：',
            enableConnect: '开启接入认证：',
            disableConnect: '关闭接入认证：',
            rtspMention: '注意：单路布点同时只支持一个客户端连接rtsp流',
            dtdk: '动态底库',
            tip1: '开启或关闭动态底库，原底库（分组+人脸）将被删除，同时重启',
            tip2: '开启动态底库，全部脸人通道必须开启解析目标属性',
            tip3: '开启动态底库，底库人数上限从30W下降到15W，但不影响分组',
            enableSSL: '是否启动SSL',
        },
        deviceSystem: {
            testVerifySystemname:"请输入中文32位或者字母48位以内",
            hwConfig: '硬件设置',
            fwUp: '固件升级',
            isSyncAlgorithmWarehouse: '算法仓同步升级',
            reboot: '设备重启',
            reset: '恢复出厂设置',
            hwInfo: '硬件信息',
            hwVer: '硬件版本',
            fwVer: '固件版本',
            ram: 'RAM大小',
            ramUsed: 'RAM使用',
            devNo: '内核序列号',
            devSN: '设备序列号',
            megConnectVersion: '私有协议版本',
            AiotapVersion: '私有协议版本',
            storage: '存储空间',
            cpu: 'CPU使用率',
            storageUsed: '已用存储',
            cpuTemp: 'CPU温度',
            time: '系统时间',
            board: '电路板温度',
            soft: '网页版本',
            deviceModel: '设备型号',
            licenseKey: '设备授权码',
            network: '网络设置',
            wan: 'WAN',
            lan: 'LAN',
            ethIpAddr: 'IP地址',
            ethSubmask: '子网掩码',
            ethGateway: '网关',
            ethDns: 'DNS',
            mac: 'MAC',
            ethBondMode: 'Bond模式',
            ethBondMode1: '主备模式',
            ethBondMode0: '平衡模式',
            ethBondMode2: '双网卡独立',
            ethBondTips: '主备模式和平衡模式，只有WAN生效',

            ftpUpgrade: '在线升级设置',
            enableAuto: '自动升级',
            dayOfWeek: '升级日期',
            startTime: '升级时间',
            timeStart: '开始时间',
            timeEnd: '结束时间',
            serverIp: '升级服务器地址',
            serverPath: '升级路径',
            username: '用户名',
            password: '密码',
            upgrade: '立即升级',
            ftpUpConfirm: '确认升级? 查询升级包: ',
            ftpUpTitle: 'FTP升级',
            upDoneFeat: '抽取特征完成',

            remote: '对接服务器设置',
            hb: '心跳服务器',
            serverAdd: "服务器地址(用':'分隔端口)",
            htInterval: '心跳间隔(1-3600秒)',
            NTP: '对时服务器',
            NTPEnable: 'NTP使能',
            ntpServer: '域名或IP',
            NTPRetry: '校时失败重试次数',
            ntpInterval: '校时间隔(1-65535分钟)',
            deviceTime: '手动设置时间',
            timeZone: '设置时区',
            chooseTime: '选择日期时间',
            dataUpload: '信息主动上报',
            uploadServer: "服务器地址(用':'分隔端口)",
            alarmServer: "服务器地址(用':'分隔端口)",
            captureServer: "服务器地址(用':'分隔端口)",
            alarmUpload: '识别信息主动上报',
            alarmInterval: '识别重发间隔时间(0-10秒)',
            failureRetry: '识别重发次数',
            upImage: '传送图片',
            capImage: '抓拍信息主动上报',
            wait: '已上传100%，等待响应中',
            choose: '点击上传',
            noFile: '未选择升级文件',
            submit: '升级',
            upDoneWait: '上传成功，设备正在升级，请勿关闭当前页面',
            serverErr: '服务器连接错误，请稍后重试',
            upFail: '升级失败',
            confirmReset: '确认恢复出厂设置吗？',
            savesetting: '保留网络设置',
            resetWait: '系统恢复出厂中，请勿关闭当前页面',
            confirmReboot: '确认要重启设备吗？',
            rebootWait: '系统正在重启，请勿关闭当前页面',
            deviceTimeErr: '设备系统时间不正确！',
            loadingDone: '完成',
            loadingDown: '正在获取升级包,请稍候... ',
            loadingVerify: '校验文件',
            loadingFeat: '算法升级中,正在重新抽取底库特征',
            loadingBatUp: '批量入库',
            checkUpgrade: '上次升级状态',
            upgradeType0: 'Web升级',
            upgradeType1: 'Ftp手动升级',
            upgradeType2: 'Ftp自动升级',
            upgradeType3: 'p2p升级',
            upgradeType4: 'MegConnect升级',
            upgradeTime: '升级时间',
            noUpgradeTime: '未知',
            upgradeResult0: '成功',
            upgradeResult1: '校验失败',
            upgradeResult2: '下载失败',
            upgradeResult3: '无可用升级文件',
            upgradeResult4: '写入磁盘错误',
            upgradeResult5: '内部错误',
            upgradeResult6: '系统忙',
            upgradeStatus: '查询设备状态中,请稍候...',
            exportFingerprint: '导出指纹',
        },
        switchSystem: {
            title: '智能模式切换',
            subTitle: '切换设置',
            label: '模式',
            mix: '人脸&结构化混合',
            alert: '警戒',
            tip: '提示：智能应用模式切换会通道清空，历史抓拍、识别清空，底库保留，IP保留，激活信息保留',
            yes: '是',
            no: '否',
            confirmMsg: '智能应用模式切换设备会重新启动，部分数据需恢复出厂设置，请确认是否切换',
            confirmTitle: '智能模式切换',
            gettingFailed: '获取智能模式失败',
            settingFailed: '设置失败',
            loadingTip: '系统重启中，请等待20秒后刷新网页重新登录设备',
        },
        access: {
            ruleMgmt: '通行权限管理',
            timeSetting: '时段设置',
            holiday: '假日设置',
            editRule: '编辑规则',
            newRule: '新建规则',
            newTime: '新建时段',
            newHoliday: '新建假日',
            timeMgmt: '时段设置',
            holidayMgmt: '假日设置',
            rule: '规则名称',
            channels: '通道',
            group: '人员分组',
            comment: '备注',
            timeSpan: '时段',
            calendarNames: '假日',
            enable: '启用',
            start: '开始时间',
            end: '结束时间',
            calendarName: '假日名称',
            repeat: '重复',
            noRepeat: '不重复',
            yearly: '年重复',
            monthly: '月重复',
            enabled: '已启用',
            disabled: '已关闭',
            channel: '通道',
            new: '新建',
            edit: '编辑',
            newEditTime: '时段',
            scheduleName: '时段名称',
            range: '至',
            startDate: '开始日期',
            endDate: '结束日期',
            fmt: 'yyyy 年 MM 月 dd 日',
            time: '时间',
            timeRange: '时间区间',
            date: '日期',
            startRange: '开始',
            endRange: '结束',
            copyAbove: '同上',
            calendar: '假日',
            selected: '已选',
            recentWeek: '最近一周',
            recentMonth: '最近一个月',
            recentSeason: '最近三个月',
            one: '星期一',
            two: '星期二',
            three: '星期三',
            four: '星期四',
            five: '星期五',
            six: '星期六',
            seven: '星期日',
            dateFmt1: 'MM 月 dd 日',
            dateFmt2: 'yyyy 年 MM 月 dd 日',
            equipment: '设备',
        },
        relay: {
            relay: '继电器配置',
            videoNo: '通道',
            triggerChannel: '继电器通道',
            relayIP: '继电器IP',
            relayMAC: '继电器MAC',
            relayMode: '工作模式',
            relayWorkPara: '工作参数选择',
            timeUnitTips: '修改单位时间会造成继电器工作状态改变',
            AESKeyTips: '继电器16位通信密码',
            netMode: '网络模式',
            timeUnit: '触发时长时间单位',
            AESKey: '通信密码',
            triggerTime0: '触发时长(毫秒)',
            triggerTime1: '触发时长(秒)',
            delayTime: '触发延迟(秒)',
            triggerInterval: '连续触发间隔(毫秒)',
            triggerCondition: '触发条件',
            status: '状态',
            enable: '启用',
            online: '在线',
            operation: '操作',
            edit: '编辑',
            delete: '解绑',
            recog: '识别',
            capture: '抓拍',
            capture_recognition:"识别+抓拍",
            search: '继电器搜索',
            submit: '提交',
            trigger: '立即触发',
            open: '常开',
            close: '常闭',
            unbindFG: '确定解绑该继电器？',
            unbind: '解绑继电器',
            recogCap: '抓拍+识别',
            success: '解绑成功!',
            triggerOk: '触发成功!',
            delayTimeTips: '(0-10秒)',
            triggerTimeTips0: '(100-50000毫秒)',
            triggerTimeTips1: '(0-50秒)',
            triggerIntervalTips: '(0-50000毫秒)',
            milliSec: '100毫秒',
            seconds: '1秒',
            relayType: '继电器功能',
            relayTypeA: '通行',
            relayTypeB: '报警',
            relayEdit0: '添加',
            relayEdit1: '编辑',
            relays: '继电器',
        },
        autoUpload: {
            autoUpload: '信息主动上报',
            wsMode: 'Websocket模式',
            httpMode: 'HTTP模式',
            dataUpload: '信息主动上报',
            alarmServer: "服务器地址(用':'分隔端口)",
            captureServer: "服务器地址(用':'分隔端口)",
            msgUpload: '识别信息主动上报',
            alarmInterval: '识别重发间隔时间(0-10秒)',
            alarmUpload: '识别信息主动上报',
            capImage: '抓拍信息主动上报',
            featureUpload: '传送特征数据',
            upImage: '传送图片',
            upSmallImage: '传送抓拍小图',
            upBigImage: '传送背景大图',
            persistReport: '上报失败数据重发',
            failureRetry: '识别重发次数(0-2)',
            httpServer: "主服务器地址(用':'分隔端口)",
            subServer: "从服务器地址(用':'分隔端口)",
            httpRetry: '上报重发次数',
            httpInterval: '上报重发间隔',
            dataType: '数据类型',
            recog: '识别',
            capture: '抓拍',
            recogCap: '抓拍+识别',
            success: '解绑成功!',
            triggerOk: '触发成功!',
            autoConnect: '主动对接模式',
            megEnable: '主动对接',
            megServer: '服务器地址',
            megPort: '服务器端口',
            megAPPID: 'APP_ID',
            megAPPKEY: 'APP_KEY',
            megDevice: '设备名称',
            megSerial: '设备序列号',
            megState: '连接状态',
            megConnected: '已连接',
            megDiscon: '未连接',
            retransmission:'上传失败数据重发',
        },
        gat: {
            gat: 'GA/T 1400配置',
            httpsEnable: 'HTTPS使能',
            authEnable: '认证使能',
            authMode: '认证方式',
            basic: 'basic',
            digest: 'digest',
            fullPicEnable: '抓拍图使能',
            gatServer: '采集设备',
            gatClient: '上层平台',
            enable: '是否启用',
            devInfo: '设备编号',
            serverAddr: '服务器地址',
            serverPort: '端口',
            username: '用户名',
            password: '密码',
            keepInterval: '保活间隔(30~300秒)',
            backgroundUpload: '是否上传背景图',
            submit: '提交',
            exit: '注销',
            status: '运行状态',
            noCon: '未连接',
            conn: '已经连接',
            unCon: '未启用',
            disconn: '注销',
            warning: '确定注销?',
            labelDevInfo: '请咨询当地机关获取注册编号',
            labelIP: '请输入域名或IPv4地址',
            labelUser: '请输入用户名',
            labelPWD: '请输入密码',
            syncTimeEnable: '是否启用校时',
            syncTimeInterval: '同步时间间隔 (1~65535s)',
            retransmissionTimes: '重传次数 (0~3)',
            retransmissionInterval: '重传间隔 (1~5s)',
            platformNo: '视图库ID (1~100)',
            gaUsername: '用户名最大长度为32个字符',
        },
        ftpUpload: {
            ftpUpload: 'FTP推图配置',
            ftpEnable: '启用',
            enableBackground: '上传抓拍背景图',
            serverIP: '服务器IP',
            port: '端口',
            user: '用户名',
            pwd: '密码',
            remoteDir: '远程目录',
            dir1: '一级目录',
            dir2: '二级目录',
            dir3: '三级目录',
            test: '测试连接',
            customDir: '自定义目录',
            name0: '无',
            name1: '名称',
            name2: '编号',
            name3: '日期',
            name4: '自定义',
            testOk: '连接测试成功',
            testFail: '连接测试失败',
        },
        error: {
            sorry: 'Sorry...',
            pageDown: '你要找的页面不存在...',
            return: '回到首页',
            isFloat: '不能是浮点数(小数)',
            commonTips: '提交错误，请检查参数',
            noVideoData: '当前没有视频流播放',
            apiRetry: '数据请求获取失败,请稍后按F5刷新再试',

            e104000: 'json结构解析错误',
            e104001: '参数格式错误',
            e104002: '缺少必要参数',
            e104003: '系统重启',
            e104004: '无访问权限',
            e104005: '密码格式错误',
            e104006: '数据处理中，请稍后重试',
            e104007: '访问的资源不存在',
            e104008: '超出限定视频路数',
            e104009: '无指定通道，未创建或通道与名称不匹配',
            e104010: '视频流接入失败',
            e104011: '通道已存在',
            e104012: '处理能力达到上限',
            e104013: '通道绑定了权限规则',
            e104014: '设备标识已存在',
            e104015: '通道名称已存在',
            e104016: '获取码流失败',
            e104017: '视频通道不存在',
            e104018: '清空视频通道中，请稍后',
            e104019: '算法能力超限',
            e104020: '不允许一个通道同时使能脸人/结构化',
            e104021: '用户名或者密码错误',
            e104022: '已存在人数统计，不允许一个通道多规则设置人数统计',
            e104023: '不允许一个规则使用同一报警规则',
            e104024: '设备SN号已存在',
            e104025: '设备ip已存在',
            e104026: '磁盘空间不足',

            e104100: '未激活，请先激活',
            e104101: '登录冻结，请稍后重试',

            e104200: '没有检测到人脸',
            e104201: '人脸未通过质量判断',
            e104202: '人脸token不存在',
            e104203: '图片不存在',
            e104204: '人脸已经存在',
            e104205: '控件满或人脸个数超限',
            e104206: '图片md5校验失败',
            e104207: '图片解码失败',
            e104208: '图片resize失败',
            e104209: '读取文件失败',

            e104210: '无效分辨率',
            e104211: '无效类型',
            e104212: '无效大小',
            e104213: '太模糊',
            e104214: '角度太大',
            e104215: '黑白比例过大',
            e104216: '人脸太小',
            e104217: '没有检测到目标',
            e104312: "人脸face_token已经存在",

            e104300: '分组名已存在或者分组名冲突',
            e104301: '分组不存在',
            e104302: '分组数超上限',
            e104303: '绑定关系不存在',
            e104304: '分组正被使用，不能删除',
            e104305: '特征版本与系统不一致',
            e104306: '人脸库同步中，请稍后重试...',
            e104307: '系统正在进行人脸库处理，请稍后重试...',
            e104308: '入库图片个数超过上限',
            e104309: '分组绑定了权限规则',

            e104400: '未开启抓拍存储',
            e104401: '服务器忙',
            e104402: '工作模式不匹配',
            e104403: '升级包文件大小/太大',
            e104404: '接收升级文件错误',
            e104405: '升级包格式错误',
            e104406: '升级包MD5检查失败',
            e104407: '升级包未找到',
            e104408: '正在进行升级',
            e104409: '升级包下载失败',
            e104410: 'FTP升级用户名密码校验失败',
            e104411: 'FTP服务器连接失败',
            e104412: 'FTP服务器路径不存在',
            e104430: '算法仓版本校验失败',
            e104431: '算法仓升级文件名包含不合规字符',
            e104432: '算法仓升级文件名重复',
            e104433: '算法仓升级文件解压失败',
            e104434: '算法仓升级文件内容有误',
            e104435: '算法仓操作中',
            e104436: '算法标识重复，请勿重复安装',
            e104437: '已安装算法其他版本，建议升级',
            e104439: '算法包依赖版本错误',
            e104460: '容器管理程序异常',
            e104461: '容器操作中',
            e104462: '容器数量已达到上限4个',
            e104463: '镜像校验失败',
            e104464: '重复上传镜像',
            e104465: '镜像解压失败',
            e104466: '容器可分配资源不足',
            e104467: '容器无亲缘性,禁止升级',
            e104468: '有亲缘性容器,禁止上传',

            e104469: '容器镜像名不合规',
            e104470: '容器未安装，不支持该操作',
            e104471: '容器未运行，不支持该操作',
            e104472: '容器运行中，不支持该操作',
            e104473: '容器已安装过，不支持该操作',
            e104474: '容器删除失败',
            e104475: '容器安装失败',
            e104476: '容器上传失败',
            e104477: '容器升级失败',
            e104478: '容器启动失败',
            e104479: '容器停止失败',
            e104480: '容器设置失败',
            e104481: '容器查询失败',
            e104482: '容器状态设置失败',
            e104483: '容器格式错误',

            e104501: '通道号无效',
            e104502: '通道未创建',
            e104503: '通道未配置继电器',
            e104504: '继电器触发失败',
            e104505: 'NTP和GA1400或GB28181冲突',
            e104506: '设置IP地址冲突',
            e104507: '通道继电器未启用',
            e104508: '继电器工作模式冲突,请查看通道 ',
            e104509: '设置IP地址和网关不在同一网段',
            e104510: '双网卡独立模式不允许设置同网段IP',
            e104511: 'GB28181校时与GA/T1400校时冲突',
            e104540: '授权文件名不合规',
            e104541: '授权文件大小太大，超过了1MB.',
            e104542: '授权文件管理程序异常',
            e104543: '授权文件操作中',
            e104544: '授权文件校验失败',
            e104550: '授权文件操作失败',
            e104551: '此次激活需要进行设备重启',

            e104601: 'calendar 已经绑定 rules',
            e104602: 'schedule 已经绑定 rules',
            e104603: 'calendar 不存在',
            e104604: 'schedule 不存在',
            e104605: 'rules 不存在',
            e104606: 'calendar 表存满',
            e104607: 'schedule 表存满',
            e104608: 'rules 表存满',
            e104609: 'calendar 条目已经存在',
            e104610: 'schedule 条目已经存在',
            e104611: 'rules  条目已经存在',
            e104612: '无效的 timerange 参数输入',
            e104613: '无效的 daterange 参数输入',
            e104614: '无效的 date 参数输入',
            e104615: 'rules 试图绑定的通道并不存在',
            e104616: 'rules 试图绑定的 group 并不存在',
            e104617: '请求rules、calendar、schedule时使用的offset大于当前数据库中的数量',
            e104618: 'FTP测试失败',
            e104619: '智能模式不一致',

            e104903: 'GB28181占用LAN卡，不支持模式切换',
            e104904: '注册中，勿操作，稍后重试',

            e104700: '语音文件编号超出限制',
            e104701: '该编号未配置语音文件',
            e104702: '语音文件存在事件绑定',
            e104703: '事件类型不存在',
            e104704: '该通道未绑定语音',
            e104705: '语音文件格式错误',
            e104706: '语音文件名字长度超出限制',
            e104707: '语音文件正在播放',
            e104708: '停止正在播放的语音失败',
            e104709: '没有语音在播放',
            e104710: '正在播放的不是这个视频通道的语音',
            e104711: '播放的不是事件触发的语音',
            e104712: '语音播放任务列表数量超过限制',
            e104800: '本地GUI初始化失败',
            e104801: 'GUI播放视频路数超限',
            e104802: '窗口不能设置相同的视频通道',

            e105000: '其它内部错误',
            e105100: '数据库正在升级',
            e105202: '镜像文件操作中',
            e104438: '算法不支持卸载',

            e106000: '请求失败，接口不存在',
            e404: '请求失败，接口不存在',
            e500: '其它内部错误',

            e104552: '超过算法仓的授权路数',
        },
        gbt: {
            gbt: 'GB/T 28181配置',
            client: '国标客户端',
            server: '国标服务端',
            sipServerId: 'SIP服务器编号',
            sipServerDomain: 'SIP域',
            sipServerAddr: 'SIP服务器IP',
            sipServerPort: 'SIP服务器端口号',
            sipEnableAuth: '启用鉴权',
            username: '注册用户名',
            password: '注册密码',
            deviceId: '	设备编号',
            localSipPort: '本地SIP端口',
            localSipIP: '本地IP',
            expiration: '注册有效期 ',
            heartBeatInterval: '心跳周期',
            heartBeatCount: '	最大心跳超时次数',
            civilCode: '行政区划代码',
            transProtocol: '信令传输协议',
            number: '次',
            streamTransProtocol: '流传输协议',
            syncTimeEnable: '启用校时',
            chnInfo: '通道信息',
            channel: '通道',
            videoNo: '视频通道编号',
            chnId: '通道编号',
            alarmLevel: '报警级别',
            sipWan: '网卡',
            registerStatus: '注册状态',
            notEnabled: '未启用',
            registerFailed: '注册失败',
            registerSuccess: '注册成功',
            setting: '设置',
            integer: '请输入整数',
            error: {
                e107001: '请输入SIP服务器编号',
                e107002: 'SIP服务器编号长度为20个字符',
                e107003: '请输入SIP域',
                e107004: '请输入SIP服务器IP',
                e107005: 'SIP服务器IP格式错误',
                e107006: '请输入SIP服务器端口',
                e107007: 'SIP服务器端口(1-65535)',
                e107008: '请输入设备编号',
                e107009: '设备编号长度为20个字符',
                e107010: '请输入本地SIP端口',
                e107011: '本地SIP端口(1-65535)',
                e107012: '请输入注册用户名',
                e107013: '注册用户名最大长度为32个字符',
                e107014: '注册密码最大长度为32个字符',
                e107015: '请输入注册有效期',
                e107016: '注册有效期(3600-65535秒)',
                e107017: '请输入心跳周期',
                e107018: '心跳周期(30-180秒)',
                e107019: '请输入最大心率超时次数',
                e107020: '最大心跳超时次数(3-5次)',
                e107021: '请填入网卡',
                e107022: '请填入流传输协议',
                e107023: '请选择本地IP',
                e107024: '请输入偶数',
                e107025: '请输入整数',
                e107026: 'SIP服务器编号与设备编号不能相同',
            },
        },

        person: {
            countRecord: '人数统计记录',
            monitorRecord: '实时统计数据',
            refresh: '刷新',
            areaPerson: '区域人数',
            in: '进入',
            leave: '离开',
            reset: '清零',
            all: '所有',
            day: '天',
            month: '月',
            hour: '时',
            days: '天',
            months: '月',
            hours: '时',
            enterPersonNumber: '进入统计人数',
            leavePersonNumber: '离开统计人数',
            areaPersonNumber: '区域统计人数',
            unit: '单位：',
            people: '人',
            success: '成功',
            fail: '失败',
            statisticalData: '统计数据',
            dailyStatement: '日报表',
            monthlyStatement: '月报表',
            annualStatement: '年报表',
            previewArea: '区域',
            previewIn: '进',
            previewOut: '出',
            statisticalReport: '统计数据报表',
            total: '总计',
            StatisticalTrends: '统计数据趋势',
            operation: '操作',
            EquipmentSelection: '设备选择',
            ReportTime: '报表时间',
            DataDimension: '数据维度',
            search: '查询',
            Reset: '重置',
        },

        // 3.0 新加入的词条
        newError: {
            fail: '失败',
            invalidParams: '参数错误',

            // 通用错误码
            error1: '通用错误',
            error2: '无效参数，协议或接口的参数无效',
            error3: '存储空间不足',
            error4: '资源无法访问(资源存在但权限不足的情况下使用该错误码)',
            error5: '断线',
            error6: '重复操作，如重复打开，重复关闭',
            error7: '不支持的方法',
            error8: '资源无效，即资源不存在或异常',
            error9: '访问超时',
            error10: '资源不足',
            error11: '资源/设备已经存在',
            error12: '无效用户名或密码',
            error13: '设备被锁定',
            error14: '超出设备规格',
            error15: '系统忙碌（达到处理能力上限）',
            error16: '用户数量超出上限',
            error17: '用户已存在',
            error18: '在线用户数量超出上限',
            error19: '远程设备已下线',
            error20: '远程设备已存在',
            error21: '远程设备不存在',
            error22: '设备未激活',
            error23: '用户数据链路断',
            error24: '缓存满',
            error25: '用户主动中止操作',
            error26: '版本不匹配',
            error27: '流退订中',
            error28: '数据不完整',
            error29: '数据量过大',
            error30: '缺少必要参数',
            error31: '配置参数冲突',
            error32: '设备重启中',
            error33: '请求消息体过大',
            error34: '用户名格式错误',

            // 通用业务错误码
            error500: '通道号不存在（无效的视频流、音频流通道号）',
            error501: '解码失败',
            error502: '图片不存在',
            error503: 'md5 校验失败',
            error504: '读取文件失败',
            error505: '无效分辨率',
            error506: '无效类型',
            error507: '特征版本与系统不一致（算法抽取目标特征版本号不一致）',
            error508: '工作模式不匹配',
            error509: '升级包文件异常',
            error510: '升级包文件接收错误',
            error511: '资源未准备好',
            error512: 'session未找到',
            error513: '升级中',
            error514: 'ip冲突',
            error515: 'sn冲突',
            error516: 'IP地址和网关不在同一网段',
            error517: '双网卡独立模式不允许设置同网段IP',
            error518: '校时配置冲突',
            error519: 'ip格式不正确',
            error520: 'rtspurl格式错误',
            error521: '设备名称不合法',
            error522: '文件名称不合法',
            error523: '文件数量超过上限',
            error524: '文件大小不合法',
            error525: '文件类型不合法',
            error526: '文件已绑定联动业务，不允许删除',
            error527: '文件格式错误',
            error528: '文件不存在',
            error529: '建立连接失败',
            error530: '未开启抓拍存储',
            error531: '音频文件正在播放中',
            error532: '继电器数量已达上限',
            error533: '未找到匹配的升级文件',
            error534: '文件接受失败',
            error535: '文件目录不存在',

            error537: 'rtsp地址包含不合规字符',

            // 远程设备接入错误码
            error601: '超出限定视频路数',
            error602: '设备接入失败',
            error603: '设备名称已存在',
            error604: '设备已绑定任务配置，不允许删除',

            // 智能业务错误码
            error10000: '人脸图片尺寸异常（太大、太小）',
            error10001: '没有检测到人脸',
            error10002: '人脸未通过质量判断',
            error10003: '人脸不存在',
            error10004: '人脸已经存在',
            error10005: '人员分组不存在',
            error10006: '人员分组已存在',
            error10007: '人员分组超出上限',
            error10008: '人员分组绑定关系不存在',
            error10009: '人员分组正在使用，不能删除',
            error10010: '人员分组绑定关系已存在',
            error10011: '人脸库同步中，请稍等（设备刚启动时，加在数据时，禁止人脸库的相关操作）',
            error10012: '人脸库导入中，请稍等（人脸入库过程中禁止修改人员信息、导入等操作）',
            error10013: '人脸库图片超过上限',
            error10014: '人脸库入库，设备端缓存图片上限，需要重传',
            error10015: '人脸库导入已经停止',
            error10016: '人脸库操作超时，设备端自动停止入库',
            error10017: '人员信息冲突',
            error10018: '人员uuid冲突',
            error10019: '人员名冲突',
            error10020: '人员编号冲突',
            error10021: '人员门禁卡号冲突',
            error10022: '动态底库未使能',
            error10023: '人员特征值数据无效',
            error10024: '默认人员分组不允许删除',
            error10025: '默认人员分组不允许修改',
            error10026: '未检测到目标',
            error10027: '人脸角度过大',
            error10028: '图片质量太差',
            error10029: '图像黑白比例过高',
            error10030: '该人员仅关联一个人员组，不允许移除',

            error11001: '通道算力不足',
            error11002: '通道不支持该算法重复布控',
            error11003: '该算法已关联联动计划，请先取消联动再删除',

            error11005: '设备已绑定智能任务',
            error11014: '存在算法未安装，请先安装算法',

            // 算法仓管理
            error12001: '算法仓标识校验失败',
            error12002: '算法仓升级文件名包含不合规字符',
            error12003: '算法仓升级文件名重复',
            error12004: '算法仓升级文件解压失败',
            error12005: '算法仓升级文件内容有误',
            error12006: '算法仓操作中',
            error12007: '算法标识重复，请勿重复安装',
            error12008: '已安装算法其他版本，建议升级',
            error12009: '算法仓不支持卸载',
            error12010: '算法包依赖版本错误',
            error12011: '算法包仅支持升级',
            error12012: '此算法仓未授权，请先导入授权，授权激活后算法仓自动安装（算法量产仓需授权激活后手动安装）',
            error12013: '算法仓状态错误，请刷新后重试',

            // 授权文件管理
            error13001: '授权文件名包含不合规字符',
            error13002: '授权文件太大或太小',
            error13003: '相同名称授权文件已存在',
            error13004: '当前授权文件已存在',
            error13005: '授权文件密钥与此设备不匹配',
            error13006: '授权文件损坏，无法导入',
            error13007: '授权文件校验参数错误',
            error13008: '当前授权模式不支持临时文件导入',
            error13009: '授权文件数量超过上限',
            error13010: '授权文件行业标识错误',
            error13011: '授权文件名长度错误',
            error13012: '授权文件类别非法',
            error13013: '授权文件维度非法',
            error13014: '授权控制粒度非法',
            error13015: '授权对象类型非法',
            error13016: '授权SKU非法',
            error13017: '授权SKU重复',
            error13018: '当前授权模式不匹配',
            error13019: '没有授权文件，不支持此操作',
            error13020: '目标SKU已存在永久授权，无法导入临时授权文件',
            error13021: '授权文件写入路数和当前设备型号硬件规格不匹配',

            // 规划模块
            error20001: '时间计划正在使用中（绑定了人员分组时不可删除）',
            error20002: '时间计划已存在（新建时间计划名称重复）',
            error20003: '时间计划超出规格上限',
            error20004: '时间计划不存在',
            error20005: '时间计划已绑定联动业务，不允许删除',
            error20006: '时间计划已绑定联动布控任务，不允许删除',
            error20007: '默认计划不允许删除',
            error20008: '节假日已经绑定了常规计划',

            // 容器管理模块
            error21001: '容器个数达上限',
            error21002: '镜像校验失败',
            error21003: '镜像文件重复',
            error21004: '容器未安装',
            error21005: '镜像解压失败',
            error21006: '容器不存在',
            error21007: '容器未运行',
            error21008: '容器已运行',
            error21009: '容器镜像名非法',
            error21010: '资源不足',
            error21011: '容器已安装',
            error21012: '容器名无亲缘性，禁止升级',
            error21013: '容器名亲缘性，禁止上传',
            error21014: '容器资源分配失败',

            // 联动计划
            error22001: '联动计划名称不合法',
            error22002: '联动计划已存在',
            error22003: '联动计划不存在',
            error22004: '联动计划超过上限',
            error22005: '设备已绑定联动计划，不允许删除',
            error22006: '继电器已绑定联动计划, 不允许删除',
            error22007: '人员分组已绑定联动计划，不允许删除',
            error22008: '人员分组已绑定布控任务，不允许删除',
            error22009: '联动计划绑定的继电器不存在',
            error22010: '联动计划绑定的文件不存在',
            error22011: '联动计划的报警输出未使能',
            error22012: '联动未选择继电器',
            error22013: '联动未选择音频',
            error22014: '联动未选择485',
            error22015: '联动未选择报警输出',

            error23001: '继电器触发失败',
            error23002: '语音播放任务列表数量超过限制',

            // main.js中axios单独处理的错误码提示语
            sameTaskSelectTips: '同一设备不允许同时布控脸人和结构化',
            restartTips: '系统重启中，请等待...',
            upgradeTips: '系统升级中，请等待...',
            groupIsUseTips: '人员分组正在使用，不能修改',
            groupImportTips: '人脸库导入中，请勿升级',
            paramFormatError: '参数格式错误',
            registeringTips: '注册期间，请勿操作',
            outTimeRangeTips: '系统时间不能早于20190801，请重新设置'
        },
        intelRecord: {
            identityRecord: '识别记录',
            alarmRecord: '告警记录',
            captureRecord: '抓拍记录',
            peopleCount: '人数统计',
            recordDeduplicate: '记录去重',
            createTaskConfig: '新建任务',
            export: '导出',
            exitBatchOperate: '取消批量',
            batchOperate: '批量操作',
            reset: '重置',
            expand: '展开',
            putAway: '收起',
            captureTime: '抓拍时间',
            captureDevice: '抓拍设备',
            identityResult: '识别结果',
            all: '全部',
            identitySuccess: '识别成功',
            identityFailed: '识别失败',
            personName: '人员姓名',
            similarity: '相似度',
            capturePic: '抓拍图',
            personFace: '人脸',
            panoramic: '全景',
            person: '人员',
            bottomLibraryFace: '底库人脸图',
            name: '姓名',
            belongingGroup: '所属分组',
            pleaseInput: '请输入',
            identityInfo: '识别信息',
            bottomLibaryPic: '底库图',
            panoramicPic: '全景图',
            otherComparisonResult: '其他比对结果',
            recordInfo: '记录详情',
            targetMatting: '目标抠图',
            alarmType: '告警类型',
            alarmTask: '告警任务',
            alarmArea: '告警区域',
            alarmScore: '置信度',
            alarmInformation: '告警信息',
            personnelDetails: '人员详情',
            faceImage: '人脸图',
            selectPersonnel: '选择人员',
            falseAlarm: '误报',
            UploadFiles: '上传文件',
            UpdateTime: "更新时间",
            PreviousVersion: "上个版本",
            fileFormat: "文件格式: .tar 或者 .pkg",
            uploadFileFormat: "文件格式: .tar",
            batchExport: "批量导出",
            selectExportAction: '选择导出形式',
            exportExcel: '仅导出表格',
            excelTips: '不含图片，以excel表格形式导出，文件名称为识别记录_[导出时间戳]',
            exportImage: '仅导出图片',
            imageTips: '图片以压缩包形式导出',
            excelAndImage: '表格+图片',
            allTips: '记录以excel表格形式导出，图片以压缩包形式导出。 excel表中增加图片地址，展示压缩包中图片名称。',
            selectExportRecord: '请选择要导出的内容',
            selectDeleteItem: '请选择要删除的内容',
            deleteSingleRecordTips: '是否确认删除该记录？',
            deleteSelectRecordTips: '是否删除所选记录？',
            notBindInfo: '没有查询到绑定数据',
            device: '设备',
            selectedDevice: '请选择设备！',
            targetType: '目标类型',
            allType: '全部类型',
            livingFraction: '活体分数',
            area: '区域',
            bindVehicle: '绑定车辆',
            galass: '眼镜',
            mask: '口罩',
            hat: '帽子',
            bindFace: '绑定人脸',
            bindPlates: '绑定车牌',
            plateRecog: '车牌识别',
            bindNonMotors: '绑定非机动车',
            image: '图片',
            areaPerson: '区域人数',
            target: '目标',
        },

        // 人员管理
        resourceManage: {
            changeFaceGroups:'请选择关联分组',
            personnelManage: '人员管理',
            personnelGroup: '人员组',
            photo: '照片',
            name: '姓名',
            group: '所属分组',
            newPerson: '新增人员',
            editPerson: '编辑人员',
            addPerson: '添加人员',
            newPersonGroup: '新增人员组',
            personGroupName: '人员组名称',
            fillPersonGroupName: '请填写人员组名称（支持中文，英文大小写，数字，特殊字符(-_)，最大支持32个字符,中文占3个字符',
            editPersonGroup: '编辑人员组',
            confirmDelete: '确认删除人员组？',
            batchRemove: '批量移除',
            personName: '人员姓名',
            personId: '人员ID',
            pleaseInput: '请输入',
            searchPeopleGroupName: '搜索人员组名称',
            personList: '人员列表',
            searchPeople: '请搜索人员',
            selectedList: '已选列表',
            selected: '已选择',
            num: '个',
            personPhotos: '人员照片',
            remarks: '备注',
            uploadPicture: '请上传图片',
            inputName: '请按规则输入姓名（1-32位中英文、数字、·和空格不允许单独输入）',
            selectGroup: '请选择所属分组',
            groupName: '组名',
            import: '人员底库批量导入',
            deleteAllConfirm: '确认删除全部人员？',
            mode: '方式',
            byPersonName: '按人员姓名',
            byPersonId: '按人员编号',
            byPersonNameAndId: '按人员姓名和编号',
            personTotal: '人员总数',
            successNum: '入库成功数量',
            failNum: '入库失败数量',
            exceptionFile: '异常文件',
            pictureSize: '请重新选择文件数量小于10万张！',
            selectFolder: '请选择一个文件夹进行入库！',
            selectOneGroup: '请至少选择一个分组！',
            selecteds: '已选',
            files: '个文件',
            pleaseInputText: '请输入文本内容',
            newAdd: '新增',
            preserve: '保存',
            return: '返回',
            deleteSinglePersonTips: '确认删除人员？',
            deleteSelectPersonTips: '确认删除所选人员？',
            removeSelectPersonByGroup: '确认移除所选人员与该组的绑定关系？',
            imageName: '图片名称',
            failedResult: '失败原因',
            time: '时间',
            clearFailed: '清空列表',
            failedEntry: '入库失败列表',
            deleteGroupTip1: '解除组内人员的绑定，并删除人员分组',
            deleteGroupTip2: '删除人员分组及人员分组中的人员，若人员与其他分组有绑定关系将一同解绑',
            deleteGroupTip3: '清除分组中的全部人员，保留分组，如果人员还有绑定其它分组，将只解绑人员',
            personInfo: '人员信息',
            removeSuccess: '清除成功',
            clearFailureList: '是否确认清除失败入库列表',
            addSuccess: '添加成功',
            addFail: '添加失败',
            maxGroupTips: '最大支持创建64个人员组，已达上限',
            pleaseSelectGroup: '请先选择人员组',
            timeUsed: '所用时间',
            expectedRemainder: '预计剩余时间',
            somePersonRemoveFail: '部分人员移除失败',
            onlyGroupTips: '项因人员仅关联一个人员组，不允许移除',
            personRemoveSuccess: '移除成功',
            tryAgain: '请重试',
            prompt: '提示',
            batchImportTips1: '人脸图片文件名录入后为人员姓名，请遵循规则',
            batchImportTips2: '（1-32位中英文、数字、·和空格不允许单独输入）',
            uploadCompleted: '上传结束',
            uploadError: '上传出错',
            facialPosition: '人脸位置',
            readFaceImg: '人脸底库图片读取中',
            uploadProgress: '上传进度',
            handleProgress: '处理进度',
            uploadSuccess: '上传成功',
            uploadFail: '上传失败',
            imageTotal: '图片总量',
            preUploadTotal: '预上传总数',
            realityReceiveCount: '实际接收个数',
            receivedSuccess: '入库成功',
            receivedFail: '入库失败',
        },
        // 离线解析
        offlineParsing: {
            parseContent: '解析内容',
            parseResult: '解析结果',
            toOne: '1:1',
            toN: '1:N',
            featureExtraction: '特征提取',
            structuredParsing: '结构化解析',
            intelligentParsing: '智能解析',
            uploadPic: '请先上传图片',
            imageOne: '照片1',
            imageTwo: '照片2',
            clickUpload: '点击上传',
            uploadImage: '上传照片',
            livingScore: '活体评分',
            search: '查询',
            Reset: '重置',
            uploadFormat: '上传图片格式',
            similarity: '相似度结果 TOP5',
            invalidFace: '未检测到有效人脸',
            targetType: '目标类型',
            face: '人脸',
            bodyCapture: '人体',
            vehicle: '机动车',
            nonMotor: '非机动车',
            plate: '车牌',
            offlineParsing1: '人脸',
            offlineParsing2: '人体',
            offlineParsing3: '机动车',
            offlineParsing4: '非机动车',
            offlineParsing5: '车牌',
            info: '信息',
            bodyInfo: '人体信息',
            topStyle: '上衣款式',
            topColor: '上衣颜色',
            lowerBodyStyle: '下身款式',
            lowerBodyColor: '下身颜色',
            algorithmType: '算法类型',
            targetMap: '目标图',
            targetTypeTips_1: '未检测到人脸，请重新上传图片',
            targetTypeTips_2: '未检测到人体，请重新上传图片',
            targetTypeTips_3: '未检测到机动车，请重新上传图片',
            targetTypeTips_4: '未检测到非机动车，请重新上传图片',
            targetTypeTips_5: '未检测到车牌，请重新上传图片',
            uploadPicture: '请上传图片',
            selectedTargetType: '请选择目标类型',
            selectedGroup: '请选择人员组',
            analyzing: '正在解析中...',
            notDetected: '图片中未检测到',
            notDetectedSelectType: '图片中未检测到所选类型'
        },
        menus: { // 新版菜单，老版菜单字段用menu
            preview: '实时预览',
            intelRecord: '智能记录',
            intelConfig: '智能配置',
            taskConfig: '任务配置',
            newTask: '新建任务',
            timePlan: '时间计划',
            linkagePlan: '联动计划',
            offlineAnalysis: '离线分析',
            resourceManage: '资源管理',
            personManage: '人员管理',
            deviceManage: '设备管理',
            algorithmManage: '算法仓管理',
            authorManage: '授权管理',
            containerManage: '容器管理',
            docManage: '文件管理',
            systemManage: '系统管理',
            systemConfig: '系统配置',
            logManage: '日志管理',
            devOpsManage: '运维管理',
            offlineParsing: '离线解析',
            dataRecv: '数据对接',
            authorizationManagement: "授权管理",
            unnamed: '未命名',
            falseAlarmLibrary: '误报库管理',
        },
        preview: {
            deviceList: '设备列表',
            videoPreview: '视频预览',
            emptyVideoTips: '可将设备拖拽到视频框',
            loadVideoTips: '加载中',
            timeoutTips: '视频播放超时',
            ruleConfig: '布控设置',
            rule: '规则',
            allSelect: '全选',
            detail: '查看详情',
            videoDevice: '视频流相机',
            imageDevice: '图片流相机',
            watching: '预览中'
        },
        taskConfig: {
            targetPolygon: '目标大小',
            taskName: '任务名称',
            taskType: '任务类型',
            taskSchedule: '任务计划',
            taskDetail: '任务详情',
            addTask: '新建任务',
            editTask: '编辑任务',
            algCardType: '规则类型',
            taskType_single_point_task: '单点任务',
            taskType_mutil_point_task: '轮询任务',
            deviceName: '设备名称',
            drawArea: '绘制区域',
            normalArea: '多边形',
            normalAreaLabel: '检测区域',
            fullArea: '全屏',
            maskArea: '屏蔽区域',
            drawLine: '绘制绊线',
            singleLine: '单向单绊线',
            singleLineBoth: '双向单绊线',
            preAlarmLine: '双向双绊线',
            preAlarmOneDireLine: '单向双绊线',
            outInLine: '出入口拌线',
            preLine: '智能预警线（双向）',
            alarmLine: '智能报警线（双向）',
            preOneDireLine: '智能预警线（单向）',
            alarmOneDireLine: '智能报警线（单向）',
            targetPreview: '目标框预览',
            clearCurrent: '清除选中',
            clearAll: '清除全部',
            emptyVideoTips: '暂未选中设备',
            noSelectToDeleteTips: '请先选中某一个规则，在进行删除',
            noVideoLoadedToDrawTips: '请等待视频加载完成后，再进行绘制',
            maxNormalAreaTips: '单任务最多支持绘制8个检测区域',
            maxMaskAreaTips: '单任务最多支持绘制4个屏蔽区域',
            maxSingleLineTips: '单任务最多支持绘制2条单向单绊线',
            maxSingleLineBothTips: '单任务最多支持绘制2条双向单绊线',
            maxPreAlarmLineTips: '单任务最多支持绘制2组双向双绊线',
            maxPreAlarmOneDireLineTips: '单任务最多支持绘制2组单向双绊线',
            maxOutInLineTips: '单任务最多支持绘制1组出入口绊线',
            editAttrSuccess: '参数保存成功',

            //------任务配置相关字段----------
            targetTypes: '目标类型',
            duration: '延迟报警',
            targetSize: '目标大小',
            cooldownDuration: '报警间隔',
            group: '人员组',
            schedulePlan: '时间计划',
            attrAnalyze: '属性分析',
            isOncall: '是否上报',
            isOncallTips1: '上报全局最优陌生人',
            isOncallTips2: '不上报全局最优陌生人',
            retryTimes: '二次识别次数',
            retryDuration: '二次识别间隔',
            strangerRetryCount: "陌生人重试次数",
            minPushInterval: "最小推图间隔",
            basicSettings: "基础配置",
            advancedSettings: "高级配置",
            ruleSettings: "规则配置",
            algoSettgins: "算法配置",
            regularPlan: "常规计划",
            holidayPlan: "节日计划",
            createRegularPlan: "新建常规计划",
            regularTime: "日常时段",
            createTimeplan: '新建时间计划',
            cancel: '取消',
            save: '保存',
            relatedHolidayPlans: '关联节日计划',
            endDraw: '结束绘制',
            noSelected: '未选中',
            operateGuide: '操作引导',
            operateGuide_draw_prompt: '注意：请在绘制区域内绘制拌线',
            operateGuide_area: '绘制区域',
            operateGuide_area_tips1: '绘制：左键单击开始绘制，可绘制3-8个点。当点数达到8个时自动闭合',
            operateGuide_area_tips2: '闭合：连接起始点或单击右键闭合区域',
            operateGuide_area_tips3: '数量：检测区域最多绘制8个，屏蔽区域最多绘制4个',
            operateGuide_line: '绘制拌线',
            operateGuide_line_tips: '左键单击开始绘制，可绘制2-4个点。当点数达到4个时自动完成。单向双绊线报警方向默认为预警线到报警线，与画线预览箭头方向无关，建议绘制时尽量保持箭头方向为预警线朝向报警线，以免造成误解',
            operateGuide_alg: '算法配置',
            operateGuide_alg_tips: '选中绘制的区域/拌线后，点选需要配置的算法类型；点击右上角icon进行属性配置',
            noneBasicsTips: '此算法无基础配置项，更多配置请点击高级配置查看',
            EngineerSurveyDoc: '工勘文档',
            taskNameTips: '请输入正确的任务名称：支持中文，英文大小写，数字，特殊字符( -_ )，最大支持32个字符,中文占3个字符',
            taskScheduleTips: '请选择任务计划',
            taskDeviceTips: '请选择设备',
            prompt: '提示',
            configCardTips: '请至少配置一个算法卡片再进行提交',
            notConfigCardTips: '未配置算法卡片',
            finishTips: '请先完成',
            draw: '绘制',
            onlyBindTips: '脸人&结构化算法只能绑定一个检测区域',
            gotIt: '知道了',
            onlyBindWiresTips: '算法卡片只允许绑定一组单/双绊线',
            drawFirstTips: '请先绘制检测区域/绊线',
            notDrawLineTips: '算法未绘制规则线',
            negativeFilter: '负样本过滤',
            useIntelRect: '启用目标框叠加',
            intelRect: '目标框叠加',
            intelRectTips: '仅脸人，结构化，警戒算法支持目标框叠加，如果画面目标数量太高，叠加会失效。建议单通道不超过5个目标。如果启用警戒算法，则按照警戒算法检测的目标叠加检测框，人脸/结构化和其他算法同时布控后不做实时目标框的显示',
        },
        // 容器管理新加字段
        containerManage: {
            Running: "运行中",
            Stopped: "已停止",
            Abnormaloperation: "运行异常",
            uploadContainer: "上传容器",
            uploadContaifile: "上传容器文件",
            upgradeContainer: "升级容器文件",
            containeRfileFormat: "文件格式:.img",
            filePlay: "播放",
            fileFormat: "文件格式：.pcm .mp3 .MP3",
            addTo: "添加",
            cancel: "取消",
            operationErr: "操作失败",
            uninstallSuccee: "操作成功",
            maxUploadTips: '最多只能上传4个容器文件',
        },
        // 系统管理
        systemManagement: {
            exportExc:'导出Excel',
            logManagement: "操作日志",
            systemLog: "系统日志",
            DebugLog: "调试日志",
            dataLog: '数据日志',
            timestamp: '记录时间',
            operationType: "操作类型",
            logNo: "日志编号",
            operatingUser: "操作用户",
            logModule: "日志模块",
            logType: "日志类型",
            logDetails: "日志详情",
            logLoading: "正在导出日志，请勿关闭页面",
            exportLog: "导出日志",
            basicsConfig: '基础配置',
            networkConfig: '网络配置',
            timeConfig: '时间配置',
            loginConfig: '登录配置',
            storageConfig: '存储配置',
            hardwareConfig: '硬件配置',
            algorithmConfig: '算法配置',
            softwareConfig: '软件配置',
            configActions: '配置操作',
            configSystem: '配置系统',
            systemUpgrade: '系统升级',
            restart: '重启',
            LOGOPic: 'LOGO 图片',
            wanConfig: 'WAN 配置',
            lanConfig: 'LAN 配置',
            heartbeatServerConfig: '心跳服务器配置',
            interval: '校时间隔',
            reset: '重置',
            preserval: '保存',
            selectDate: '选择日期',
            manualUpgrade: '手动升级',
            upgradeMethod: '升级方式',
            systemUpgradeFile: '系统升级文件',
            enableStatus: '启用状态',
            savePanorama: '是否保存全景图',
            videoStorageAddr: '录像存储地址',
            accountName: '账户名称',
            loginTimeOut: '登录超时时间',
            loginLimit: '登录次数限制',
            unlockTime: '解锁时间',
            minute: '分钟',
            second: '秒',
            timeFrame: '1-3600秒',
            configInfo: '配置信息',
            uploadTip: '上传建议：.jpg .png 且大小不超过 5M，图片最小尺寸 ≥ 50*50',
            platformAccess: "平台接入",
            alarmReporting: "报警上报",
            client: "客户端",
            serverword: "服务器",
            hasUsedAlgorithm: "已使用算力",
            algorithmStatistics: "可用算力统计",
            cancelUpgrade: '取消升级',
            decompression: '需要解压后升级',
            formatError: '上传文件格式错误',
            upgrading: '升级中...',
            // waitForUpgrade: '请稍等！设备升级中',
            waitForUpgrade: '升级包上传成功，请稍等！',
            deviceRestart: '设备重启中！即将跳回登录页面，请等待……',
            hardwareConfigTitle: '485配置',
            alertConfig: '报警开关量配置',
            mode: '模式',
            isEnable: '是否使能',
            timeout: '超时时间',
            timeoutRepeatNum: '超时重发次数',
            channel: '通道',
            channel_1: '通道1',
            channel_2: '通道2',
            channel_3: '通道3',
            channel_4: '通道4',
            isUse: '是否启用',
            workMode: '工作模式',
            millisecond: '毫秒',
            millisecond1: '毫秒（100-50000）',
            second2: '秒（0-10）',
            millisecond2: '毫秒（0-50000）',
            test: '测试',
            testSuccess: '测试成功',
            normalOpen: '常开',
            normalClose: '常闭',
            heartbeat: '心跳包',
            heartbeatCommandInfo: '心跳指令信息',
            heartbeatResponseInfo: '心跳应答信息',
            upgradeOkTips: 'Logo上传成功，请保存',
            defaultTipes: '已恢复默认，请保存！',
            saveFail: '保存失败，请重试！',
            aesKeyTips: '通信密码必须为16个字节',
            count: '次',
            captureCamera: '图片流（抓拍机）参数配置',
            globalAlgorithm: '全局算法配置',
            pictureRetryCount: '图片流二次识别次数',
            pictureMinInterval: '图片流最小识别间隔',
            pictureStrangerRetryCount: '图片流陌生人多次判定',
            advancedParamsConfig: "高级参数配置",
            advancedParamsConfigTip: '开启后展示全量算法参数，部分参数较为复杂，建议联系技术支持帮助。',
            recordStorage: '记录存储',
            targetBoxDraw: '目标框绘制',
            bgcMassCoefficient: '背景图质量系数',
            bgcMassCoefficientTip: '配置范围10-100，当设置系数为100时为原图，系数越小背景图质量越差，但占用存储空间越小',
            recordStorageNum: '识别记录存储数量',
            nonRecordStorageNum: '非识别记录存储数量',
            rtspPullFlow: 'rtsp拉流',
            rtspPullFlowTips: '开启后支持通过rtsp流地址拉取盒子上的视频通道；流地址示例：rtsp://用户名:密码@IP:端口/通道号。',
            dynamicBaseLibrary: '动态底库',
            dynamicBaseLibraryTips_maxNum_15: '开启后底库容量降为15W，分组不变。',
            dynamicBaseLibraryTips_maxNum_5: '开启后底库容量降为5W，分组不变。',
            dynamicBaseLibraryTips_15: '原底库数据将被清空并重启（未超过15W底库不会被清空），是否确认开启/关闭？',
            dynamicBaseLibraryTips_5: '原底库数据将被清空并重启（未超过5W底库不会被清空），是否确认开启/关闭？',
            realtimePreview: '外界屏实时预览配置',
            windowCount: '窗口数',
            windowChannel: '视频通道对应窗口',
            window: '窗口',
            commSafe: '通信安全',
            parityNone: '不校验',
            parityOdd: '奇校验',
            parityEven: '偶校验',
            parityMark: 'mark',
            paritySpace: 'space',
            error: '保存失败，请重试',
            timePlan: '时间计划',
            lanTips: '主备模式和平衡模式，只有WAN生效',
            intervalTips: '校时间隔为1-65535分钟',
            pleaseEnterUpgradeAddr: '请输入升级服务器地址',
            confirmUpgrade: '确认升级？',
            queryUpgradePackage: '查询升级包',
            upgradeConfirmSuccess: '升级配置成功',
            upgradeConfirmFail: '升级配置失败',
            requestFail: '请求失败',
            upgradePackageNotFound: '升级包未找到',
            selectTimePlan: '请选择时间计划',
            configSuccess: '配置成功',
            configFail: '配置失败',
            logoUploadTips: 'logo暂未上传完毕，请等待后重新提交',
            usernameEmptyTips: '用户名不得为空',
            deviceRestartTips: '设备正在重启中，请稍等...',
            empty: '无',
            singlePointNum: '单点任务数',
            queryWayNumTips: '请勾选算法类型，查看剩余可布控路数',
            countResidueWay: '计算剩余可添加任务数',
            availableWay: '可用路数',
            videoStream: '视频流',
            imageStream: '图片流',
            or: '或',
            selectAlgorithmTips: '请选择算法类型',
            sameTimeSelectTips: '脸人和结构化不可同时选择！',
            commSuccess: '通信安全配置成功',
            commFail: '通信安全配置失败',
            wanLanSuccess: 'WAN/LAN配置成功',
            wanLanFail: 'WAN/LAN配置失败',
            cloudAssist: '云边协同',
            serveStatus: '服务器连接状态',
            successConnect: '连接成功',
            failConnect: '连接失败',
            dataReportType: '数据上报类型',
            timeoutMechanism: '超时机制',
            selectedDataReportType: '请选择数据上报类型',
            policyNotice: '政策须知',
            populationStatistics: '出入口人数统计上报',
            enable: '开关',
            statisticsInterval: '统计时间间隔配置',
            alarmIoConfig: '报警IO配置',
            breaker: {
                alarmOutput: '报警输出',
                outputPort: '输出端口',
                mode: '模式设置',
                alarmTime: '报警持续时间',
                isEnable: '不使用',
                open: '常开',
                close: '常闭',
                msg: '最大支持10行扩展',
                alarmIO: '报警IO口'
            },
            jumpToLoginPage: '即将跳转至登录页面',
            maxBytesTips: '最大支持32字节',
            cnMaxBytesTips: '最大支持32字节（中文占3个字节）',
            userNameTips: '支持大小写字母、数字，最大支持32位'
        },
        // 数据对接新增字段
        dataDocking: {
            serverSucessIP:'请输入正确的服务器IP',
            registerName:"注册用户名",
            registerPassword:"注册密码",
            FTPtransmission: 'FTP传图',
            enabled: "已开启",
            closed: "已关闭",
            serverIP: "SIP服务器IP",
            ip: '服务器IP',
            pleaseEnter: "请输入",
            serverport: "服务器端口",
            connectionStatus: "连接状态测试",
            test: "测试",
            timeInterval: "识别重发间隔时间",
            retransmissions: "识别重发次数",
            uploadSnapshot: "上传抓拍小图",
            uploadBackground: "上传背景大图",
            transferCharacteristic: "传输特征数据",
            reportingMode: "上报模式",
            protocolType: "协议类型",
            EquipmentcodeID: "设备编码 ID",
            devicedomainID: "设备域 ID",
            transportProtocol: "传输协议",
            deviceCard: "本地IP",
            keepInterval: '保活间隔',
            private: "私有SDK",
            servercodeID: "SIP服务器编号",
            ServerdomainID: "SIP域",
            ServerPort: "SIP服务器端口号",
            maxHeart: "最大心跳超时次数",
            equipmentCode: "设备编码",
            addRow: "增加行",
            serverIPaddress: "服务器 IP 地址",
            viewLibrary: "视图库 ID",
            synchronization: "同步时间间隔",
            passiveMode: "被动模式端口",
            maxThirtyTwo: "最多只能添加16条",
            FtpUsername: '用户名最大长度为32个字符',
            FtpPassward: '密码最大长度为32个字符',
            deviceIDLength: '设备编码 ID 长度应为20个或0个字节',
            alertReportMode:"识别信息主动上报",
            captureReportMode:"抓拍信息主动上报",
            registerStatus_1: '未连接',
            registerStatus_13: '心跳超时',
            registerStatus_14: '连接失败',
            registerStatus_21: '正在注册',
            registerStatus_31: '连接成功',
            gat1400Port: '请正确输入端口（1024-65535）',
            username: '用户名',
            password: '密码',
            mainServerTips: '请完善主服务器用户名和密码！',
            slaveServerTips: '请完善从服务器用户名和密码！',
            persistReportTips: '开启上传失败数据重发，需要填写主服务器地址'
        },
        deviceManage: {
            basicDevice: '基础设备',
            externalDevice: '外接设备',
            deviceName: '设备名称',
            deviceType: '设备类型',
            deviceAddr: '设备地址',
            relayIP: '继电器IP',
            agreementType: '协议类型',
            deviceStatus: '设备状态',
            newDevice: '新建设备',
            editDevice: '编辑设备',
            findDevice: '发现设备',
            batchDelete: '批量删除',
            deviceID: '设备ID',
            manufacturer: '厂商',
            manufacturer_unknown: '未知',
            manufacturer_megvii: '旷视',
            ip: 'IP',
            port: '端口',
            userName: '用户名',
            password: '密码',
            autoDistribution: '自动分配',
            autoObain: '自动获取',
            enterDeviceName: '请输入设备名称（支持中文，英文大小写，数字，特殊字符(包括-_)，最大支持32个字符,中文占3个字符）',
            enterDeviceType: '请选择设备类型',
            enterAgreementType: '请选择协议类型',
            enterIp: '请输入合法IP',
            enterPort: '请输入端口（1-65535）',
            enterUserName: '请输入用户名（最大支持32个字符，中文占3个字符）',
            enterPassword: '请输入密码（最大支持32个字符，支持英文、数字、特殊字符）',
            enterRtspUrl: '请输入正确的RTSP地址（最大支持256个字符，其中用户名密码各支持最大32位）',
            enterTransportType: '请选择传输协议',
            enterChannelNum: '请输入设备通道号',
            enterGatDeviceId: '请输入1400设备ID',
            enterGbDeviceID: '请输入系统编码ID',
            enterInternationalCode: '请输入20位国际编码',
            enterSnCode: '请输入20位SN编码',
            deviceAbility: '设备能力',
            batchAdd: '批量添加',
            exitBatchAdd: '退出批量添加',
            add: '添加',
            addDevice: '添加设备',
            rtspUrl: 'RTSP流地址',
            getDeviceInfo: '获取设备信息',
            deviceState_0: '在线',
            deviceState_1: '离线',
            deviceState_2: '认证失败',
            deviceState_true: '在线',
            deviceState_false: '离线',
            deviceState_basics0: '在线',
            deviceState_basics1: '离线',
            deviceState_basics2: '认证失败',
            deviceState_basics3: '视频流格式错误',
            deviceState_basics4: '分辨率过高',
            deviceState_basics5: '解码失败',
            deviceState_basics6: '超出最大解码能力',
            deviceState_basics7: 'h265多title模式不支持',
            agreementType_megconnect: 'megconnect',
            agreementType_onvif: 'onvif',
            agreementType_rtsp: 'rtsp',
            agreementType_gat1400: 'gat1400',
            agreementType_gb28181: 'gb28181',
            agreementType_aiotap: 'aiotap',
            channelType_0: '传感器',
            channelType_1: '网络摄像机',
            channelType_2: '音频',
            channelType_3: '智能摄像机',
            channelType_4: '混合', // 接口枚举0,1,2,3值
            transportType: '传输协议',
            delete: '确认删除所选设备？',
            channelNum: '设备通道号',
            gatDeviceId: '1400设备ID',
            gbDeviceID: '设备编号',
            internationalCode: '通道编号',
            modifySuccess: '修改成功',
            modifyError: '修改失败',
            submitSuccess: '提交成功',
            addSuccess: '新建成功',
            addError: '新建失败',
            peripheralType: '外设类型',
            peripheralID: '外设ID',
            enable: '使能开关',
            outChannel: '输出通道',
            mode: '工作模式',
            interval: '触发间隔',
            duration: '触发时长',
            delay: '触发延时',
            timeUnit: '时间单位',
            serialId: '串口ID',
            serialType: '串口类型',
            baudrate: '波特率',
            dataBits: '数据位',
            stopBits: '停止位',
            parity: '校验位',
            peripheralType_relay: '继电器',
            peripheralType_serial: '串口',
            mode_0: '常开',
            mode_1: '常闭',
            mode_2: '485-2',
            timeUnit_0: '100毫秒',
            timeUnit_1: '秒',
            parity_none: '不校验',
            parity_odd: '奇校验',
            parity_even: '偶校验',
            enterUsnAndPaw: '请输入账号和密码',
            deviceSwitch_true: '启用',
            deviceSwitch_false: '未启用',
            relayIp: '继电器IP',
            macAddr: 'MAC地址',
            workMode: '工作模式',
            triggerOften: '触发时长',
            triggerDelay: '触发延时',
            triggerInterval: '连续触发间隔',
            startUse: '启用',
            gettingTips: '获取中，请稍等...',
            relayMaxTips: '继电器设备已达上限！',
            netVideoMaxTips: '网络摄像机设备已达上限！',
            intelVideoMaxTips: '智能摄像机设备已达上限！',
            selectOutChannel: '请选择输出通道',
            selectMode: '请选择工作模式',
            selectPeripheralType: '请选择外设备类型',
            enterNumber: '请输入数字',
            actionMode: '主动模式',
            snCode: 'SN编码',
            findDeviceMaxTips: '可建设备已达上限，已无可用设备ID',
            millisecond: '毫秒',
            second: '秒',
            triggerSuccess: '触发成功',
            triggerFail: '触发失败',
            partDeviceDeleteFail: '部分设备删除失败',
            batchDeleteFailTips: '以下ID设备删除失败，请重试',
        },
        // 设备激活新增字段
        deviceActivation: {
            verifyPassword: "密码长度不允许少于8位",
            verifySupplement: "密码必须由小写字母,大写字母,数字,特殊字符中的三种组合,最大支持32个字符",
            learnMore: "登录官方网站,了解更多",
            firstLogin: "首次登录，请先为默认账户 admin 设置新密码，并激活设备",
            active: "设备正在激活中",
        },
        // 授权管理
        authorizationManagement: {
            empowerSKU:"授权SKU",
            industryIdentification: "授权名称",
            authorizationAlgorithm: "授权算法",
            authorizationStatus: "授权状态",
            fileDetails: "授权文件详情",
            expireTime: "到期时间（无时区）",
            onlyUpload: "只能上传.lic文件",
            fileErr: "文件有误",
            authorizationActivation:"正在激活中,请勿关闭当前页面",
            currentAuthorization:"当前授权：",
            standardAuthorization:"标准授权",
            customAuthorization:"自定义授权",
            switch:"切换",
            switchTitle:"点击后将在标准授权和自定义授权模式间进行切换",
            switchSuccess: '切换成功',
        },
        // 误报库管理
        falseAlarmLibrary: {
            selectActionTips: '请选择要操作的内容',
            revoke: '撤销',
            linkSample: '关联负样本',
            addSample: '加入负样本',
            falseAlarmHandle: '误报处理',
            falseAlarmTips: '是否确认做误报处理，将记录放入负样本库并删除记录？记录删除后无法恢复',
            filterScore: '过滤分数',
            negativeSampleLibrary: '负样本库',
            filterRecord: '过滤记录',
            batchRevoke: '批量撤销',
            sampleType: '样本类型',
            filterThreshold: '过滤阈值',
            libraryConfig: '库配置',
            addSampleTips: '是否将选中记录加入负样本？',
            saveSuccess: '保存成功',
            revokeRecodeTips: '是否确认撤销选中记录？',
            revokeSuccess: '撤销成功',
            revokeFail: '撤销失败',
            notFindLinkTips: '关联负样本已删除，关联失败',
            sampleInfo: '负样本信息',
            notActionTips: '选中项中无可操作误报的内容',
            removeSingleRecordTips: '是否确认移除选中记录？',
            removeSuccess: '移除成功',
            removeFail: '移除失败',
            notLinkSampleTips: '当前记录算法类型已不存在，不支持操作关联负样本',
            notBatchLinkSampleTips: '当前选中下无可操作的记录',
        },
        // 联动计划
        linkagePlan: {
            enterTimeName:"请输入正确的计划名称",
            enableFilling:"启用音频联动请填写算法类型和音频文件",
            notDeployed:'【此算法未布控】',
            timeCientTip:"不填写通行有效期默认为永久生效",
            deviceNotConfigured:"设备未配置任务，请先在【任务配置】菜单下完成配置",
            handchangeAlgorithm:"请选择算法",
            changeChannels:"请选择绑定设备",
            changeTimeplan:"请选择时间计划",
            changePeople:"请选择人员组",
            ChangeType :"请选择算法类型",
            enterDeviceName: '请输入联动计划名称（支持中文，英文大小写，数字，特殊字符(包括-_)以及空格，最大支持32个字符,中文占3个字符）',
            clickSearch:"点击开始搜索",
            deleteAllPlan:"确认删除所有联动计划?",
            deleteSelectPlan:"确认删除所选计划?",
            deleteSelectTask:"确认删除所选联动计划?",
            planName: "计划名称",
            linkageAction: "联动动作",
            newPlan: "新建计划",
            editPlan: '编辑计划',
            rulesName: "联动计划名称",
            changeDevice: "选择设备",
            changeAlgorithm: "选择算法",
            audioLinkage: "音频联动",
            alarmNum: '报警次数',
            triggerInterval: "触发间隔",
            policeNum: "报警开关量输出",
            testSuccess: "测试成功",
            delayTime: '触发延迟',
            triggerTime: '触发时长',
            millisecond: "毫秒",
            continuityInterval: '连续触发间隔',
            bindChannel: "绑定设备",
            validityPassage: "通行有效期",
            onSubmit: "确认",
            cancel: "取消",
            interfaceOne: "接口1",
            interfaceTwo: "接口2",
            foundSucess:'创建成功',
            err22001:'联动计划名称不合法',
            err22002:'联动计划已存在',
            err22003:'联动计划不存在',
            err22004:'联动计划超过上限',
            err22005:'设备已绑定联动计划，不允许删除',
            err22006:'继电器已绑定联动计划, 不允许删除',
            err22007:'人员分组已绑定联动计划，不允许删除',
            err22008:'人员分组已绑定布控任务，不允许删除',
            instruct: '指令',
            interface: '485接口',
            commandConfig: '指令配置',
            submitTips: '当前设备没有可配置的算法，无法配置联动',
            perfectAudioTip: '请完善音频联动下算法类型对应的音频文件',
            selectRelayTip: '启用继电器配置请选择算法类型和绑定设备',
            perfectRelayTip: '请完善继电器配置下算法类型对应的绑定设备',
            perfectRsTip: '请完善RS485下算法类型对应的485接口',
            selectIoTip: '启用报警输出请选择算法类型和绑定设备',
            perfectIoTip: '请完善报警输出算法类型对应的报警IO口',
            configLeastOneTip: '请至少配置一项联动任务'
        },
        schedulePlan: {
            Defaultschedule: '默认时间计划',
            modifyTimePlanName: '修改时间计划名称',
            planName: '计划名称',
            newHolidayPlan: '新建节日计划',
            editPlan: '编辑计划',
            repeatPlan: '重复计划',
            noRepeat: '不重复',
            annually: '按年',
            HolidayPeriod: '节假日时段',
            selectTime: '选择时间',
            add: '添加',
            deleteTimePlan: '删除时间计划',
            deleteConfirmTips: '确认删除当前时间计划？',
            notDelete: '暂不删除',
            loading: '加载中',
            inputPlanName: '请输入计划名称',
            planNameTips: '支持中文，英文大小写，数字，特殊字符（包括-_），最大支持32个字符',
            selectRepeatPlan: '请选择重复计划',
            timeOverlap: '时间段有重叠',
            minSelectTips: '请至少选择一个节假日时段',
            minDrawTips: '请至少绘制一个时间段',
            maxPlanConfigTips: '计划配置上限50个',
            maxHolidayTips: '设置的节假日时间段不能超过16段',
            addAgain: '请勿重复添加',
            none: '暂无',
            startDate: '开始日期',
            endDate: '结束日期',
            timePicker: '时间选择',
            confirm: '确认',
            Mon: '星期一',
            Tues: '星期二',
            Wed: '星期三',
            Thur: '星期四',
            Fri: '星期五',
            Sat: '星期六',
            Sun: '星期日',
            copyTo: '复制到',
            deleteTimePeriod: '删除时间段',
            confirmDelete: '确认删除当前时间段？',
            maxConfigTimeTips: '设置的时间计划时间段不能超过8段',
            selectTimeRangeTips: '请选择正确的时间范围',
            success: '成功',
        },

        // 报警类型关系表
        alarmType: {
            // perimeter_alert
            park: '车辆禁停',
            exit: '离开',
            person_wander: '人员徘徊',
            wander: '人员徘徊',
            overwall: '人员越界-翻墙检测',
            intrusion: '入侵',
            tripwire: '越界',
            electric_bike_in_elevator: '电瓶车入电梯',
            mask_area: '屏蔽区域',
            // headcount_alarm
            head_count: '区域人数统计',
            cross_line: '出入口人数统计',
            // behaviour_alert
            fall: '摔倒检测',
            smoke: '抽烟检测',
            call: '打电话',
            watch_phone: '看手机',
            run: '人员奔跑',
            sleep: '睡岗检测',
            person_off_duty_querying: '人员值岗/离岗-离岗',
            gathering: '人员聚众',
            fight: '人员扭打',
            person_over_querying: '超员',
            person_less_querying: '少员',
            holdweapon: '持械检测',
            // mask_area: '屏蔽区域',

            // goods_alarm
            sundry_detect: '杂物堆放',
            goods_forget: '物品遗留',
            goods_guard: '物品看守',
            // face_basic_business
            face_capture: '',
            body_capture: '',
            face_comparison_successful: '',
            stranger: '',
            non_living_attack: '',
            no_wear_respirator: '',
            suspicious_target: '',
            update_feature: '',
            // access_control
            // non_living_attack: '',
            import_person_try_to_pass: '',
            stranger_try_to_pass: '',
            normal_access_of_staff: '',
            abnormal_access_of_staff: '',
            normal_access_of_visitor: '',
            abnormal_access_of_visitor: '',
            // no_wear_respirator: '',
            fire_control: '',
            door_always_open: '',
            doorbell_rings: '',
            // suspicious_target: '',
            // equipment_operation

            structure:'结构化',
            face: '人脸',
            faces: '人脸',
            pedestrian: '人形',
            pedestrians: '人形',
            vehicle: '机动车',
            vehicles: '机动车',
            non_motor: '非机动车',
            non_motors: '非机动车',
            plate: '车牌',
            plates: '车牌',
            // skeleton_alarm
            situps: '仰卧起坐',
            situps_ready_to_start: '仰卧起坐 预备动作',
            situps_complete_action: '仰卧起坐 完整动作',
            situps_incomplete_action: '仰卧起坐 违规动作',
            pullups: '引起向上',
            pullups_ready_to_start: '引体向上 预备动作',
            pullups_complete_action: '引体向上 完整动作',
            pullups_incomplete_action: '引体向上 违规动作',
            longjump: '立定跳远',
            longjump_ready_to_start: '立定跳远 预备动作',
            longjump_complete_action: '立定跳远 完整动作',
            longjump_incomplete_action: '立定跳远 违规动作',
            // safety_alarm
            no_safetycap: '未佩戴安全帽报警',
            no_uniform: '未穿戴安全工服报警',
            no_safetybelt: '未佩戴安全带报警',
            flame: '火焰报警',
            smog: '烟雾报警',
            oil_spill: '油品泄漏',
            no_vest: '未佩戴反光衣报警',
            lose_fire_equipment: '消防设施检测',
            no_respirator: '口罩',
            // city_alarm
            hawker: '游商小贩',
            outstore: '店外经营',
            roadside: '占道经营',
            sundrystack: '乱堆物料',
            muck: '堆积渣土',
            exposed_garbage: '暴露垃圾',
            outdoor_adv: '户外广告',
            watergather: '道路积水',
            // mclz_alarm
            trashbin: '垃圾桶未盖报警',
            discover_mice: '',
            no_chef_cloth: '未穿厨师服',
            no_chef_hat: '未戴厨师帽子',
            no_face_respirator: '未戴口罩',
            no_rubber_glove: '未戴橡胶手套',
            flame_without_human: '动火离人',
            // sdwe_alarm
            indicator_flag: '静电线',
            oil_pipe: '卸油管报警',
            oilpump_door_open: '油机侧盖打开',
            oil_gun_drag: '油管拉断',
            // flame: '火焰报警',
            // smog: '烟雾报警',
            // lose_fire_equipment: '消防设施检测(包含灭火器和灭火箱)',

            // fire_alarm
            overflowed_garbage: '垃圾满溢',
            // exposed_garbage: '暴露垃圾',
            // flame: '',
            // smog: '',
            // lose_fire_equipment: '',

            // gkpw_alarm
            falling_goods: '高空抛物',
            // bzj_alarm
            hand: '手部检测',
            trash_exist: '垃圾检测',
            climb: '攀爬检测',
            image_cover_alert: '画面异常',
        },
        newStructure: {
            gender_type1: '未知',
            gender_type2: '男性',
            gender_type3: '女性',
            // 胡子类型
            beard_class: '胡子类型',
            beard_class_type1: '未知',
            beard_class_type2: '无胡子',
            beard_class_type3: '八字胡',
            beard_class_type4: '络腮胡',
            beard_class_type5: '一字胡',
            beard_class_type6: '淡胡子',
            beard_class_type7: '山羊胡',
            beard_class_type8: '花白胡',
            beard_class_type9: '一点胡',
            // 眼镜款式
            glasses_style: '眼镜款式',
            glasses_style_type1: '未知',
            glasses_style_type2: '全框',
            glasses_style_type3: '半框',
            glasses_style_type4: '无框',
            glasses_style_type5: '眉线框',
            glasses_style_type6: '多功能框',
            glasses_style_type7: '变色镜',
            glasses_style_type8: '太阳镜',
            glasses_style_type9: '无镜片',
            glasses_style_type10: '透明色',
            glasses_style_type11: '深色框透明眼镜',
            glasses_style_type12: '普通透明眼镜',
            glasses_style_type13: '墨镜',
            // 发型
            hair_style: '发型',
            hair_style_type1: '未知',
            hair_style_type2: '平头',
            hair_style_type3: '中分',
            hair_style_type4: '偏分',
            hair_style_type5: '额秃',
            hair_style_type6: '项秃',
            hair_style_type7: '全秃',
            hair_style_type8: '卷发',
            hair_style_type9: '波浪发',
            hair_style_type10: '麻花辫',
            hair_style_type11: '盘发',
            hair_style_type12: '披肩',
            hair_style_type13: '短发',
            hair_style_type14: '长发',
            // 帽子类型
            hat_style: '帽子类型',
            hat_style_type1: '未知',
            hat_style_type2: '毛线帽',
            hat_style_type3: '贝雷帽',
            hat_style_type4: '棒球帽',
            hat_style_type5: '平顶帽',
            hat_style_type6: '渔夫帽',
            hat_style_type7: '套头帽',
            hat_style_type8: '鸭舌帽',
            hat_style_type9: '大檐帽',
            hat_style_type10: '安全帽',
            hat_style_type11: '厨师帽',
            // 帽子颜色
            hat_color: '帽子颜色',
            hat_color_type1: '未知',
            hat_color_type2: '黑色',
            hat_color_type3: '白色',
            hat_color_type4: '灰色',
            hat_color_type5: '红色',
            hat_color_type6: '橙色',
            hat_color_type7: '黄色',
            hat_color_type8: '绿色',
            hat_color_type9: '深蓝色',
            hat_color_type10: '浅蓝色',
            hat_color_type11: '紫色',
            hat_color_type12: '粉色',
            hat_color_type13: '棕色',
            // 口罩类型
            respirator_class: '口罩类型',
            respirator_class_type1: '未知',
            respirator_class_type2: '医用口罩',
            respirator_class_type3: '雾霾口罩',
            respirator_class_type4: '普通口罩',
            respirator_class_type5: '厨房用透明口罩',
            // 肤色
            skin_color: '肤色',
            skin_color_type1: '未知',
            skin_color_type2: '黑色',
            skin_color_type3: '白色',
            skin_color_type4: '黄色',
            skin_color_type5: '深色',
            // 上衣颜色
            coat_color: '上衣颜色',
            coat_color_type1: '未知',
            coat_color_type2: '黑色',
            coat_color_type3: '白色',
            coat_color_type4: '灰色',
            coat_color_type5: '红色',
            coat_color_type6: '橙色',
            coat_color_type7: '黄色',
            coat_color_type8: '绿色',
            coat_color_type9: '深蓝色',
            coat_color_type10: '浅蓝色',
            coat_color_type11: '紫色',
            coat_color_type12: '粉色',
            coat_color_type13: '棕色',
            coat_color_type14: '彩色',
            // 上衣类型
            coat_length: '上衣类型',
            coat_length_type1: '未知',
            coat_length_type2: '长袖',
            coat_length_type3: '短袖',
            coat_length_type4: '无袖',
            // 下衣长度
            pants_length: '下衣长度',
            pants_length_type1: '未知',
            pants_length_type2: '长',
            pants_length_type3: '短',
            // 下衣颜色
            pants_color: '下衣颜色',
            pants_color_type1: '未知',
            pants_color_type2: '黑色',
            pants_color_type3: '白色',
            pants_color_type4: '灰色',
            pants_color_type5: '红色',
            pants_color_type6: '橙色',
            pants_color_type7: '黄色',
            pants_color_type8: '绿色',
            pants_color_type9: '深蓝色',
            pants_color_type10: '浅蓝色',
            pants_color_type11: '紫色',
            pants_color_type12: '粉色',
            pants_color_type13: '棕色',
            pants_color_type14: '蓝色',
            pants_color_type15: '青色',
            // 下衣类型
            wear_lower_skirt: '下衣类型',
            wear_lower_skirt_type1: '未知',
            wear_lower_skirt_type2: '裤子',
            wear_lower_skirt_type3: '裙子',
            // 汽车品牌
            brand: '汽车品牌',
            brand1: "其他",
			brand2: "大众",
			brand3: "别克",
			brand4: "宝马",
			brand5: "本田",
			brand6: "标致",
			brand7: "丰田",
			brand8: "福特",
			brand9: "日产",
			brand10: "奥迪",
			brand11: "马自达",
			brand12: "雪佛兰",
			brand13: "雪铁龙",
			brand14: "现代",
			brand15: "奇瑞",
			brand16: "起亚",
			brand17: "荣威",
			brand18: "三菱",
			brand19: "斯柯达",
			brand20: "吉利",
			brand21: "中华",
			brand22: "沃尔沃",
			brand23: "雷克萨斯",
			brand24: "菲亚特",
			brand25: "东风",
			brand26: "比亚迪",
			brand27: "铃木",
			brand28: "金杯",
			brand29: "海马",
			brand30: "五菱",
			brand31: "江淮",
			brand32: "斯巴鲁",
			brand33: "长城",
			brand34: "庆铃（五十铃）",
			brand35: "东南",
			brand36: "长安",
			brand37: "福田",
			brand38: "奔驰",
			brand39: "一汽",
			brand40: "依维柯",
			brand41: "力帆",
			brand42: "雷诺",
			brand43: "名爵",
			brand44: "众泰",
			brand45: "金龙",
			brand46: "黄海",
			brand47: "保时捷",
			brand48: "凯迪拉克",
			brand49: "吉普",
			brand50: "路虎",
			brand51: "宝骏",
			brand52: "开瑞",
			brand53: "北汽",
			brand54: "纳智捷",
			brand55: "克莱斯勒",
			brand56: "广汽",
			brand57: "哈飞",
			brand58: "凯马",
			brand59: "昌河",
			brand60: "上海汇众",
			brand61: "海格",
			brand62: "宇通",
			brand63: "中国重汽",
			brand64: "北奔重卡",
			brand65: "华菱星马",
			brand66: "跃进汽车",
			brand67: "英菲尼迪",
			brand68: "长丰猎豹",
			brand69: "陕汽重卡",
			brand70: "安凯",
			brand71: "中通",
			brand72: "陆风",
			brand73: "北京",
			brand74: "威麟",
			brand75: "欧宝",
			brand76: "华普",
			brand77: "讴歌",
			brand78: "中兴",
			brand79: "捷豹",
			brand80: "唐骏欧铃",
			brand81: "福迪",
			brand82: "莲花",
			brand83: "双环",
			brand84: "永源",
			brand85: "道奇",
			brand86: "大运汽车",
			brand87: "北方客车",
			brand88: "宾利",
			brand89: "华泰",
			brand90: "江铃",
			brand91: "特斯拉",
			brand92: "悍马",
			brand93: "林肯",
			brand94: "GMC",
			brand95: "上汽",
			brand96: "双龙",
			brand97: "友谊",
			brand98: "飞碟",
			brand99: "南骏CNJ",
			brand100: "玛莎拉蒂",
			brand101: "大发",
			brand102: "法拉利",
			brand103: "霸龙重卡",
			brand104: "兰博基尼",
			brand105: "三一重工",
			brand106: "金旅",
			brand107: "中顺",
			brand108: "联合卡车",
			brand109: "红岩",
			brand110: "MINI",
			brand111: "恒通",
			brand112: "黑豹",
			brand113: "五征",
			brand114: "解放",
			brand115: "启晨",
			brand116: "亚星",
			brand117: "理念",
			brand118: "smart",
			brand119: "少林",
			brand120: "青年",
			brand121: "英伦",
			brand122: "夏利",
			brand123: "时代汽车",
			brand124: "申龙",
			brand125: "大宇",
			brand126: "启辰",
			brand127: "野马",
			brand128: "瑞麟",
			brand129: "江南",
			brand130: "九龙",
			brand131: "舒驰客车",
			brand132: "红旗",
            // 机动车辆类型
            vehicle_class: '机动车辆类型',
            vehicle_class_type1: '未知',
            vehicle_class_type2: '小型车',
            vehicle_class_type3: 'SUV',
            vehicle_class_type4: '面包车',
            vehicle_class_type5: '中巴 / 大巴',
            vehicle_class_type6: '皮卡',
            vehicle_class_type7: '卡车 / 货车',
            vehicle_class_type8: '渣土车',
            vehicle_class_type9: '混凝土搅拌车',
            vehicle_class_type10: '消防车',
            vehicle_class_type11: '校车',
            vehicle_class_type12: '警车',
            vehicle_class_type13: '救护车',
            vehicle_class_type14: '集装箱车',
            vehicle_class_type15: '挖掘机',
            // 车辆颜色
            vehicle_color: '车辆颜色',
            vehicle_color_type1: '未知',
            vehicle_color_type2: '黑色',
            vehicle_color_type3: '白色',
            vehicle_color_type4: '银灰',
            vehicle_color_type5: '深灰',
            vehicle_color_type6: '棕色',
            vehicle_color_type7: '红色',
            vehicle_color_type8: '橙色',
            vehicle_color_type9: '黄色',
            vehicle_color_type10: '绿色',
            vehicle_color_type11: '青色',
            vehicle_color_type12: '蓝色',
            vehicle_color_type13: '粉色',
            vehicle_color_type14: '紫色',
            vehicle_color_type15: '香槟',
            vehicle_color_type16: '花色',
            //机动车辆朝向类型
            vehicle_orientation: '机动车辆朝向',
            vehicle_orientation_type1: '未知',
            vehicle_orientation_type2: '前向',
            vehicle_orientation_type3: '背向',
            vehicle_orientation_type4: '侧向',
            //非机动车辆类型
            non_motor_class: '非机动车辆类型',
            non_motor_class_type0: '未知',
            non_motor_class_type1: '自行车',
            non_motor_class_type2: '电动车',
            non_motor_class_type3: '摩托车',
            non_motor_class_type4: '三轮车',
            //车牌种类
            plate_class: '车牌种类',
            plate_class_type1: "未知",
			plate_class_type2: "大型汽车号牌",
			plate_class_type3: "小型汽车号牌",
			plate_class_type4: "使馆汽车号牌",
			plate_class_type5: "领馆汽车号牌",
			plate_class_type6: "境外汽车号牌",
			plate_class_type7: "外籍骑车号牌",
			plate_class_type8: "普通摩托车号牌",
			plate_class_type9: "轻便摩托车号牌",
			plate_class_type10: "使馆摩托车号牌",
			plate_class_type11: "领馆摩托车号牌",
			plate_class_type12: "境外摩托车号牌",
			plate_class_type13: "外籍摩托车号牌",
			plate_class_type14: "低速车号牌",
			plate_class_type15: "拖拉机号牌",
			plate_class_type16: "挂车号牌",
			plate_class_type17: "教练汽车号牌",
			plate_class_type18: "教练摩托车号牌",
			plate_class_type19: "临时入境骑车号牌",
			plate_class_type20: "临时入境摩托车号牌",
			plate_class_type21: "临时行驶车号牌",
			plate_class_type22: "警用汽车号牌",
			plate_class_type23: "警用摩托车号牌",
			plate_class_type24: "原农机号牌",
			plate_class_type25: "香港入出境号牌",
			plate_class_type26: "澳门入出境号牌",
			plate_class_type27: "WJ号牌",
			plate_class_type28: "JD号牌",
            // 车牌类型
            plate_row: '车牌类型',
            plate_row_type1: '未知',
            plate_row_type2: '单行车牌',
            plate_row_type3: '双行车牌',
            // 车牌颜色
            plate_color: '车牌颜色',
            plate_color_type1: "未知",
			plate_color_type2: "黑色",
			plate_color_type3: "白色",
			plate_color_type4: "蓝色",
			plate_color_type5: "黄色",
			plate_color_type6: "绿色",
			plate_color_type7: "小型新能源",
			plate_color_type8: "大型新能源",
            // 车牌号
            plate_no: '车牌号',
            // 背包状态
            carry_bag: '背包状态',
            carry_bag_type1: '未知',
            carry_bag_type2: '无背包',
            carry_bag_type3: '背包',
            // 三值逻辑
            ternary_type1: '未知',
            ternary_type2: '无',
            ternary_type3: '有',
            // 三值逻辑-戴/不戴
            wear_ternary_type1: '未知',
            wear_ternary_type2: '不戴',
            wear_ternary_type3: '戴',
        },
        ALARMTYPE: {
            // 警戒 - 周界报警
            PARK: '车辆禁停',
            EXIT: '离开',
            WANDER: '人员徘徊',
            OVERWALL: '翻墙检测',
            INTRUSION: '入侵',
            TRIPWIRE: '越界',
            CLIMB: '攀爬检测',
            ELECTRIC_BIKE_IN_ELEVATOR: '电瓶车入电梯',
            mask_area: '屏蔽区域',

            // 人数统计
            MAX_NUMBER_PEOPLE: '区域人数统计',  
            CROSS_LINE: '出入口人数统计',

            // 行为
            FALL: '摔倒检测',
            SMOKING: '抽烟检测',
            CALL: '打电话',
            WATCH_PHONE: '看手机',
            RUN: '人员奔跑',
            SLEEP: '睡岗检测',
            LEAVE_POST: '离岗',
            GATHERING: '人员聚众',
            FIGHT: '人员扭打',
            PERSON_OVER_QUERYING: '超员',
            PERSON_LESS_QUERYING: '少员',
            HOLDWEAPON: '持械检测',

            // 物品
            SUNDRY_DETECT: '杂物堆放',
            GOODS_FORGET: '物品遗留',
            GOODS_GUARD: '物品看守',
            
            structure: '结构化',
            face: '脸人',
            face_basic_business: '脸人',

            // 安监
            SAFETY_CAP: '未佩戴安全帽报警',
            SAFETY_UNIFORM: '未穿戴安全工服报警',
            SAFETY_BELT: '未佩戴安全带报警',
            FIRE: '火焰报警',
            SMOKE: '烟雾报警',
            OIL_SPILL: '油品泄漏',
            REFLECTIVE_VEST: '未佩戴反光衣报警',
            FIRE_EQUIPMENT: '消防设施检测',
            RESPIRATOR: '普通口罩检测',
           
            // 城管
            HAWKER: '游商小贩',
            OUTSTORE: '店外经营',
            ROADSIDE: '占道经营',
            SUNDRYSTACK: '乱堆物料',
            MUCK: '堆积渣土',
            EXPOSED_GARBAGE: '暴露垃圾',
            OUTDOOR_ADV: '户外广告',
            WATERGATHER: '道路积水',
            OVERFLOWED_GARBAGE: '垃圾满溢',
            MUCK_TRUCK: '渣土车识别',
            OPENED_MUCKTRUCK: '渣土车苫布未盖',

            // 明厨亮灶
            TRASHBIN: '垃圾桶未盖报警',
            MICE: '老鼠',
            CHEF_CLOTH: '未穿厨师服',
            CHEF_HAT: '未戴厨师帽子',
            CHEF_RESPIRATOR: '餐饮口罩检测',
            RUBBER_GLOVE: '未戴橡胶手套',
            FLAME_WITHOUT_HUMAN: '动火离人',

            // 加油站
            INDICATOR_FLAG: '静电线报警',
            OIL_PIPE: '卸油管报警',
            OILPUMP_DOOR_OPEN: '油机侧盖打开',
            OIL_GUN_DRAG: '油管拉断',
            
            // 高空抛物
            FALLING_GOODS: '高空抛物',
            // 视频诊断
            IMAGE_COVER_ALERT: '画面异常',

            situps: '仰卧起坐',
            situps_ready_to_start: '仰卧起坐 预备动作',
            situps_complete_action: '仰卧起坐 完整动作',
            situps_incomplete_action: '仰卧起坐 违规动作',
            pullups: '引起向上',
            pullups_ready_to_start: '引体向上 预备动作',
            pullups_complete_action: '引体向上 完整动作',
            pullups_incomplete_action: '引体向上 违规动作',
            longjump: '立定跳远',
            longjump_ready_to_start: '立定跳远 预备动作',
            longjump_complete_action: '立定跳远 完整动作',
            longjump_incomplete_action: '立定跳远 违规动作',

            'face-face_capture': '脸人-人脸抓拍',
            'face-body_capture': '脸人-人体抓拍',
            'face-face_comparison_successful': '脸人-人脸识别',
            'face-stranger': '脸人-陌生人',

            'structure-face': '结构化-人脸抓拍',
            'structure-pedestrian': '结构化-人体抓拍',
            'structure-vehicle': '结构化-机动车抓拍',
            'structure-non_motor': '结构化-非机动车抓拍',
            'structure-plate': '结构化-车牌抓拍',
        },
        targetType: {
            faces: '人脸',
            pedestrians: '人体',
            vehicles: '机动车',
            "non-motors": '非机动车',
            plate: '车牌',
            plates: '车牌', // 当前识别记录车牌返回plates
            frequency:"识别次数",
            label:"标签",
            label2:"标签2",
            lowNoHigh:'低阈值不能大于高阈值',
            highNoSmall:"高阈值不能小于低阈值",
        }
    }
};

export default cn;
