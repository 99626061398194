// 算法卡片相关配置store
export default {
    namespaced: true,
    state: {
        algoConfigMap: {
            outputConfig: {
                ALARM_TYPE: {},// 告警事件类型
                TARGET_TYPE: {},// 告警目标类型
                OBJECT_TYPE: {},// 目标类型
            },
            inputConfig: {// 算法卡片配置列表
            }
        },
        algTypeJsonSchema: { // 算法仓管理模块-查看描述文件使用
            oldAlgSrcVersion: [], // 储存全部旧版本alg_src_version，算法管理模块使用
            algTypeBySchema: {} // 储存新版本算法类型对应的schema
        }
    },
    actions: {
        async updateOutpuConfig({ commit }, value) {
            console.log('update output config', value);
            commit('update_output_config', value);
        },
        async updateOldAlgSrcVersion({ commit }, value) {
            commit('update_old_alg_src_version', value);
        }
    },
    mutations: {
        update_output_config(state, value) {
            state.algoConfigMap = value;
        },
        update_old_alg_src_version(state, value) {
            state.algTypeJsonSchema = value;
        }
    }
};