import layout from '../components/layout'

/**
 * 在主框架内显示
 */
const frameIn = [
  {
    path: '/',
    redirect: { name: 'preview' },
    component: layout,
    meta: {
      title: 'preview',
      icon: 'menu-preview'
    },
    children: [
      {
        path: 'preview',
        name: 'preview',
        meta: {
          title: 'preview',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "preview" */ "@/views/preview")
      },
    ]
  },
  {
    path: '/intelRecord',
    redirect: { name: 'intelRecord' },
    component: layout,
    meta: {
      title: 'intelRecord',
      icon: 'menu-record'
    },
    children: [
      {
        path: '/',
        name: 'intelRecord',
        meta: {
          title: 'intelRecord',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "intelRecord" */ "@/views/intelRecord")
      },
    ]
  },
  {
    path: '/intelConfig',
    redirect: { name: 'taskConfig' },
    component: layout,
    meta: {
      title: 'intelConfig',
      icon: 'menu-config'
    },
    children: [
      {
        path: 'taskConfig',
        name: 'taskConfig',
        meta: {
          title: 'taskConfig',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "taskConfig" */ "@/views/intelConfig/taskList")
      },
      {
        path: 'timePlan',
        name: 'timePlan',
        meta: {
          title: 'timePlan',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "timePlan" */ "@/views/intelConfig/timePlan")
      },
      {
        path: 'linkagePlan',
        name: 'linkagePlan',
        meta: {
          title: 'linkagePlan',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "linkagePlan" */ "@/views/intelConfig/linkagePlan"),
        // children: [
        //   {
        //     path: '',
        //     name: 'newPlan',
        //     meta: {
        //       title: 'linkagePlan',
        //       //   auth: true
        //     },
        //     component: () => import("@/views/intelConfig/linkagePlan/newPlan")
        //   },
        // ]
      },
    ]
  },
   // 离线解析
   {
    path: '/offlineParsing',
    redirect: { name: 'offlineParsing' },
    component: layout,
    meta: {
      title: 'offlineParsing',
      icon: 'menu-offline'
    },
    children: [
      {
        path: '/',
        name: 'offlineParsing',
        meta: {
          title: 'offlineParsing',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "offlineParsing" */ "@/views/offlineParsing")
      },
    ]
  },
  // 资源管理
  {
    path: '/resourceManage',
    redirect: { name: 'personManage' },
    component: layout,
    meta: {
      title: 'resourceManage',
      icon: 'menu-resource'
    },
    children: [
      {
        path: 'personManage',
        name: 'personManage',
        meta: {
          title: 'personManage',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "personManage" */ "@/views/resourceManage/personManage")
      },
      {
        path: 'deviceManage',
        name: 'deviceManage',
        meta: {
          title: 'deviceManage',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "deviceManage" */ "@/views/resourceManage/deviceManage"),
        // children: [
        //   {
        //     path: 'findDevice',
        //     name: 'findDevice',
        //     meta: {
        //       title: 'deviceManage',
        //         // auth: true
        //     },
        //     component: () => import("@/views/resourceManage/deviceManage/findDevice")
        //   },
        // ]
      },
      {
        path: 'algorithmManage',
        name: 'algorithmManage',
        meta: {
          title: 'algorithmManage',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "algorithmManage" */ "@/views/resourceManage/algorithmManage")
      },
      {
        path: 'authorizationManagement',
        name: 'authorizationManagement',
        meta: {
          title: 'authorizationManagement',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "authorizationManagement" */ "@/views/resourceManage/authorizationManagement")
      },
      {
        path: 'containerManage',
        name: 'containerManage',
        meta: {
          title: 'containerManage',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "containerManage" */ "@/views/resourceManage/containerManage")
      },
      {
        path: 'fileManagement',
        name: 'fileManagement',
        meta: {
          title: 'docManage',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "fileManagement" */ "@/views/resourceManage/fileManagement")
      },
      {
        path: 'falseAlarmLibrary',
        name: 'falseAlarmLibrary',
        meta: {
          title: 'falseAlarmLibrary',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "falseAlarmLibrary" */ "@/views/resourceManage/falseAlarmLibrary")
      },
    ]
  },
  // 系统管理
  {
    path: '/systemManagement',
    redirect: { name: 'logManagement' },
    component: layout,
    meta: {
      title: 'systemManage',
      icon: 'menu-system'
    },
    children: [
      {
        path: 'logManagement',
        name: 'logManagement',
        meta: {
          title: 'logManage',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "logManagement" */ "@/views/systemManagement/logManagement")
      },
      {
        path: 'systemConfig',
        name: 'systemConfig',
        meta: {
          title: 'systemConfig',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "systemConfig" */ "@/views/systemManagement/systemConfig")
      },
      {
        path: 'dataDocking',
        name: 'dataDocking',
        meta: {
          title: 'dataRecv',
          //   auth: true
        },
        component: () => import(/* webpackChunkName: "dataDocking" */ "@/views/systemManagement/dataDocking")
      },
      // {
      //   path: 'devOpsManage',
      //   name: 'devOpsManage',
      //   meta: {
      //     title: 'devOpsManage',
      //     //   auth: true
      //   },
      //   component: () => import(/* webpackChunkName: "devOpsManage" */ "@/views/systemManagement/devOpsManage")
      // },
    ]
  },
 

]

/**
 * 在主框架之外显示
 */
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ "@/views/login")
  },
  // 设备激活
  {
    path: '/activation',
    name: 'activation',
    component: () => import(/* webpackChunkName: "activation" */ "@/views/deviceActivation")
  },
  // 设备激活
  {
    path: '/deviceActivation',
    name: 'deviceActivation',
    component: () => import(/* webpackChunkName: "deviceActivation" */ "@/views/deviceActivation")
  }
]

/**
 * 错误页面
 */
// const errorPage = [
//   {
//     path: '*',
//     name: '404',
//     component:() => import("@/views/error")
//   }
// ]

// 导出需要显示菜单的
export const frameInRoutes = frameIn

// 重新组织后导出
export default [
  ...frameIn,
  ...frameOut,
  //   ...errorPage
]
