// 请求算法相关数据的工具方法
import {
    getAlgInfo,
    getAlgoConfig,
} from "@/service/api/megConnectApi";

// 还需要判断当前系统中用的新的算法仓方式
// 还是旧的方式
// 新旧方式决定了请求获取算法卡片的方式

// 数据格式转换
// 由新的格式转换适配成旧的的算法配置格式
const extendParamsTrans = (card, params, cardIcon, eventType, areaInfo) => {
    const { card_name, card_type } = card;
    /**
     * 当 card_type = "OVERWALL" | "CLIMB"
     * 当前如果是以上两种卡片的时候
     * 需要加上 检测预警 的开关（属于业务属性）
     */
    let defaultBasicProps = {};

    if (card_type === "OVERWALL" || card_type === "CLIMB") {
        defaultBasicProps = {
            "preAlarm": {
                "title": "预警开关",
                "title:en": "Detection and warning",
                "type": "boolean",
                "default": false,
                "customPropertyName": "detectionWarn"
            }
        };
    }

    const result = {
        "label": card_name,
        "label:en": eventType['title:en'],
        "icon": cardIcon,
        "masksEnable": areaInfo.masksEnable,
        "areaType": areaInfo.areaType,
        "schema": {
            "title": "算法配置",
            "title:en": "Algorithm Configuration",
            "type": "object",
            "properties": {
                "basic": {
                    "title": "基础配置",
                    "title:en": "Basic Setting",
                    "type": "object",
                    "properties": defaultBasicProps
                },
                "advanced": {
                    "title": "高级配置",
                    "title:en": "Advanced Setting",
                    "type": "object",
                    "properties": {}
                }
            }
        }
    };

    // 需要区分中英文
    const objectType = {};
    const targetType = {};

    const properties = params.properties;

    for (let key in properties) {
        const property = properties[key];

        // 需要解析是否存在custom-自定义高级参数
        if (key === 'custom') {
            const cusProperties = property.properties;
            for (let cKey in cusProperties) {
                const customPropertyName = cKey;
                const ps = cusProperties[cKey];
                for (let pKey in ps.properties) {
                    const innerProperty = ps.properties[pKey]
                    // 还需要根据 innerProperty 的 type进行不同的渲染

                    result.schema.properties.basic.properties[pKey] = Object.assign({}, innerProperty, {
                        isCustom: true,
                        customPropertyName
                    });

                    if (innerProperty.type === 'array') {
                        if (innerProperty.items['ui:options']) {
                            result.schema.properties.basic.properties[pKey]['ui:options'] = innerProperty.items['ui:options'];
                        }
                    }

                    // 使用滑块 slider 组件进行渲染
                    if (innerProperty['ui:options']?.widget === "CountSlider") {
                        // 如果不存在 max 和 min 就用 0-1，step= 0.01,暂时这么使用
                        // 理论上，使用新格式的情况下，如果要渲染 CountSlider 就不会存在 缺少 min 和 max的情况
                        // TODO：后续可以删除此判断逻辑
                        if (innerProperty.minimum === undefined || innerProperty.maximum === undefined) {
                            result.schema.properties.basic.properties[pKey].default = 0
                            result.schema.properties.basic.properties[pKey].maximum = 1
                            result.schema.properties.basic.properties[pKey].minimum = 0
                            innerProperty['ui:options'].step = 0.01;
                            innerProperty['ui:options'].precision = 2;
                        } else {
                            innerProperty.default && (result.schema.properties.basic.properties[pKey].default = innerProperty.default)
                            innerProperty.maximum && (result.schema.properties.basic.properties[pKey].maximum = innerProperty.maximum)
                            innerProperty.minimum && (result.schema.properties.basic.properties[pKey].minimum = innerProperty.minimum)
                            innerProperty['ui:options'].step = (innerProperty.maximum - innerProperty.minimum) / 100;
                            innerProperty['ui:options'].precision = 2;// 按照保留两位小数的精度
                        }
                    }
                }
            }

        } else if (key === 'targetMax' || key === 'targetMin') {
            if (properties['targetMax']['ui:options'].show === 'hidden' || properties['targetMin']['ui:options'].show === 'hidden') {
                // 隐藏
            } else {
                // 目标大小配置需要特殊整合，配合B4H
                const targetMax = properties['targetMax'];
                const targetMin = properties['targetMin'];

                result.schema.properties.advanced.properties['targetSize'] = {
                    "title": "目标大小",
                    "title:en": "Target size",
                    "type": "number",
                    "default": [targetMin.default, targetMax.default],
                    "minimum": 0,
                    "maximum": 100,
                    "ui:options": {
                        "widget": "TargetSize"
                    }
                };
            }
        } else if (key === 'targetTypes') {
            // 目标类型，这里需要构造 object type 配置
            const { items } = property;
            const { enumNames = [], enumNums = [], enumCustomNums = [], enumCustomNames = [] } = items;
            // const enumNamesEn = items['enumNames:en'] || [];
            const enumList = items.enum || [];
            const enumNamesEn = items['enumNames:en'] || [];

            const enumCustomList = items.enumCustom || [];
            const enumCustomNamesEn = items['enumCustomNames:en'] || [];
            // 中英文需要区分


            enumNums.map((objNum, index) => {
                // 中文
                if (objectType.cn) {
                    objectType.cn.OBJECT_TYPE[`objectClass_${objNum}`] = enumNames[index];
                    targetType.cn.TARGET_TYPE[`targetType_${enumList[index]}`] = enumNames[index];
                } else {
                    objectType.cn = { OBJECT_TYPE: {} };
                    targetType.cn = { TARGET_TYPE: {} };
                    objectType.cn.OBJECT_TYPE[`objectClass_${objNum}`] = enumNames[index];
                    targetType.cn.TARGET_TYPE[`targetType_${enumList[index]}`] = enumNames[index];
                }

                // 英文
                if (objectType.en) {
                    objectType.en.OBJECT_TYPE[`objectClass_${objNum}`] = enumNamesEn.length > 0 ? enumNamesEn[index] : '';
                    targetType.en.TARGET_TYPE[`targetType_${enumList[index]}`] = enumNamesEn.length > 0 ? enumNamesEn[index] : '';
                } else {
                    objectType.en = { OBJECT_TYPE: {} };
                    targetType.en = { TARGET_TYPE: {} };
                    objectType.en.OBJECT_TYPE[`objectClass_${objNum}`] = enumNamesEn.length > 0 ? enumNamesEn[index] : '';
                    targetType.en.TARGET_TYPE[`targetType_${enumList[index]}`] = enumNamesEn.length > 0 ? enumNamesEn[index] : '';
                }

                // objectType[`objectClass_${objNum}`] = enumNames[index];
                // targetType[`targetType_${enumList[index]}`] = enumNames[index];
            });

            // fix: 修复一种情况：目标类型里还有扩展的类型，存在于 enumCustomNums 字段里，只会用于告警上报的时候找到对应的类型翻译进行展示
            enumCustomNums.map((objNum, index) => {
                // 中文
                if (objectType.cn) {
                    objectType.cn.OBJECT_TYPE[`objectClass_${objNum}`] = enumCustomNames[index];
                    targetType.cn.TARGET_TYPE[`targetType_${enumCustomList[index]}`] = enumCustomNames[index];
                } else {
                    objectType.cn = { OBJECT_TYPE: {} };
                    targetType.cn = { TARGET_TYPE: {} };
                    objectType.cn.OBJECT_TYPE[`objectClass_${objNum}`] = enumCustomNames[index];
                    targetType.cn.TARGET_TYPE[`targetType_${enumCustomList[index]}`] = enumCustomNames[index];
                }

                // 英文
                if (objectType.en) {
                    objectType.en.OBJECT_TYPE[`objectClass_${objNum}`] = enumCustomNamesEn.length > 0 ? enumCustomNamesEn[index] : '';
                    targetType.en.TARGET_TYPE[`targetType_${enumCustomList[index]}`] = enumCustomNamesEn.length > 0 ? enumCustomNamesEn[index] : '';
                } else {
                    objectType.en = { OBJECT_TYPE: {} };
                    targetType.en = { TARGET_TYPE: {} };
                    objectType.en.OBJECT_TYPE[`objectClass_${objNum}`] = enumCustomNamesEn.length > 0 ? enumCustomNamesEn[index] : '';
                    targetType.en.TARGET_TYPE[`targetType_${enumCustomList[index]}`] = enumCustomNamesEn.length > 0 ? enumCustomNamesEn[index] : '';
                }
            });

            if (params.required.includes('targetTypes') && items.enum.length > 1) {
                result.schema.properties.basic.properties['targetTypes'] = {
                    "title": "目标类型",
                    "title:en": "Target Types",
                    "type": property.type,
                    "items": {
                        "type": items.type,
                        "enum": items.enum,
                        "enumNames": items.enumNames
                    },
                    "default": property.default,
                    "ui:options": {
                        "widget": "RadioBox"
                    }
                }
            } else if (params.required.includes('targetTypes') && items.enum.length === 1) {
                result.schema.properties.basic.properties['targetTypes'] = {
                    "title": "目标类型",
                    "title:en": "Target Types",
                    "type": property.type,
                    "items": {
                        "type": items.type,
                        "enum": items.enum,
                        "enumNames": items.enumNames
                    },
                    "default": property.default,
                    "ui:options": {
                        "show": "hidden",
                    }
                }
            }

        } else {
            // 其他非自定义属性
            if (property['ui:options']) {
                // 屏蔽掉不需要显示的item
                const uiOptions = property['ui:options'];
                if (uiOptions && uiOptions.show && uiOptions.show === 'hidden') {
                    // 隐藏，不需要展示
                } else {
                    // 先人为划分：目标大小 & 阈值 作为高级参数配置
                    // TODO：需要优化
                    if (key === 'threshold') {
                        // result.schema.properties.advanced.properties[key] = property;
                    } else if (key === 'duration') {
                        // TODO：由于当前版本inputSchema返回ui:options中缺失tips，现手动输入，后期调整
                        property['ui:options'].tips = "范围：0分0秒-29分59秒"
                        property['ui:options']['tips:en'] = "Range: 0 minutes 0 seconds - 29 minutes 59 seconds"
                        result.schema.properties.basic.properties[key] = property;
                    } else if (key === 'cooldownDuration') {
                        property['ui:options'].tips = "范围：0分0秒-23时59分59秒"
                        property['ui:options']['tips:en'] = "Range: 0 minutes 0 seconds - 23 hours 59 minutes 59 seconds"
                        result.schema.properties.basic.properties[key] = property;
                    } else {
                        // 基础配置
                        result.schema.properties.basic.properties[key] = property;
                    }
                }

            } else {
                // TODO：可优化，这里跟上面的判断逻辑有重复
                if (key === 'threshold') {
                    // 阈值参数解析
                    const thresholdProperties = property.properties;

                    // 判断是否为多阈值
                    const isMultiple = Object.keys(thresholdProperties).length > 1;
                    for (let thKey in thresholdProperties) {

                        if (isMultiple) {
                            // 多阈值
                            const thProperty = thresholdProperties[thKey];
                            result.schema.properties.advanced.properties[`threshold_${thKey}`] = {
                                title: thProperty.title,
                                'title:en': thProperty['title:en'] || '',
                                type: thProperty.type,
                                default: thProperty.default,
                                minimum: thProperty.minimum,
                                maximum: thProperty.maximum,
                                'ui:options': {
                                    step: (thProperty.maximum - thProperty.minimum) / 1000,
                                    precision: 3 // 阈值保留三位小数作为精度
                                }
                            };
                        } else {
                            // 单阈值
                            const thProperty = thresholdProperties[thKey];
                            result.schema.properties.advanced.properties[key] = {
                                title: thProperty.title,
                                'title:en': thProperty['title:en'] || '',
                                type: thProperty.type,
                                default: thProperty.default,
                                minimum: thProperty.minimum,
                                maximum: thProperty.maximum,
                                'ui:options': {
                                    step: (thProperty.maximum - thProperty.minimum) / 1000,
                                    precision: 3 // 阈值保留三位小数作为精度
                                }
                            };
                        }

                    }

                } else {
                    // 基础配置
                    result.schema.properties.basic.properties[key] = property;
                }
            }
        }
    }

    return { cardConfig: result, objectType, targetType };
};

// 获取算法仓列表
export const getAlgoList = async () => {
    return new Promise((resolve, reject) => {
        getAlgInfo({ offset: 0, size: 100 }).then((res) => {
            if (res.code === 0) {
                resolve(res.data.list);
            } else {
                reject(res);
            }
        }).catch(err => {
            console.log('ERROR:', err);
            reject(err);
        });
    });
};

// 解析整体卡片信息

// 根据算法卡片列表 获取对应信息卡片的配置信息
// 适配：V3.0、V3.1版本
// requestType = config.json / alarm.json
export const getAlgoWarehouceConfig = (algoList, requestType = 'alarm') => {
    const getConfigPromiseList = [];
    algoList.map((algo) => {
        const { alg_warehouse_type, alg_version } = algo;
        // 过滤 脸人 & 结构化
        if (
            alg_warehouse_type !== "face" &&
            alg_warehouse_type !== "structure"
        ) {
            // 拼接版本
            const algTypeKey = alg_warehouse_type.split("_alarm")[0];
            const curVersion = `mc40_${algTypeKey}_algo_package_${alg_version}`;
            const configReqPath = `/web/webplugin/${curVersion}/${requestType}.json`;
            getConfigPromiseList.push(getAlgoConfig({ path: configReqPath }));
        }
    });
    return Promise.all(getConfigPromiseList);
}

// requestType 取 config 或者 alarm
export const getAlgoWarehouceConfigByUrlList = (urlList, requestType = 'config') => {
    const getConfigPromiseList = [];
    urlList.map((url) => {
        getConfigPromiseList.push(getAlgoConfig({ path: `${url}/${requestType}.json` }));
    });
    return Promise.all(getConfigPromiseList);
}

export const getAlarmConfig = async () => {
    const params = { offset: 0, size: 50 };
    await getAlgInfo(params).then(async (res) => {
        if (res.code === 0) {
            // 根据卡片信息获取对应的 alarm config
            const algoList = res.data.list;
            const alarmConfigList = await this.getAlgoWarehouceConfig(algoList);
            // 处理合并alarm config
            const alarmConfig = {};
            alarmConfigList.map(result => {
                if (result && result.status === 200 && typeof result.data !== 'string') {
                    // 返回的数据不是string 表示返回的数据是正确的
                    const data = result.data;

                    if (alarmConfig.en) {
                        // 需要遍历 alarmConfig.en 下面的所有值，进行合并
                        // 不能只合并 alarmConfig.en 这一层
                        for (let key in alarmConfig.en) {
                            alarmConfig.en[key] = Object.assign(alarmConfig.en[key], data.en[key]);
                        }
                    } else {
                        alarmConfig.en = data.en;
                    }

                    if (alarmConfig.cn) {
                        for (let key in alarmConfig.cn) {
                            alarmConfig.cn[key] = Object.assign(alarmConfig.cn[key], data.cn[key]);
                        }
                    } else {
                        alarmConfig.cn = data.cn;
                    }
                }
            });

            if (Object.keys(alarmConfig).length >= 2) {
                this.useLocalAlarmConfig = false;
                this.remoteAlarmConfig = alarmConfig;
            }

        }
    }).catch((err) => {
        console.log("请求失败 err:", err);
        // this.taskLoading = false;
    });
}


// 获取系统内所有算法卡片相关数据
export const initAlgoConfigs = async () => {
    const [getAlgoError, algoList] = await getAlgoList().then(res => {
        return [null, res];
    }).catch(err => {
        console.log('请求报错', err);
        return [err, null];
    });

    if (getAlgoError) {
        return null;
    }

    // 获取对应算法仓的 extend.json
    // 这里需要兼容新仓和旧仓

    const [getExtendListError, algoExtendList] = await getAlgoWarehouceConfig(algoList, 'extend').then(res => [null, res]).catch(err => [err, null]);

    if (getExtendListError) {
        return;
    }

    // 解析 extend.json，获取每个卡片的 output schema
    const alarmList = [];

    const alarmConfig = {
        cn: {
            ALARM_TYPE: {},
            TARGET_TYPE: {
                targetType_FIRE: '火焰'// fix: 特殊处理，起源是高低版本的 火焰目标类型 用的是 FIRE 和 FLAME，为了兼容，把 FIRE 作为默认具有的配置
            },
            OBJECT_TYPE: {}
        },
        en: {
            ALARM_TYPE: {},
            TARGET_TYPE: {
                targetType_FIRE: 'Fire'// fix: 特殊处理，起源是高低版本的 火焰目标类型 用的是 FIRE 和 FLAME，为了兼容，把 FIRE 作为默认具有的配置
            },
            OBJECT_TYPE: {}
        },
    };

    let cardConfig = {};

    const oldAlgSrcVersion = [] // 储存全部旧版本alg_src_version，算法管理模块使用
    let algTypeBySchema = { // 储存新版本算法类型对应的schema
        inputSchema: {},
        outputSchema: {},
    }

    for (let i = 0; i < algoExtendList.length; i++) {
        const data = algoExtendList[i].data;
        const reqUrlPrefix = algoExtendList[i].config.url.replace('extend.json', '');

        const oldVersionReqUrlList = [];

        if (typeof data === 'object') {
            // 有对应的卡片数据
            // 进行数据解析
            // const algoType = data.algo_type;
            // const algoName = data.algo_name;// 中文名
            const pockets = data.pockets;
            // const cards = [];

            for (let j = 0; j < pockets.length; j++) {
                // 需要通过card的路径获取input数据
                // 再通过 input 数据进行schema的解析和构造
                const cardList = pockets[j].cards;
                for (let k = 0; k < cardList.length; k++) {
                    const card = cardList[k];
                    const inputPath = card.abilities.video.input_path;
                    const cardName = card.card_name;
                    const cardType = card.card_type;

                    alarmConfig.cn.ALARM_TYPE[cardType] = cardName;
                    alarmConfig.en.ALARM_TYPE[cardType] = cardType;

                    // 通过inputPath请求对应每个卡片的schema
                    const cardReqPath = `${reqUrlPrefix}${inputPath.replace('spec/assets/business/', '')}`;
                    // output地址
                    const out_cardReqPath = `${cardReqPath.replace('_in', '_out')}`

                    await getAlgoConfig({ path: cardReqPath }).then(res => {
                        if (typeof res.data === 'object') {
                            // 储存新版本算法类型对应的schema
                            algTypeBySchema.inputSchema[`${cardType}`] = res.data

                            const cardSchema = res.data.schema;
                            // 解析schema
                            const rulesParams = cardSchema.properties.taskInfo.properties.rulesParams;
                            // 算法参数配置
                            const algoParams = rulesParams.items.properties.extendParams;

                            const eventType = rulesParams.items.properties.eventType;

                            // card icon
                            const cardIcon = cardSchema.properties.taskInfo.icon || '';

                            // 画框类型 & 屏蔽区域支持情况
                            const areas = rulesParams.items.properties.areas;
                            const masksEnable = rulesParams.items.properties.masks ? true : false;
                            const areaType = areas.items.properties.areaType.enum;
                            const areaInfo = { masksEnable, areaType };

                            const transData = extendParamsTrans(card, algoParams, cardIcon, eventType, areaInfo);

                            cardConfig[cardType] = transData.cardConfig;

                            alarmConfig.cn.OBJECT_TYPE = Object.assign(alarmConfig.cn.OBJECT_TYPE, transData.objectType.cn ? transData.objectType.cn.OBJECT_TYPE : {});
                            alarmConfig.cn.TARGET_TYPE = Object.assign(alarmConfig.cn.TARGET_TYPE, transData.targetType.cn ? transData.targetType.cn.TARGET_TYPE : {});

                            alarmConfig.en.OBJECT_TYPE = Object.assign(alarmConfig.en.OBJECT_TYPE, transData.objectType.en ? transData.objectType.en.OBJECT_TYPE : {});
                            alarmConfig.en.TARGET_TYPE = Object.assign(alarmConfig.en.TARGET_TYPE, transData.targetType.en ? transData.targetType.en.TARGET_TYPE : {});
                        }

                    }).catch(err => {
                        console.log('请求报错：', err);
                    });

                    await getAlgoConfig({ path: out_cardReqPath }).then(res => {
                        if (typeof res.data === 'object') {
                            // 储存新版本算法类型对应的schema
                            algTypeBySchema.outputSchema[`${cardType}`] = res.data
                        }
                    }).catch(err => {
                        console.log('请求报错：', err);
                    });
                }
            }

            alarmList.push(data.pockets);
        } else {
            // 通过extend.json的方式没有获取配置文件
            // 就需要走旧的方式
            oldVersionReqUrlList.push(reqUrlPrefix);
            // 直接储存alg_src_version，算法管理模块使用
            oldAlgSrcVersion.push(reqUrlPrefix.split('/')[3])
        }

        // 对于 oldVersionReqUrlList 需要在进行一次请求
        // 算法仓 转换成 算法卡片list
        const oldConfigResult = await getAlgoWarehouceConfigByUrlList(oldVersionReqUrlList, 'config')
            .then((res) => {
                return [null, res];
            })
            .catch((err) => {
                return [err, null];
            });

        // console.log("oldConfigResult >>>>>", oldConfigResult);

        // 配置并定义 ALGORITHM_POCKETS
        // let algorithmPockets = {};
        if (oldConfigResult[0]) {
            // 整个promise 全部失败
        } else {
            // 可能存在部分失败
            const resultList = oldConfigResult[1];
            resultList.map((result) => {
                if (result.status === 200 && typeof result.data !== "string") {
                    // console.log('旧格式的卡片信息：', result.data);
                    // fix: 这里需要解决 旧格仓和新仓存在一样的算法卡片，需要使用新仓下的算法卡片的配置
                    // 因为新老格式的配置存在部分参数配置不一致问题（例如：报警阈值）无法兼容

                    const newInputSchemaKeyList = Object.keys(algTypeBySchema.inputSchema);

                    for (let oldKey in result.data) {
                        if (newInputSchemaKeyList.indexOf(oldKey) !== -1) {
                            delete result.data[oldKey];
                        }
                    }

                    cardConfig = Object.assign({}, cardConfig, {
                        ...result.data,
                    });
                } else {
                    // 请求返回有错误，接口请求得不到对应config
                }
            });
        }

        // 还需要通过 oldVersionReqUrlList 获取旧的 alarm.json
        const alarmConfigList = await getAlgoWarehouceConfigByUrlList(oldVersionReqUrlList, 'alarm');
        // const oldAlarmConfig = {};
        alarmConfigList.map(result => {
            if (result && result.status === 200 && typeof result.data !== 'string') {
                // 返回的数据不是string 表示返回的数据是正确的
                const data = result.data;

                if (alarmConfig.en) {
                    // 需要遍历 alarmConfig.en 下面的所有值，进行合并
                    // 不能只合并 alarmConfig.en 这一层
                    for (let key in alarmConfig.en) {
                        alarmConfig.en[key] = Object.assign(alarmConfig.en[key], data.en[key]);
                    }
                } else {
                    alarmConfig.en = data.en;
                }

                if (alarmConfig.cn) {
                    for (let key in alarmConfig.cn) {
                        alarmConfig.cn[key] = Object.assign(alarmConfig.cn[key], data.cn[key]);
                    }
                } else {
                    alarmConfig.cn = data.cn;
                }
            }
        });

        if (Object.keys(alarmConfig).length >= 2) {
            // this.useLocalAlarmConfig = false;
            // this.remoteAlarmConfig = oldAlarmConfig;
        }
    }

    // 最终返回解析后的数据
    return {
        cardConfig, alarmConfig, oldAlgSrcVersion, algTypeBySchema
    };
};
