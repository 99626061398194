/**
 * @desc 定义一些公共变量和方法
 * 
 * */
// 蒙层背景色
export const maskBackground = 'rgba(0, 0, 0, 0.6)';

// 语言包
export const Languages = [
    { id: 'cn', value: '中文' },
    { id: 'en', value: 'ENGLISH' },
    // {id: 'jp', value: '日本語'},
];

// 编辑类型 新增/修改
export const EditType = { Add: 'add', Update: 'update' };

export const strangerGroupName = 'gbKinpol';

// 布点默认基本信息
export const initVideoForm = {
    videoNo: '',
    videoName: '',
    url: 'rtsp://',
    subUrl: 'rtsp://',
    username: '',
    password: '',
    rotation: 0,
    videoType: 'rtsp',
};

// 人脸&结构化规则默认配置
export const initCapSet = {
    livenessEnabled: false,
    livenessThreshold: 50,
    attributeEnabled: true,
    pushConfigs: {
        pushMode: 1,
        retryCount: 5,
        retryInterval: 5,
        strangerRetryCount: 2,
        pushInterval: 500,
        maxPushNumber: 1,
    },
    detectTargetList: [
        ['face', 'human'],
        ['face', 'human', 'motorVehicle', 'nonMotorVehicle', 'plate'],
    ],
    captureConfigs: {
        captureMode: 1,
        quality: {
            faceQuality: 50,
            humanQuality: 50,
            motorVehicleQuality: 45,
            nonMotorVehicleQuality: 0,
            plateQuality: 0,
        },
    },
    surveillanceEnabled: true,
    filter: {
        roll: 45,
        yaw: 35,
        pitch: 45,
        blurness: 60,
        faceMin: 50,
        bodyMin: 80,
    },
    group: [],
    globalStranger: {
        enable: false,
        searchThresholdLow: 0,
        startTime: '000000',
        endTime: '000000',
    },
    bestStrangerUpload: false,
};

// 初始化脸人数据
export const initFaceSet = {
    face_param: {
        "areas_param": {
          "areas": [],
          "enable": false
        },
        "biodetection_enable": false,
        "biodetection_threshold": 50,
        "detect_target_list": [
          "face",
          "pedestrian"
        ],
        "face_attr_enable": true,
        "group_list_info": [],
        "high_threshold": 68,
        "low_threshold": 0
    },
    extend_face_param: {
        "capture_param": {
          "capture_mode": "high_quality",
          "face_quality": 50,
          "pedestrian_quality": 50
        },
        "enable": false,
        "filter": {
          "blurness": 60,
          "body_min": 80,
          "face_min": 50,
          "pitch": 45,
          "roll": 45,
          "yaw": 35
        },
        "global_stranger_param": {
          "enable": false,
          "schedule_plan": {
            "schedule_plan_id": "1"
          },
          "threshold": 0,
          "upload_best_stranger": false
        },
        "push_param": {
          "max_push_number": 1,
          "push_interval": 500,
          "push_mode": "fast"
        },
        "recog_param": {
          "retry_count": 5,
          "retry_interval": 5000
        },
        "stranger_retry_count": 2
    }
}

// 初始化结构化数据
export const initStructureSet = {
    structure_param: {
        "areas_param": {
          "areas": [],
          "enable": true
        },
        "detect_target_list": [
          "face",
          "pedestrian",
          "vehicle",
          "non-motor",
          "plate"
        ],
        "target_attr_enable": true
    },
    extend_structure_param: {
        "capture_param": {
          "capture_mode": "high_quality",
          "face_quality": 50,
          "non-motor_quality": 0,
          "pedestrian_quality": 50,
          "vehicle_quality": 45
        },
        "enable": false,
        "push_param": {
          "max_push_number": 1,
          "push_interval": 500,
          "push_mode": "best"
        }
    }
}

// 脸人参数范围
export const faceAttrRange = {
    push_mode: { defaultVal: 'fast' },
    upload_best_stranger: { defaultVal: false },
    face_attr_enable: { defaultVal: false },
    retry_count: { defaultVal: 5, range: [0, 5000] },
    retry_interval: { defaultVal: 5, range: [0, 60 * 1000] },
    stranger_retry_count: { defaultVal: 2, range: [0, 5] },
    push_interval: { defaultVal: 500, range: [500, 10000] },
    capture_mode: { defaultVal: 'high_quality' },
    face_quality: { defaultVal: 50, range: [0, 100] },
    pedestrian_quality: { defaultVal: 50, range: [0, 100] },
    roll: { defaultVal: 45, range: [0, 180] },
    yaw: { defaultVal: 35, range: [0, 100] },
    pitch: { defaultVal: 45, range: [0, 100] },
    blurness: { defaultVal: 60, range: [0, 100] },
    face_min: { defaultVal: 50, range: [5, 380] },
    body_min: { defaultVal: 80, range: [20, 380] },
    biodetection_enable: { defaultVal: false },
    biodetection_threshold: { defaultVal: 50, range: [0, 100] },
    high_threshold: { defaultVal: 68, range: [0, 100] },
    low_threshold: { defaultVal: 0, range: [0, 100] },
}
// 结构化参数范围
export const structureAttrRange = {
    push_interval: { defaultVal: 500, range: [500, 10000] },
    push_mode: { defaultVal: 'fast' },
    max_push_number: { defaultVal: 1, range: [1, 10] },
    capture_mode: { defaultVal: 'high_quality' },
    face_quality: { defaultVal: 50, range: [0, 100] },
    pedestrian_quality: { defaultVal: 50, range: [0, 100] },
    vehicle_quality: { defaultVal: 45, range: [0, 100] },
    'non-motor_quality': { defaultVal: 0, range: [0, 100] },
    target_attr_enable: { defaultVal: false },
}

// 默认的布点列表
export const initVideoList = [
    { chn: '', name: '', play: false, full: false, chnType: 0 },
    // {chn: '', name: '', play: false, full: false},
    // {chn: '', name: '', play: false, full: false},
    // {chn: '', name: '', play: false, full: false}
];

// 绘制区域背景色
export const DrawAreaBackgroundColor = {
    MaskArea: 'rgb(254, 0, 254)', // 屏蔽区域画线颜色
    IgnoredArea: 'rgba(0,0,255, 0.2)', // 屏蔽区域填充颜色
    AlertArea: 'rgba(255, 0, 0, 0.2)', // 警戒区域填充颜色
    ActiveAlertPathColor: '#11ff00', // 警戒区域高亮颜色
    PreAlertLineColor: 'red'// 告警线条颜色(第二条话的线)
};

// 布点通道算法类型
export const AlgType = {
    FaceHuman: 0, // 脸人
    Structure: 1, // 结构化
    Perimeter: 10, // 周界警戒
    Action: 11, // 行为警戒
    // 新增的算法
    CityAlarm: 12, // 城管算法
    SafetyAlarm: 13, // 安监算法
    AlertAlarm: 14, // 警戒算法
    MclzAlarm: 15, // 明厨亮灶算法
    GoodsAlarm: 16, // 物品算法
    FireAlarm: 17, // 消防算法
    HeadCount: 20, // 人数统计
};

// 3.0算法类型配对
export const NewAlgType = {
    face: 0, // 脸人
    structure: 1, // 结构化
    perimeter_alarm: 21, // 周界警戒
    behavior_alarm: 22, // 行为警戒
    // 新增的算法
    city_alarm: 12, // 城管算法
    safety_alarm: 13, // 安监算法
    // AlertAlarm: 14, // 警戒算法
    mclz_alarm: 15, // 明厨亮灶算法
    goods_alarm: 16, // 物品算法
    fire_alarm: 17, // 消防算法
    headcount_alarm: 20, // 人数统计
}

// 工勘文档下载链接
export const DocHrefByAlgType = {
    21: 'https://manual.megvii.com/MegCube-B4H工勘指导-周界',
    22: 'https://manual.megvii.com/MegCube-B4H工勘指导-行为',
    10: 'https://manual.megvii.com/MegCube-B4H工勘指导-周界',
    11: 'https://manual.megvii.com/MegCube-B4H工勘指导-行为',
    16: 'https://manual.megvii.com/MegCube-B4H工勘指导-物品',
    13: 'https://manual.megvii.com/MegCube-B4H工勘指导-生产安监',
    17: 'https://manual.megvii.com/MegCube-B4H工勘指导-消防',
    15: 'https://manual.megvii.com/MegCube-B4H工勘指导-明厨亮灶',
    20: 'https://manual.megvii.com/MegCube-B4H工勘指导-人数统计',
}

// 兼容警戒模式, 将警戒算法拆分成 周界和行为 警戒
export const CompatibleAlertType = {
    None: { type: -1, value: 0 },
    Perimeter: { type: AlgType.Perimeter, value: 1 },
    Action: { type: AlgType.Action, value: 2 },
};

// 事件类型
export const AlertorType = {
    Park: { name: 'park', value: 4 }, // 车辆禁停
    Exit: { name: 'exit', value: 5 }, // 车辆离开
    Wander: { name: 'wander', value: 6 }, // 人员徘徊
    OverWall: { name: 'overwall', value: 7 }, // 翻墙
    Fall: { name: 'fall', value: 8 }, // 摔倒
    Smoke: { name: 'smoke', value: 9 }, // 抽烟
    Call: { name: 'call', value: 10 }, // 打电话
    WatchPhone: { name: 'watch_phone', value: 11 }, // 玩手机
    Run: { name: 'run', value: 12 }, // 奔跑
    Sleep: { name: 'sleep', value: 13 }, // 睡岗
    // SingleLine: {name: 'single_line', value: 14}, // 经营类
    // SingleLine: {name: 'single_line', value: 15}, // 杂物类
    // SingleLine: {name: 'single_line', value: 16}, // 普通垃圾
    // SingleLine: {name: 'single_line', value: 17}, // 广告
    // SingleLine: {name: 'single_line', value: 18}, // 地面积水
    NoSafetyCap: { name: 'no_safetycap', value: 19 }, // 安全帽报警
    NoUniform: { name: 'no_uniform', value: 20 }, // 安全工服报警
    NoSafetyBelt: { name: 'no_safetybelt', value: 21 }, // 安全带报警
    Flame: { name: 'flame', value: 22 }, // 火焰
    Smog: { name: 'smog', value: 23 }, // 烟雾
    OilSpill: { name: 'oil_spill', value: 24 }, // 漏油
    PersonOffDutyQuerying: { name: 'person_off_duty_querying', value: 25 }, // 人员离岗
    Gathering: { name: 'gathering', value: 26 }, // 人员聚众
    Fight: { name: 'fight', value: 27 }, // 人员扭打
    Intrusion: { name: 'intrusion', value: 28 }, // 入侵
    Tripwire: { name: 'tripwire', value: 29 }, // 越界
    NoVest: { name: 'no_vest', value: 30 }, // 反光衣报警
    LoseFireEquipment: { name: 'lose_fire_equipment', value: 31 }, // 消防设施(包含灭火器和灭火箱)
    MaskArea: { name: 'mask_area', value: 32 }, // 屏蔽区域
    TrashBin: { name: 'trashbin', value: 33 }, // 未盖垃圾桶
    Mice: { name: 'mice', value: 34 }, // 老鼠报警
    SundryDetect: { name: 'sundry_detect', value: 35 }, // 杂物堆放
    HeadCount: { name: 'head_count', value: 51 }, // 区域人数统计
    CrossLine: { name: 'cross_line', value: 52 }, // 出入口人数统计
    NoChefCloth: { name: 'no_chef_cloth', value: 55 }, // 厨师服
    GoodsForget: { name: 'goods_forget', value: 64 }, // 物品遗留
    GoodsGuard: { name: 'goods_guard', value: 65 }, // 物品看守
    OverflowedGarbage: { name: 'overflowed_garbage', value: 68 }, // 垃圾满溢
    ExposedGarbage: { name: 'exposed_garbage', value: 69 }, // 垃圾暴露
};

// ROI区域类型 3.0
export const RoiTypeNew = {
    SingleLine: { name: 'SINGLE_LINE', value: 1 }, // 单向单绊线
    SingleLineBoth: { name: 'SINGLE_LINE_BOTH', value: 2 }, // 双向单绊线
    MultiLine: { name: 'MULTI_LINE', value: 3 }, // 单向双绊线
    MultiLineBoth: { name: 'MULTI_LINE_BOTH', value: 4 }, // 双向双绊线
    Polygon: { name: 'POLYGON', value: 5 }, // 区域
    CrossLine: { name: 'cross_line', value: 52 },
};

// ROI区域类型
export const RoiType = {
    SingleLine: { name: 'single_line', value: 1 }, // 单向单绊线
    SingleLineBoth: { name: 'single_line_both', value: 2 }, // 双向单绊线
    MultiLine: { name: 'multi_line', value: 3 }, // 单向双绊线
    MultiLineBoth: { name: 'multi_line_both', value: 4 }, // 双向双绊线
    Polygon: { name: 'polygon', value: 5 }, // 区域
    CrossLine: { name: 'cross_line', value: 52 },
};

// 安全工服允许的颜色
export const AllowedUniformColors = {
    Red: 'red',
    Blue: 'blue',
    Yellow: 'yellow',
    Gray: 'gray',
};

// 算法类型默认配置, 默认警戒类型
export const CommonAlgSet = {
    areaInfo: [], // 警戒区域
    targetRange: [0.06, 1], // 目标大小
    targetRangeGoodsForget: [0, 1], // 物品遗留的目标大小默认值（特殊）
    detectTargetList: [], // 目标类型
    allowedColors: [], // 允许的颜色
    expectedNum: 2, // 消防设施个数
    targetNumRange: [0, 1], // 目标个数大小
    objCount: 5, // 聚众人数
    dutyRatio: 0.5, // 杂物与布控区域比例
    interruptEndurance: 5, // web无须配置，直接给固定值
    personPreOverWall: false, // 翻墙检测预警
    cooldownDuration: { hour: 0, minute: 10, second: 0 }, // 报警间隔
    duration: { hour: 0, minute: 0, second: 3 }, // 滞留时长/延时报警
    timeRange: ['00:00:00', '00:00:00'], // 布控时段
    sensitive: 50, // 灵敏度
    custom: {
        chef_cloth: {
            allowed_color: ['white'],
        },
    },
    garbageExposedArea: 0.5, // 垃圾暴露面积
    garbageOverflowArea: 0.5, // 垃圾溢出面积
};

// 报警阈值相关参数
export const alarmThresholdInfo = {
    canConfigAlgoList: [13, 22, 17], // 支持设置报警阈值的算法仓
    canConfigAlertTypeList: [22, 23, 24, 8, 27, 9, 13, 12, 10, 11, 68, 69, 80], // 支持设置报警阈值的报警类型 // 10和11为老版本的周界和行为
    // alarmThresholdDefault: {
    //    // 火焰检测（默认阈值90），烟雾检测（默认阈值60），液体泄漏检测（默认阈值95）
    //    flame: 0.9,
    //    smog: 0.6,
    //    oil_spill: 0.95,
    //    // 摔倒检测（默认阈值95），人员扭打（默认阈值55），抽烟检测（默认阈值95），睡岗检测（默认阈值95），人员奔跑（默认阈值95），看手机（默认阈值95），打电话（默认阈值95）
    //    fall: 0.95,
    //    fight: 0.55,
    //    smoke: 0.95,
    //    sleep: 0.95,
    //    run: 0.95,
    //    watch_phone: 0.95,
    //    call: 0.95
    // }
    alarmThresholdDefault: {
        13: {
            22: 0.9,
            23: 0.6,
            24: 0.95
        },
        22: {
            8: 0.95,
            27: 0.55,
            9: 0.95,
            13: 0.95,
            12: 0.95,
            11: 0.95,
            10: 0.95,
            80: 0.85
        },
        17: {
            68: 0.75,
            69: 0.75
        }
    }
}

// 垃圾溢出&暴露面积相关参数
export const garbageThresholdInfo = {
    canConfigAlgoList: [17], // 支持的算法仓
    canConfigAlertTypeList: [68], // 支持设置垃圾溢出&暴露面积的报警类型
    // canConfigAlertTypeList: [68, 69], // 支持设置垃圾溢出&暴露面积的报警类型
    garbageThresholdDefault: {
        17: {
            68: 0.6, // 垃圾满溢面积默认值
            // 69: 0.2, // 垃圾暴露面积默认值
        }
    }
}

// 垃圾暴露和垃圾溢出相关参数
export const garbageAreaInfo = {
    canConfigGarbageExposedAreaList: [69],
    canConfigGarbageOverflowAreaList: [68]
}

// 警戒规则的最大警戒区域个数
export const MaxRuleAreaNum = 4;

// 警戒规则的最大警戒区域个数
export const MaxMaskAreaNum = 2;

export const AlgorithmInstallProgress = {
    DownStart: 1, // 下载开始
    DownEnd: 2, // 下载结束
    InstallStart: 3, // 安装开始
    InstallEnd: 4, // 安装结束
    UninstallStart: 5, // 卸载开始
    UninstallEnd: 6, // 卸载结束
    UpgradeStart: 7, // 升级开始
    UpgradeEnd: 8, // 升级结束
};

// iframe 消息类型
export const IframeMessageType = {
    Init: 'init',
    InitSuccess: 'initSuccess',
    ChangeLanguage: 'changeLanguage',
    SetSubStream: 'setSubStream',
    CheckPlayOk: 'checkPlayOk',
    VideoDestroy: 'videoDestroy',
    HandleStreamFromSocket: 'handleStreamFromSocket',
    AddSubStream: 'addSubStream',
    AlertAreaChange: 'alertAreaChange',
    MaskAreaChange: 'maskAreaChange',
    AlertSetChange: 'alertSetChange',
    DocumentClick: 'documentClick',
    ReSubscribe: 'reSubscribe',
    PostWidthAndHeight: 'postWidthAndHeight',
    FullAlgParamEnabled: 'fullAlgParamEnabled',
    DownLoadSiteSurveyGuide: 'downLoadSiteSurveyGuide',
    OpenOrCloseBigVideoModal: 'openOrCloseBigVideoModal',
    CancelTask: 'cancelTask',
    SubmitTask: 'submitTask',
    GetBodyHeight: 'getBodyHeight',
    SubmitFail: 'submitFail'
};

// 布点类型
export const VideoTypes = {
    rtsp: 'rtsp',
    gb28181: 'gb28181',
};

export const SubRuleStandard = [
    { alertor_id: 1, area_id: { min: 1, max: 4 }, rule_id: 1 },
    { alertor_id: 2, area_id: { min: 5, max: 8 }, rule_id: 2 },

    { alertor_id: 3, area_id: { min: 9, max: 10 }, mask_id: 1, rule_id: 1 },
    { alertor_id: 4, area_id: { min: 11, max: 12 }, mask_id: 2, rule_id: 2 },

    { alertor_id: 5, area_id: { min: 13, max: 16 }, rule_id: 3 },
    { alertor_id: 6, area_id: { min: 17, max: 20 }, rule_id: 4 },

    { alertor_id: 7, area_id: { min: 21, max: 22 }, mask_id: 3, rule_id: 3 },
    { alertor_id: 8, area_id: { min: 23, max: 24 }, mask_id: 4, rule_id: 4 },
];

export const doCalculateSubRule = (key, value, findKey) => {
    let result = null;
    if (key == 'area_id') {
        result = SubRuleStandard.find((item) => value >= item[key].min && value <= item[key].max);
    } else {
        result = SubRuleStandard.find((item) => item[key] == value);
    }

    if (result && result[findKey]) {
        return result[findKey];
    } else {
        return 0;
    }
};

// 绘制规则对应的算法
export const drawItemCorrespondAlg = {
    singleLine: ['TRIPWIRE'], // 越界
    singleLineBoth: ['TRIPWIRE'],
    preAlarmOneDireLine: ['CLIMB', 'OVERWALL', 'TRIPWIRE'], // 攀爬、翻墙、越界
    preAlarmLine: ['CLIMB', 'OVERWALL', 'TRIPWIRE'], // 攀爬、翻墙、越界
    outInLine: ['CROSS_LINE'], // 出入口人数统计
    maskArea: [
    // 明厨亮灶
    'CHEF_CLOTH', 'CHEF_HAT', 'CHEF_RESPIRATOR','RUBBER_GLOVE','FLAME_WITHOUT_HUMAN', 'TRASHBIN', 'MICE',
    // 安监仓
    'SAFETY_CAP', 'SAFETY_UNIFORM','SAFETY_BELT','REFLECTIVE_VEST', 'RESPIRATOR', 'FIRE','SMOKE','OIL_SPILL', 'FIRE_EQUIPMENT',
    // 城管
    'HAWKER','OUTSTORE','ROADSIDE', 'SUNDRYSTACK', 'MUCK','EXPOSED_GARBAGE','OVERFLOWED_GARBAGE', 'OUTDOOR_ADV', 'WATERGATHER', 'MUCK_TRUCK', 'OPENED_MUCKTRUCK',
    // 加油站
    'INDICATOR_FLAG', 'OIL_PIPE', 'OIL_GUN_DRAG', 'OILPUMP_DOOR_OPEN',
    // 高空抛物仓
    'FALLING_GOODS',
    // 人数统计
    'MAX_NUMBER_PEOPLE', 'CROSS_LINE',
    // 物品仓
    'SUNDRY_DETECT','GOODS_FORGET','GOODS_GUARD',
    // 警戒合仓
    'LEAVE_POST', 'PERSON_OVER_QUERYING','PERSON_LESS_QUERYING','GATHERING', 'SMOKING',
    'RUN','CALL','FALL', 'WATCH_PHONE', 'SLEEP','HOLDWEAPON','INTRUSION', 'TRIPWIRE',
    'OVERWALL','WANDER','EXIT', 'PARK', 'CLIMB','ELECTRIC_BIKE_IN_ELEVATOR','FIGHT',
    // 教学仓
    'STAND', 'RAISE_HAND', 'WRITE', 'READ', 'REST', 'HEAD_UP', 'HEAD_DOWN'
    ],
    // 不需要绘制区域或绊线就能配置的算法
    normalList: ['face', 'structure', 'IMAGE_COVER_ALERT'],
}

// 列表类数据的操作类型list
export const dataListOperateType = {
    DETAIL: { id: 1, desc: '查看详情' },
    EDIT: { id: 2, desc: '编辑' },
    DELETE: { id: 3, desc: '删除' },
    FALSE_ALARM: { id: 4, desc: '误报' }
};

// 整体列表的操作按钮list
export const operateTypeList = {
    ADD: { id: 1, desc: '添加' },
    DEDUPLICATE: { id: 2, desc: '去重' },
    BATCH_OPERATE: { id: 3, desc: '批量操作' }
};