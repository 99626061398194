const en = {
    message: {
        common: {
            LogOut: 'Log Out',
            revisePwd: 'Change Password',
            title: "Intelligent Analysis System",
            confirm: "Ok",
            cancel: "Cancel",
            save: "Save",
            delete: "Delete",
            choose: "Please Select",
            chosen: "Selected",
            input4_16: "Please enter 4-16 characters",
            submit: "Submit",
            groupName: "Group Name",
            alertName: "Group",
            timeSpan: "Time Span (00:00:00-00:00:00 valid all day)",
            timeSpan1: "(The time span is: 00:00-00:00 valid all day)",
            timeAny: "Any time span",
            num: "ID",
            more: "Operation",
            viewEdit: "Edit",
            default: "Default",
            success: "Submit Successfully",
            loading: "loading...",
            all: "All",
            search: "Search",
            description: "Description",
            enable: "On",
            disable: "Off",
            liveness: "Liveness Detection",
            feature: "Face Feature Enrollment",
            prev: "Prev",
            next: "Next",
            page: " /Page",
            total: "Total",
            from: "Start from",
            // goon: "",
            base: "Base",
            exit: "Exit",
            detail: "Details",
            item: "strip",
            age: "Age",
            gender: "Gender",
            glass: "Glasses",
            mask: "Mask",
            headwear: "Hairstyle/Hat",
            male: "Male",
            female: "Female",
            less: "Less Hair",
            short: "Short Hair",
            long: "Long Hair",
            ack: "With",
            deny: "Without",
            pageToken: "Sort By Page",
            pageOffset: "Sort By Item",
            groupBusy: "The system is in the process of face database processing, please try again later...",
            errInput: "Please enter valid characters",
            saveImg: "Save Image",
            hair: "Hairstyle",
            hair0: "Shaved head",
            hair1: "A small amount of hair (including baldness)",
            hair2: "Short hair",
            hair3: "Long hair",
            beard: "Beard",
            beard0: "No beard or beard is not obvious",
            beard1: "Beard above the lips",
            beard2: "Beard",
            hat: "Hat",
            hat0: "No hat",
            hat1: "Hard hat",
            hat2: "chef hat",
            hat3: "Student hat",
            hat4: "Helmet",
            hat5: "White Hat",
            hat6: "Headscarf",
            hat7: "Other Hats",
            respirator: "Mask",
            respirator0: "No Mask",
            respirator1: "With Mask",
            respirator2: "With Mask",
            respirator3: "With Mask",
            respirator4: "With Mask",
            /* 结构化 */
            glasses: "Glasses",
            glasses0: "No glasses",
            glasses1: "Dark frame clear glasses",
            glasses2: "Ordinary clear glasses",
            glasses3: "Sunglasses",
            skin_color: "Skin color",
            skin_color0: "Yellow skin",
            skin_color1: "white skin",
            skin_color2: "Dark Skin",
            skin_color3: "Brown skin",
            unknown: "Unknown",
            resolution: "Resolution",
            cannotEmpty: "Can not be empty",
            version: "Version",
            deleteFailed: "Delete failed",
            deleteSuccess: "Delete Ok",
            minLessThanMax: "Minimum value must be less than maximum value",
            pleaseInput: 'Please enter',
            deleteSelected: 'Delete selected',
            deleteAll: 'Delete all',
            add: 'Add',
            affirmDelete: "Confirm to delete?",
            remove: 'Remove',
            removeSelected: 'Remove selected',
            notDelete: 'Not deleted yet',
            immediateTrigger: 'Trigger',
            bindQuery: 'Bind Query',
            personFace: 'Face Photo',
            clearAll: 'Clear All',
            enterNumber: 'Please enter the number correctly',
            submitSuccess: 'Submitted successfully',
            submitFail: 'Submission failed',
            loginAccountSuc: 'Login account password configuration successful',
            loginConfigSuc: 'Successfully configured login system parameters',
            loginAccountFail: 'Login account password configuration failed',
            loginConfigFail: 'Login parameter configuration failed',
            loginSettingAllSuc: 'Login configuration parameters submitted successfully',
            noneData: 'None data',
            userManual: 'user manual',
            apiDoc: 'api doc',
            startTime: 'Start time',
            endTime: 'End time',
            lastTabTips: 'Currently, only the last label is left and cannot be closed',
            operationFailedTips: 'The following are the failed items for the operation. Please try again',
            submitting: 'Submitting',
        },
        login: {
            passwordGradeLow: 'Your current password security level is too low. Please reset it',
            passwordIrregular: "Password strength requirement is' strong/medium/weak ', please modify and resubmit",
            required: "Required",
            passwordLengthLimit: "The password length must be between 8-32 characters",
            strongPassword: "Strong: must contain any three combinations of uppercase letters, lowercase letters, numbers, and special symbols",
            centrePassword: "Middle: must contain any combination of uppercase letters, lowercase letters, numbers, and special symbols",
            weakPassword: "Weak: must contain any combination of uppercase letters, lowercase letters, and numbers",
            normalPassword: "Password length is 8-32 characters",
            oldPasswordError: "Old password error",
            oldPassword: "Old password",
            newPassword: "New password",
            affirmnewPassword: "Confirm the new password",
            confirmExit: 'Confirm to log out?',
            title: "Intelligent Analysis System",
            productName: 'MegCube',
            account: "Username",
            save: "Remember Account",
            pwd: "Password ",
            rePwd: "Confirm Password",
            // activationTitle:'',
            login: "Login",
            reset: "Cancel",
            check: "Please enter the correct account (hint: admin)",
            loginTip: "Please login first",
            unknownCode: "Unknown error, error code is",
            error: "Request failed",
            password: "(The password must be at least 8 digits long, consisting of at least two of numbers, uppercase and lowercase letters, and special symbols)",
            noAPI: "API not exist",
            serverError: "Server connection error, please try again later",
            activationTitle: "Device activation",
            activationTips: "When using the device for the first time, please activate it first",
            activation: "Activate",
            activationSuccess: "Activation Successful",
            freeze: "Enter the wrong password 5 times in a row, freeze for 5 minutes!",
            enterPassword: "Please enter your password",
            enteroldPassword: "Please enter old password",
            enterPasswordAgain: "Please enter your password again",
            diffPassword: "The passwords entered twice do not match",
            freezeTip1: 'The account is locked, please',
            freezeTip2: 'retry after minutes',
            errorTip1: 'Username or password is wrong, please re-enter, the number of retries remaining',
            errorTip2: '',
            welcome: 'Welcome to login',
            rememberPwd: 'remember password',
            loginToMore: 'Log in to the official website to learn more'
        },
        menu: {
            monitor: "Realtime Surveillance",
            alarm: "Recognition",
            alarmBind: "Recognition Bind",
            capture: "Captures",
            structureCapture: "Face Body & Structure",
            alertCapture: "Alert",
            personCount: "People Counting",
            captureBind: "Capture Bind",
            group: "Face Group",
            base: "Face Library",
            camera: "Camera",
            picStation: "Capture Camera",
            surve: "Surveillance Setting",
            relay: "Network Relay",
            faceCmp: "Application",
            previl: "Access Permission",
            single: "1：1",
            multi: "1：N",
            viewResolver: "Image Analysis",
            device: "System Setting",
            params: "System Parameter",
            setting: "Hardware Config",
            switch: "Mode Switch",
            file: "File Management",
            audio: "Audio File",
            dataRecv: "Data Integration",
            gat1400: "GA/T 1400",
            gbt28181: "GB/T 28181",
            ftpUpload: "FTP Push",
            autoUpload: "Active Push",
            videoStructure: "Preview",
            logs: "Logs",
            algorithm: "Algorithm Management",
            authorManagement: 'License Management',
        },
        monitor: {
            alarmTime: "Alarm Time",
            alarmSoruce: "Alarm Source",
            seeBigImg: "Paranoma",
            drawShield: "Draw/Show Masking Area",
            roiLable: "ROI Settings",
            moveLines: "Move Lines",
            camera: "Video Channels",
            channelName: "Channel Name",
            addDev: "Please add devices",
            drawRect: "Frame",
            screens: "4 Screens",
            screen: "1 Screen",
            rtAlarm: "Realtime Recognition",
            all: "All",
            alarmed: "Recognition Time",
            captureTime: 'Capture Time',
            rtCapture: "Realtime Capture",
            fullCap: "Full Capture",
            viewFullCap: "Full Capture",
            capture1: "Capture Details",
            group: "Group",
            alert: "Recognition Group",
            remark: "Remark",
            date: "Date",
            capture: "Recognition Details",
            getCamFailed: "Failed to get video list",
            getPic1080: "The camera is not connected to the main stream",
            detail: "Details",
            basePic: "Details",
            tips: "Click to turn on/off the alarm audio",
            videoEx: "The number of video streams connected has reached the upper limit and cannot be previewed",
            imgEx: "The number of image streams accessed has reached the upper limit and cannot be previewed",
            subStreamEx: "The resolution of the secondary stream exceeds D1/WD1, and the opening fails",
            streamEx: "The resolution of the stream exceeds 1080P, and the capture result may not be obtained",
            videoCfg: "The current channel is not connected to the secondary stream!",
            vType: "The current video stream format is not H.264 encoding, the video cannot be played!",
            pointLimit: "Click on the video area to generate an ROI area consisting of 3-5 points",
            saveRect: "Save",
            drawTips: "Start Drawing",
            clearRect: "Clear Drawing",
            disabledROI: "ROI Disabled",
            videoErr3: "The channel number range is incorrect",
            videoErr4: "Bad request data type",
            videoErr5: "Image stream not configured",
            videoErr6: "Deleted",
            videoErr7: "Connection failed",
            videoErr8: "Authentication failed",
            videoErr9: "Video stream format error",
            videoErr10: "Maximum resolution exceeded",
            videoErr11: "Decoding failed",
            videoErr12: "Exceeded maximum decoding capacity",
            noDisplay: "Snapshot storage function is not enabled, no preview",
            noDisplay1: "The background image snapshot switch is not enabled",
            beyondLimit: "Exceed the number of points, clear, re-mark",
            noIntersection: "No intersecting areas",
        },
        alert: {
            alert: 'FR Records',
            faceImg: 'Captured Face',
            chnName: 'Channel Name',
            score: 'Score',
            downVideo: 'Download Video',
            delAlarm: 'Delete',
            detail: 'Details',
            deduplicate: 'Deduplication',
            duplicate: 'No Deduplication',
            frequency: 'Frequency',
            age: 'Age',
            gender: 'Gender',
            gender0: 'Male',
            gender1: 'Female',
            gender2: 'Unknown',
            hair: 'Hairstyle',
            hair0: 'Shaved head',
            hair1: 'a small amount of hair (including baldness)',
            hair2: 'short hair',
            hair3: 'long hair',
            hair4: 'unknown',
            beard: 'beard',
            beard0: 'No beard or beard is not obvious',
            beard1: 'beard above the lips',
            beard2: 'beard',
            beard3: 'unknown',
            hat: 'hat',
            hat0: 'no hat',
            hat1: 'hard hat',
            hat2: 'chef hat',
            hat3: 'student hat',
            hat4: 'helmet',
            hat5: '01 Little White Hat',
            hat6: 'turban',
            hat7: 'other hats',
            hat8: 'unknown',
            respirator: 'mask',
            respirator0: 'No mask',
            respirator1: 'with mask',
            respirator2: 'Has a mask',
            respirator3: 'with mask',
            respirator4: 'Has a mask',
            respirator5: 'unknown',
            glasses: 'glasses',
            glasses0: 'No glasses',
            glasses1: 'Dark frame clear glasses',
            glasses2: 'Ordinary clear glasses',
            glasses3: 'sunglasses',
            glasses4: 'unknown',
            glasses32: 'unknown',
            glasses33: 'Dark frame clear glasses',
            glasses34: 'Ordinary clear glasses',
            glasses35: 'sunglasses',
            glasses36: 'Other',
            memberAll: 'All',
            memberBase: 'Person in the library',
            memberNoBase: 'Person not in the library',
            faceTokenPlaceholder: 'Please enter face token',
            objTypesAll: 'All',
            objTypesVehicle: 'Motor Vehicle',
            objTypesCycle: 'Non-Motor Vehicle',
            objTypesHuman: 'Personnel',
            // 3.0新增字段
            maskStatus: 'Mask Status',
            glassesStatus: 'Glasses Status',
            hairStatus: 'Hair Status',
            hatStatus: 'Hat Status',
            areaClass_1: 'One-way Tripwire',
            areaClass_2: 'Double-way Tripwire',
            areaClass_3: 'One-way Tripwires',
            areaClass_4: 'Double-way Tripwires',
            areaClass_5: 'Area',
            areaClass_crossline: 'Entry/Exit Tripwire',
            areaType_SINGLE_LINE: 'One-way Tripwire',
            areaType_SINGLE_LINE_BOTH: 'Double-way Tripwire',
            areaType_MULTI_LINE: 'One-way Tripwires',
            areaType_MULTI_LINE_BOTH: 'Double-way Tripwires',
            areaType_POLYGON: 'Area',
            tripwire_class: 'Crossing type',
            tripwire_class_1: 'Entering the area',
            tripwire_class_2: 'Leave the area',
            tripwire_class_3: 'Trip wire entry',
            tripwire_class_4: 'Leave at Tripwire',
            tripwire_class_5: 'Climb',
            tripwire_class_6: 'Climbing Over the Wall',
            tripwire_class_7: 'Crossing the Boarder',
            // 3.2新上报参数
            subType: 'Crossing type',
            subType_PRE_TRIPWIRE: 'Crossing the Boarder',
            subType_PRE_CLIMB: 'Climb',
            subType_PRE_OVERWALL: 'Climbing Over the Wall',
            subType_CROSS_LINE_IN: 'Trip wire entry',
            subType_CROSS_LINE_OUT: 'Leave at Tripwire',
        },
        capture: {
            capture: "Capture Records",
            autoRefresh: "Auto Refresh",
            refresh: "Refresh",
            chnName: "Channel Name",
            captured: "Capture Time",
            entered: "Enter Time",
            departure: "Leave Time",
            deleteCap: "Delete Cap",
            prev: "Prev",
            next: "Next"
        },
        group: {
            groupName: "Group Name",
            facePos: "Face Position",
            face: "Face Image",
            unbind: "Remove Binding",
            group: "Face Group",
            new: "New Group",
            groupNum: "Number of Personnel",
            delGroup: "Delete",
            delGroupTip1: "Do not delete faces in the group",
            delGroupTip2: "Delete all the faces in this group, and disassociate the faces in the group with other groups",
            delGroupTip3: "Clear all faces in the group and keep the current group",
            unbindFG: "This face will be unlinked from this group!"
        },
        library: {
            failList: "Failure List",
            clearList: "Clear List",
            imageName: "Image Name",
            detail: "Reason of Failure",
            time: "Time",
            downImg: "Download Face Image",
            face: "Face Library",
            single: "Import",
            import: "Submit",
            singleSend: "Send Once",
            top: "Top Limit",
            bind: "Related Group",
            delOrig: "Delete Raw Image",
            image: "Portrait",
            imported: "Response",
            importing: "Uploading",
            facePos: "Face Position",
            groupList: "Group List",
            batchImport: "Batch Import",
            batchChoose: "Batch Import",
            clickChoose: "Choose Folder",
            clickGroup: "Please click the group list on the right to select the group",
            groupSelected: "Please select group",
            upProgress: "upload progress",
            process: "progress",
            term: "Terminate Upload",
            done: "Upload Finished",
            stop: "Stopped",
            ctnue: "Continue",
            success: "Upload Complete",
            fail: "Upload Failed",
            total: "Total",
            elapsed: "Used Time",
            remain: "Remaining",
            expected: "Expected",
            received: "Received",
            importSuccess: "Import Success",
            importFail: "Import Failed",
            file: "File",
            faces: "Face Image",
            group: "Group",
            bindGroup: "Related Group",
            confirmlearFailed: "Are you sure you want to clear the failed list?",
            clearFailed: "Clear failed list",
            confirmInit: "This operation will delete all original photos and face photos stored in the library, and delete all associations, you cannot view the history!",
            init: "Initialize face library",
            confirmClose: "The warehousing process has not been completed. After closing the pop-up window, you will not be able to view the processing results. Are you sure you want to close the pop-up window?",
            tips: "Tips",
            netInter: "Network or internal error",
            unknownCode: "Unknown error",
            serverError: "Server connection error, please try again later",
            upError: "Request failed",
            stopConfirm: "Are you sure you want to stop the uploading file?",
            stopImport: "Immediately terminate",
            upPrgressError: "Failed to get the progress of inbound processing",
            error: "Request failed",
            unbindAll: "This operation will unbind all groups of this face!",
            delFace: "Delete face",
            confirmDelImg: "Deleting the original image cannot be restored!",
            delOrigin: "Delete original image",
            describe: "Please enter a description",
            notice: "Description:",
            imgType: "Image type: JPG, JPEG, PNG, JFIF or BMP",
            imgSize: "Image size ≤ 4MB",
            imgMax: "Maximum image size ≤ 4096*4096",
            imgMin: "Minimum image size ≥ 100*100",
            imgFormat: "The image format is incorrect",
            imgLimit: "The image size cannot exceed 4M",
            imgNum: "There can only be one face in each picture, if there are multiple faces, take the largest",
            INVALID_RESOLUTION: "Invalid Resolution",
            INVALID_TYPE: "Invalid Type",
            INVALID_SIZE: "Invalid size",
            BLURNESS: "too blurry",
            POSE: "The angle is too large",
            BLACK_WHITE: "The ratio of black and white is too large",
            FACE_TOO_SMALL: "Face is too small",
            FACE_NOT_FOUND: "No face detected",
            IMAGE_ALREADY_EXIST: "The face already exists",
            SPACE_IS_FULL: "The hard disk is full or the number of faces exceeds the limit",
            IMAGE_MD5_CHECK_FAIL: "Image md5 check failed",
            IMAGE_DECODE_FAIL: "Image decoding failed",
            IMAGE_RESIZE_FAIL: "Image resize failed",
            READ_FILE_FAIL: "Failed to read file",
            FACE_ALREADY_EXIST: "Face already exists",
            FACE_TOKEN_ALREADY_EXIST: "face_token already exists",
            INNER_ERR: "General Error",
            detectedFaceNum: "Number of detected faces",
            compareFailedFaceNum: "Number of failed comparisons",
            checkFile: "Please select folder"
        },
        station: {
            editItem: "Edit",
            newItem: "New",
            loading: "Loading……",
            info: "Basic Information",
            chnNo: "Channel No.",
            chnName: "Channel Name",
            chnNameTip: "Maximum length 50 English characters",
            channelNoTip: "For IOT and Kunlun, use 131 or 132 from 11 to 13 bits",
            chnNameEx: "Exceeded maximum character limit",
            chnType: "Protocol Type",
            deviceNo: "Device SN",
            channelNo: "Channel No.",
            chnAddress: "URL",
            user: "Username",
            pwd: "Password",
            inputUser: "Please enter a user name",
            inputPwd: "Please enter your password",
            rotation: "Rotation angle",
            filter: "Capture filter condition",
            paraRecog: "parameter recognition",
            roll: "Roll rotation angle",
            yaw: "Yaw horizontal angle",
            pitch: "Pitch vertical angle",
            bright: "Brightness",
            deviation: "Contrast",
            blurness: "Blur",
            faceMin: "Minimum face",
            faceMinTips: "(less than 50 may affect the recognition accuracy)",
            thHigh: "High Threshold",
            thLow: "Low Threshold",
            liveness: "Liveness Threshold",
            bind: "Bind Group",
            config: "Channel Settings",
            total: "Total:  ",
            source: "RTSP URL",
            delete: "Once deleted, it cannot be recovered!",
            deleteCam: "Delete Channel",
            triggerSet: "Trigger Setting",
            triggerAdd: "Add Setting",
            triggerDelete: "Delete Setting",
            triggerMode: "Trigger Mode",
            triggerCapture: "Trigger Capture",
            triggerRecognize: "Recognition Trigger",
            triggerCaptureRecognize: "Capture&Recognition Trigger",
            triggerHttpMode: "Communication Method",
            enable: "Toggle",
            paramTips: "In the whitelist mode, the high threshold is recommended to be 66, and in the blacklist mode, the high threshold is recommended to be 71.",
            paramTips1: "High threshold recommended 68",

            gbKinpol: "Global Stranger",
            onvifDetect: "Onvif Search",
            onvifList: "Device List",
            onvifSelected: "URL",
            onvifGets: "Get RTSP URL",
            onlineState: "Connection",
            onlineState0: "Online",
            onlineState1: "Offline",
            onlineState2: "Other",
            channelType: "Channel Type",
            channelType0: "Face Recognition & Face-Body Binding",
            channelType1: "Full Structure",
            channelType10: "Perimeter",
            channelType11: "Behavior",
            channelType21: 'Perimeter',
            channelType22: 'Behavior',
            // channelType12: '城管',
            channelType13: 'Work Safety',
            channelType19: 'Gas Station',
            // channelType14: '警戒',
            channelType15: 'Kitchen Hygiene',
            channelType16: 'Object',
            channelType17: 'Fire Alarm',
            channelType17s: 'Community Fire Alarm',
            channelType20: 'People Counting',
            channelType24: 'Throwing objects from heights',
            capSettings: "FR & Structure Rule Setting",
            analyse: "Analyse the attributes of the targets",
            videoConStatus: "Video Stream Connection Status",
            videoConStatus0: "Success",
            videoConStatus1: "Failure",
            videoConStatus2: "Authentication failed",
            videoConStatus3: "Format error",
            videoConStatus4: "Exceeds maximum resolution",
            videoConStatus5: "Decoding error",
            videoConStatus6: "Exceeded maximum decoding capacity",
            videoConStatus7: "H265 multi-tile mode is not supported",
            registerStatus: "Registration status",
            registerStatus0: "Unregistered",
            registerStatus1: "Registered",
            bodyMin: "Minimum Humanoid",
            livenessEnabled: "Liveness",
            bestStrangerUpload: "Upload the best global stranger",
            siteSurveyGuideDocDownLoad: 'Site Survey Guidance Document Download',
            siteSurveyGuideDocDownLoadTips: 'Tip: For drawing rules, please refer to the site survey guidance document',
            alarmConditionTextFall:
                'Trigger the alarm when the target person has the following behaviors:<br/>Recommended scenario 1: falling on the stomach, landing on the abdomen and arms<br/>Recommended scenario 1: falling on the back, landing on the buttocks and above body parts<br/> The second recommended scene 2: fall on the ground, buttocks and arms on the ground',
            alarmConditionTextSmoke:
                'Trigger the alarm when the target person has the following behaviors:<br/>Recommended scenario 1: Hold a cigarette and place it near the mouth, and the smoke is clear on the screen<br/>Recommended scenario 2: Hold the cigarette in the mouth, the smoke is on the screen Clear<br/>Scenario 1 not recommended: Holding a cigarette, not putting it on the mouth',
            alarmConditionTextCall:
                'Trigger the alarm when the target person has the following behaviors:<br/>Recommended scenario 1: Hold the mobile phone close to the ear, and the mobile phone is clear on the screen<br/>Not recommended scenario 1: The hand covers the mobile phone and cannot be clearly seen see the phone',
            alarmConditionTextWatchPhone:
                'Trigger the alarm when the target person has the following behaviors:<br/>Recommended scenario 1: The hand-held mobile phone is placed on the person chest, and the mobile phone is clear in the screen<br/>Scenario 1: The mobile phone is small or not in the screen clear',
            alarmConditionTextRun:
                'Trigger the alarm when the target person has the following behaviors:<br/>Recommended scenario 1: The person on the screen clearly shows that the arm is swinging and the leg is bent<br/>Scenario 1 is not recommended: The arm and the leg are facing the camera, and it is impossible to judge whether is running posture',
            alarmConditionTextSleep:
                'Trigger the alarm when the target person has the following behaviors:<br/>Recommended scenario 1: side view, the person arm is placed on the table, the head touches the arm, and the contact relationship between the head, hand, and table is not blocked<br/ >Second recommended scenario 1: From a birds-eye view, there may be people bowing their heads and misidentifying the alarm',
            alarmConditionTextGathering:
                'Trigger an alarm when the target person has the following behaviors:<br/>Recommended scenario 1: Multiple people (the lower limit is the set value of the number of people to gather) The target frame is in contact or overlap, and no movement occurs<br/>Scenario 1 is not recommended: Multiple people target boxes are touching or overlapping, but keep moving',
            alarmConditionTextFight:
                'Trigger the alarm when the target person has the following behaviors:<br/>Recommended Scenario 1: There is a violent attack (continuous blow, kick), and there is a physical collision between the personnel;<br/>Not recommended Scenario 1: No Body collision, no obvious attack action',
            alertSettings: "Rule Setting",
            alertEnable: "Alarm",
            alertType: "Alarm Type",
            alertSubType: "Alarm Subtype",
            personPreOverWall: "Prewarning of Over-the-wall detection",
            preOverWall: "Prewarning of Over-the-wall",
            areaType: "ROI Type",
            detectTarget: "Detect Target",
            detectTargetMotorVehicle: "Motor Vehicle",
            detectTargetNonMotorVehicle: "Non-Motor Vehicle",
            clearAlertArea: "Clear Warning Line",
            clearMaskArea: "Clear Masking Area",
            drawAreaTips: "Hint: Multiple sub-rules share the blocking area, and the blocking area is at most 2 and cannot overlap",
            targetRange: "Target Size",
            timeRange: "Time Span",
            stayDuration: "Delay Alarm",
            stayDuration2: "Fire Confirmation Time",
            cooldownDuration: "Alarm Interval",
            disappearDuration: "Fire Observation Time",
            humCross: "Personnel Crossing the Border",
            humInva: "Personnel Intrusion",
            vehiCross: "Vehicle Crossing the Border",
            vehiStop: "No Parking",
            vehiLeave: "Vehicle Leave",
            humWander: "Personnel Wandering",
            humWall: "Personnel Climbing Over the Wall",
            fireAlarm: "Fire Alarm",
            wallDetec: "Climbing Over the Wall",
            fireDetect: "Fire Detection",
            singleDetec: "Single Tripwire",
            intellWarn: "Intelligent Prewarning",
            imArea: "Enter Area",
            exArea: "Leave Area",
            imExArea: "In/Out Area",
            areaStay: "Area Stay",
            single_line: "One Way Tripwire",
            single_line_both: "Two Way Tripwire",
            multi_line: "One Way Two Tripwires",
            multi_line2: "One Way Multi Tripwires",
            multi_line_both: "Two Way Two Tripwires",
            polygon: "Area",
            fireExtinguisherNum: "Number of fire fighting equipment",
            targetNum: "Number of targets",
            objCount: "Number of people gathered",
            dutyRatio: "Area Ratio",
            range: "Range",
            hour: "H",
            minute: "M",
            second: "S",
            needMultiLine: "A two tripwire must draw two lines",
            fillAlertTime: "Please fill in the complete alarm time",
            alarmThreshold: 'Alarm threshold',

            allNo: "All",
            capPic: "Capture",
            targetType: "Target Type",
            detectAlarmList: "All Alarm Type",

            alertSubType0: "Unknown",
            alertSubType1: "Tripwire Detection",
            alertSubType2: "Enter Area",
            alertSubType3: "Leave Area",
            alertSubType4: "In/Out Area",
            alertSubType5: "Area Stay",
            alertSubType6: "Climbing Over-the-wall",
            alertSubType7: "Fire",
            alertSubType8: "Tripwire In",
            alertSubType9: "Tripwire Out",

            verifyTime: "Start time cannot be equal to end time!",
            verifyTime2: 'The start time cannot be greater than or equal to the end time!',
            verifyTime1: "Please select a time span!",

            areaType0: "Unknown",
            areaType1: "One Way Tripwire",
            areaType2: "Two Way Tripwire",
            areaType3: "One Way Two Tripwires",
            areaType4: "Two Way Two Tripwires",
            areaType5: "Area",

            ruleLabel: "Rule",
            ruleEnable: "On",
            ruleDisable: "Off",
            subRuleLabel: "Sub Rule",
            needAlertLine: "Need to draw alarm line",

            // 算法目标类型
            targetType_face: "Face",
            targetType_person: "Body",
            targetType_car: "Motor Vehicle",
            targetType_cycle: "Non-Motor Vehicle",
            targetType_plate: "License Plate",
            targetType_head: "Head",
            targetType_flame: "Flame",
            targetType_crowd: "Crowd",
            targetType_fighter: "Fighter",
            targetType_smog: "Smog",
            targetType_oil_spill: "Oil",
            targetType_fire_equipment: "Fire Equipment",
            targetType_trashbin: "Trash Bin",
            targetType_mice: "Mouse",
            targetType_sundry: "Objects",

            targetType_chef_cloth: 'Chef Clothes',
            targetType_chef_hat: 'Chef Hat',
            targetType_face_respirator: 'Respirator',
            targetType_glove: 'Rubber Gloves',
            targetType_electric_bike: 'Electric Scooter',
            targetType_goods: 'Objects',
            targetType_garbage: 'Trash',
            targetType_falling_goods: 'Falling Objects',

            targetType_motor: 'Motor Vehicle',
            targetType_indicator_flag: 'Static wire (indicator flag)',
            targetType_oil_pipe: 'Oil pipeline',
            targetType_oilpump_door: 'Oil pump door',

            // 算法目标类型---大写
            targetType_FACE: 'Face',
            targetType_PERSON: 'Body',
            targetType_CAR: 'Motor Vehicle',
            targetType_CYCLE: 'Non-Motor Vehicle',
            targetType_PLATE: 'License Plate',
            targetType_HEAD: 'Head',
            targetType_FLAME: 'Flame',
            targetType_CROWD: 'Crowd',
            targetType_FIGHTER: 'Fighter',
            targetType_SMOG: 'Smog',
            targetType_OIL_SPILL: 'Oil',
            targetType_FIRE_EQUIPMENT: 'Fire Equipment',
            targetType_TRASHBIN: 'Trash Bin',
            targetType_MICE: 'Mouse',
            targetType_SUNDRY: 'Objects',

            targetType_CHEF_CLOTH: 'Chef Clothes',
            targetType_CHEF_HAT: 'Chef Hat',
            targetType_FACE_RESPIRATOR: 'Respirator',
            targetType_GLOVE: 'Rubber Gloves',
            targetType_ELECTRIC_BIKE: 'Electric Scooter',
            targetType_GOODS: 'Objects',
            targetType_GARBAGE: 'Garbage',
            targetType_FALLING_GOODS: 'Throwing objects',

            targetType_MOTOR: 'Motor Vehicle',
            targetType_INDICATOR_FLAG: 'Static wire (indicator flag)',
            targetType_OIL_PIPE: 'Oil pipeline',
            targetType_OILPUMP_DOOR: 'Fuel tank door',

            // 3.2完善目标类型
            targetType_WALLET: 'Wallet',
            targetType_ILLEGALBAG: 'Illegal bags',
            targetType_FIRE: 'Flame',
            targetType_SMOKE: 'Smoke',
            targetType_LADDER: 'Ladder',
            targetType_INDICATOR: 'Device indicator light',
            targetType_GAS_CYLINDER: 'Cylinder',
            targetType_SLOGAN: 'Slogan',

            // 3.0新增，按需加入，未在en.js添加
            targetType_PHONE: 'Mobile',
            targetType_BAG: 'Bag',

            // 目标类型定义参考：https://git-pd.megvii-inc.com/bistro/ai-paas-pb/-/blob/main/protobuf/aipaas/v1/algo_cabin_custom.proto
            // 对象类型/目标类型 3.0告警记录详情中使用，未在en.js添加
            // objectClass_1: '人脸',
            // objectClass_2: '人体',
            // objectClass_3: '机动车',
            // objectClass_4: '非机动车',
            objectClass_5: 'License Plate',
            objectClass_6: 'Head',
            objectClass_7: 'Flame',
            objectClass_8: 'Gathering',
            objectClass_9: 'Fighter',
            objectClass_10: 'Smoke',
            objectClass_11: 'Oil',
            objectClass_12: 'Fire Equipment',
            objectClass_13: 'Trashbin',
            objectClass_14: 'Mouse',
            objectClass_15: 'Objects',
            objectClass_16: 'Not wearing chef clothes',
            objectClass_17: 'No chef hat',
            objectClass_18: 'Not wearing a mask',
            objectClass_19: 'Without gloves',
            objectClass_20: 'Static wire (indicator flag)',
            objectClass_21: 'Oil discharge pipe',
            objectClass_22: 'Electric Scooter',
            objectClass_23: 'Hand',

            objectClass_25: 'Objects',
            objectClass_26: 'Fuel tank door',
            objectClass_27: 'Motor vehicle/Tank truck',
            objectClass_28: 'Community garbage',
            objectClass_29: 'Throwing objects',
            objectClass_30: 'Business',
            objectClass_31: 'Miscellaneous',
            objectClass_32: 'Ordinary garbage',
            objectClass_33: 'Advertisement',
            objectClass_34: 'Water on the ground',
            objectClass_35: 'Mobile',
            objectClass_36: 'Bag',
            objectClass_37: 'Forklift',
            objectClass_38: 'Shelter',
            objectClass_39: 'Belt',

            // 新---目标类型
            objectClass_1: 'Body',
            objectClass_2: 'Face',
            objectClass_4: 'Head',

            objectClass_3: 'The original image of a frame of the picture',

            objectClass_1000: 'Livestock',
            // 交通工具相关（1XXXX）
            // objectClass_10001: '',
            // objectClass_10002: '',
            objectClass_10003: 'Motor Vehicle',
            objectClass_10004: 'Non-Motor Vehicle',
            objectClass_10005: 'Electric bicycle',

            // 和人相关的日常物品（2XXXX）
            objectClass_20001: 'Mobile',
            objectClass_20002: 'Bag',
            objectClass_20003: 'Wallet',
            objectClass_20004: 'Objects',
            objectClass_20005: 'Illegal bags',

            objectClass_30001: 'Flame',
            objectClass_30002: 'Smoke',
            objectClass_30003: 'Fire Equipment',
            objectClass_30004: 'Oil spill',
            objectClass_30005: 'Ladder',
            objectClass_30006: 'Device indicator light',
            objectClass_30007: 'Cylinder',
            objectClass_30008: 'Objects',
            objectClass_30009: 'Slogan',
            objectClass_30010: 'Throwing objects form a height',
            // 明厨亮灶（4XXXX）
            objectClass_40001: 'Trashbin',
            objectClass_40002: 'Mouse',
            // 城管算法仓 (5XXXX)
            objectClass_50001: 'Out-of-store operations',
            objectClass_50002: 'Itinerant hawkers, unlicensed vagrants',
            objectClass_50003: 'Occupying the street',
            objectClass_50004: 'Clutter pile',
            objectClass_50005: 'Exposure of rubbish',
            objectClass_50006: 'Illegal parking of motor vehicles',
            objectClass_50007: 'Illegal parking of non-motor vehicles',
            objectClass_50008: 'Rubbish overflow',
            objectClass_50009: 'Illegal outdoor advertising',
            objectClass_50010: 'Illegal small ads',
            objectClass_50011: 'Drying along the street',
            objectClass_50012: 'Accumulated rubbish and debris',
            objectClass_50013: 'Unclean roads',
            objectClass_50014: 'Illegal building',
            objectClass_50015: 'Damage to public facilities (broken manhole covers)',
            objectClass_50016: 'Dump truck not enclosed for transport',
            objectClass_50017: 'Pulling banners indiscriminately and hanging banners illegally',
            objectClass_50018: 'Damaged roads',
            objectClass_50019: 'Waterlogged roads',
            objectClass_50020: 'Unclean waters',
            objectClass_50021: 'Watershed order',
            objectClass_50022: 'Green Stampede',
            objectClass_50023: 'Muck truck',
            // 加油站算法仓 (6XXXX)
            objectClass_60001: 'Indicator flags',
            objectClass_60002: 'Oil bin doors',
            objectClass_60003: 'Oil pipe',

            // 工服颜色
            allowedColors: "Uniform Color",
            allowedColors_red: "Red",
            allowedColors_blue: "Blue",
            allowedColors_yellow: "Yellow",
            allowedColors_gray: "Gray"
        },
        picStation: {
            editItem: "Edit",
            newItem: "New",
            loading: "Loading...",
            info: "Basic Information",
            chnNo: "Channel No.",
            chnName: "Device Name",
            chnNameTip: "The maximum length is 50 English characters",
            chnNameEx: "Exceeded maximum character limit",
            user: "Username",
            pwd: "Password",
            devOnline: "Online Status",
            onlineY: "Online",
            onlineN: "Offline",
            inputUser: "Please enter the username",
            inputPwd: "Please enter the password",
            rotation: " rotation",
            filter: "Capture filter conditions",
            paraRecog: "Recognition Parameters",
            roll: "Roll rotation",
            yaw: "Yaw",
            pitch: "Pitch",
            bright: "Brightness",
            deviation: "Contrast",
            blurness: "Blur",
            faceMin: "Minimum face",
            faceMinTips: "(less than 50 may affect the recognition accuracy)",
            bodyMin: 'Minimal human body',
            thHigh: "Recognition High Threshold",
            thLow: "Identify Low Threshold",
            liveness: "Liveness Threshold",
            bind: "Bind the group",
            config: "Capture Settings",
            total: "Total:  ",
            source: "Device SN",
            delete: "Once deleted, it cannot be recovered!",
            deleteCam: "Delete Channel",
            triggerSet: "Trigger Setting",
            triggerAdd: "Add Setting",
            triggerDelete: "Delete Setting",
            triggerMode: "Trigger Method",
            triggerCapture: "Capture Trigger",
            triggerRecognize: "Recognition Trigger",
            triggerCaptureRecognize: "Capture & Recognition Trigger",
            triggerHttpMode: "Communication Method",
            enable: "Toggle",
            paramTips: "The recommended high threshold is 66 in whitelist mode, and 71 is recommended in blacklist mode",
            copyOk: "Copy successfully",

            itemType: "Channel Type",
            itemTypeTips: "Please select type",
            deviceIdTips: "Please enter device sn",
            ipAddrTips: "Please fill in the ip address",
            portTips: "Please fill in the port number",
            deviceSnTips: "Please fill in the SN number",
            privateAgreement: "Private Agreement",
            ipAddr: "IP address",
            port: "Port number",
            username: "Username",
            password: "Password",
            ipAddr2: "IP address (*required)",
            port2: "Port number (*required)",
            username2: "Username (*required)",
            password2: "Password (*required)",
            source2: "Device ID (*required)",
            register: "Active registration",
            SN: "SN number",
            SN2: "SN number (*required)"
        },
        structure: {
            portraitC: "Face Capture",
            vehicleC: "Motor Vehicle Capture",
            nonVehicleC: "Non-Motor Vehicle Capture",
            noCapBig: "No big capture",
            noCapDetail: "No capture details",
            path: "Path",

            videoStructure: "Analyse",
            gender: "Gender",
            gender1: "Unknown",
            gender2: "Male",
            gender3: "Female",

            eye: "eye",
            eye0: "Unknown",
            eye1: "Left eye closed",
            eye2: "Open left eye",
            eye16: "Right eye closed, unknown",
            eye17: "Close the right eye",
            eye18: "Open right eye",

            mouth: "Mouth status",
            mouth0: "Unknown",
            mouth1: "Shut up",
            mouth2: "Open your mouth",

            skinColor: "Skin color",
            skinColor0: "Unknown",
            skinColor1: "Yellow skin",
            skinColor2: "White skin",
            skinColor3: "Dark Skin",
            skinColor4: "Brown skin",

            hair: "Hairstyle",
            // hair0: "Unknown",
            // hair1: "Shaved head",
            // hair2: "A small amount of hair (including baldness)",
            // hair3: "Short hair",
            // hair4: "Long hair",
            // hair5: "Other hairstyles",
            hair1: 'Unknown', // 新增，en.js未添加
            hair2: 'Flat Top',
            hair3: 'Middle Part',
            hair4: 'Side Part',
            hair5: 'Frontal Baldness',
            hair6: 'Top Baldness',
            hair7: 'Baldness',
            hair8: 'Curly ',
            hair9: 'Waves',
            hair10: 'Braid',
            hair11: 'Updo',
            hair12: 'Shoulder-length hair',
            hair13: 'Short hair',
            hair14: 'Long hair',

            beard: "Beard",
            // beard0: "Unknown",
            // beard1: "No beard or beard is not obvious",
            // beard2: "Mustache, mustache above the lips",
            // beard3: "Whiskers",
            // beard4: "Other types",
            beard1: 'Unknown',
            beard2: 'No beard',
            beard3: 'Walrus moustache',
            beard4: 'Whiskers',
            beard5: 'Toothbrush moustache',
            beard6: 'Becoming moustache',
            beard7: 'Goatee',
            beard8: 'White Beard',
            beard9: 'Imperial',

            hat: "Hat",
            // hat0: "unknown",
            // hat1: "Wear a hat",
            // hat2: "No hat",
            hat1: 'Unknown',
            hat2: 'Not wearing a hat',
            hat3: 'Wearing a hat',

            hat16: "Hat color unknown",
            hat17: "White",
            hat18: "Other",
            hat48: "Hard hat",
            hat49: "Chef Hat",
            hat50: "Student Hat",
            hat51: "helmet",
            hat52: "Security All Race 01 Little White Hat",
            hat53: "Headscarf",
            hat54: "Other hats",
            hat55: "Unknown type",

            respirator: "Mask",
            respirator1: "Unknown",
            respirator2: "Not Wearing a mask",
            respirator3: "Wearing mask",
            respirator16: "Medical Mask",
            respirator17: "Smog Mask",
            respirator18: "Ordinary mask",
            respirator19: "Unknown type",
            respirator20: "Clear Kitchen Mask",

            glasses: "Glasses",
            glasses1: "Unknown",
            glasses2: "Not wearing glasses",
            glasses3: "Wearing glasses",
            glasses16: "It is unknown if the right eye is wearing glasses",
            glasses17: "Right eye glasses",
            glasses18: "No glasses on the right eye",
            glasses32: "Unknown left eye glasses type",
            glasses33: "Dark frame clear glasses for left eye",
            glasses34: "Ordinary clear glasses for left eye",
            glasses35: "Sunglasses for the left eye",
            glasses36: "Left eye other",
            glasses48: "Unknown right eye glasses type",
            glasses59: "Dark frame clear glasses for right eye",
            glasses50: "Ordinary clear glasses for right eye",
            glasses51: "Sunglasses for the right eye",
            glasses52: "Other right eye",

            clothes: "Top color and style",
            // clothes0: "The color of the shirt is unknown",
            // clothes1: "Llack top",
            // clothes2: "White top",
            // clothes3: "Grey top",
            // clothes4: "Red top",
            // clothes5: "Orange top",
            // clothes6: "Yellow top",
            // clothes7: "Green top",
            // clothes8: "Dark blue top",
            // clothes9: "Light blue top",
            // clothes10: "Purple Top",
            // clothes11: "Pink top",
            // clothes12: "Brown top",
            // clothes13: "Colored tops",
            // clothes32: "Long and short sleeves unknown",
            // clothes33: "Long sleeves",
            // clothes34: "Short Sleeves",
            clothes1: 'unknown', // replace the enumeration type, en.js is not replaced
            clothes2: 'black',
            clothes3: 'white',
            clothes4: 'gray',
            clothes5: 'red',
            clothes6: 'Orange',
            clothes7: 'yellow',
            clothes8: 'green',
            clothes9: 'dark blue',
            clothes10: 'light blue',
            clothes11: 'purple',
            clothes12: 'pink',
            clothes13: 'brown',
            clothes14: 'color',
            clothes15: 'blue',
            clothes16: 'cyan',

            coat_length1: "Unknown",
            coat_length2: "Long sleeve",
            coat_length3: "Short sleeve",
            coat_length4: "Sleeveless",

            pants: "Lower body color and style",
            // pants0: "Unknown",
            // pants1: "Black bottoms",
            // pants2: "White bottoms",
            // pants3: "Grey bottoms",
            // pants4: "Red bottoms",
            // pants5: "Orange bottoms",
            // pants6: "Yellow bottoms",
            // pants7: "Green bottoms",
            // pants8: "Dark blue bottoms",
            // pants9: "Light blue bottoms",
            // pants10: "Purple Bottoms",
            // pants11: "Pink bottoms",
            // pants12: "Brown bottoms",
            // pants13: "Colored Bottoms",
            // pants32: "Long shorts unknown",
            // pants33: "Pants",
            // pants34: "Shorts",
            // pants48: "Unknown in skirt",
            // pants49: "Wear a skirt",
            // pants50: "No skirts",
            pants1: 'unknown', // replace the enumeration type, en.js is not replaced
            pants2: 'black',
            pants3: 'white',
            pants4: 'gray',
            pants5: 'red',
            pants6: 'Orange',
            pants7: 'yellow',
            pants8: 'green',
            pants9: 'dark blue',
            pants10: 'light blue',
            pants11: 'Purple',
            pants12: 'pink',
            pants13: 'brown',
            pants14: 'blue',
            pants15: 'cyan',

            pants_length1: 'Unknown',
            pants_length2: 'Pants',
            pants_length3: 'shorts',

            bags: "Backpack Status",
            bags0: "unknown",
            bags1: "Backpack",
            bags2: "No backpack",
            bags32: "Package type unknown",
            bags33: "Backpack",
            bags34: "Shoulder bag, messenger bag",
            bags35: "Handbag",
            bags36: "Trolley case",

            rideBike: "Ride status",
            rideBike0: "Unknown",
            rideBike1: "Riding a bike",
            rideBike2: "Not riding a bike",

            umbrella: "Umbrella status",
            umbrella0: "Unknown",
            umbrella1: "Umbrella",
            umbrella2: "No umbrella",

            safetyHelmet: "Helmet",
            safetyHelmet0: "Unknown",
            safetyHelmet1: "Wearing a helmet",
            safetyHelmet2: "Not wearing a helmet",
            safetyHelmet16: "Hat color unknown",
            safetyHelmet17: "white",
            safetyHelmet18: "Other",
            safetyHelmet32: "Helmet",
            safetyHelmet33: "Chef Hat",
            safetyHelmet34: "Student Hat",
            safetyHelmet35: "helmet",
            safetyHelmet36: "Security Safety Race 01 Little White Hat",
            safetyHelmet37: "Headscarf",
            safetyHelmet38: "Other hats",
            safetyHelmet39: "Unknown type",

            action: "action",
            action0: "unknown",
            action1: "fall down",
            action2: "No falls",
            action16: "Unknown",
            action17: "smoking",
            action18: "No smoking",
            action32: "Unknown",
            action33: "Running",
            action34: "No running",
            action48: "Unknown",
            action49: "Call",
            action50: "No phone call",
            action64: "Unknown",
            action65: "Looking at the phone",
            action66: "Not looking at my phone",

            vsubClass: "Vehicle Subclass",
            vsubClass0: "unknown",
            vsubClass1: "Car",
            vsubClass2: "SUV",
            vsubClass3: "Van",
            vsubClass4: "Minibuses and buses",
            vsubClass5: "Pickup Truck",
            vsubClass6: "Truck",
            vsubClass7: "Other",

            vColor: "color",
            vColor8191: "Unknown",
            vColor8192: "Black",
            vColor8193: "white",
            vColor8194: "Dark Gray",
            vColor8195: "Brown",
            vColor8196: "Red",
            vColor8197: "Blue",
            vColor8198: "Yellow",
            vColor8199: "green",
            vColor8200: "suit color",
            vColor8201: "Silver Gray",
            vColor8202: "Orange",
            vColor8203: "Cyan",
            vColor8204: "Pink",
            vColor8205: "Purple",
            vColor8206: "Champagne",

            vOrientation: "Driving Orientation",
            vOrientation8224: "Orientation",
            vOrientation8225: "Backwards",
            vOrientation8226: "Other",

            csubClass: "Non-motor vehicle subclass",
            csubClass0: "unknown",
            csubClass1: "Bicycle",
            csubClass2: "Electric Vehicle",
            csubClass3: "Motorcycle",
            csubClass4: "Tricycle",
            csubClass5: "Other",

            type: "License plate type",
            type0: "single line",
            type1: "double line",
            type2: "unknown",

            color: "License plate color",
            color0: "blue",
            color1: "Yellow",
            color2: "black",
            color3: "white",
            color4: "green",
            color5: "Small new energy",
            color6: "Large New Energy",
            color7: "default",

            plateRecog: "LPR",
            bindAttr: "Bind Query",
            alertAttr: "Capture Query",
            brandname: "Vehicle Brand",
            bindFace: "Bind Face",
            bindHuman: "Bind Body",
            bindCycle: "Bind Non-Motor Vehicle",
            noBindings: "No binding data found",
            picStationError: "The camera does not support binding relationship query",

            baseInfo: "Base Information",
            alertInfo: "Recognition Information",
            aboveHigh: "Stranger",
            noDetected: "No bound body detected",
            videoTips: "The recording is successful, and the video file is stored in the C:\\Cubevideo folder",
            videoDownTips1: "The video is being recorded and the window cannot be switched, please end the recording first",
            videoDownTips2: "The video is being recorded and the channel cannot be switched, please end the recording first",
            overDown: "end recording",

            // 目标框提示
            noIntelRectTips_1: 'There are too many targets. The resources have exceeded the limit and the target frame cannot be overlayed',
            noIntelRectTips_2: 'No matching smart frame target bounding-box found, please try lowering the load',
        },

        audio: {
            deleteFile: "Confirmation of deletion of the file?",
            fileRepeat: 'The file name cannot be repeated',
            upload: "Audio Upload",
            list: "Audio List",
            audioNo: "Audio File ID",
            audioName: "Audio File Name",
            alarm: "Trigger Alarm",
            deleteSuccess: "Delete Successfully",
            success: "Operation Successfully",
            select: "Please select the audio file",
            uploadSuccess: "Upload Successfully",
            format: "The audio format is incorrect",
            size: "PCM audio size cannot exceed 5M",
            size1: "MP3 audio size cannot exceed 1M",
            type_limit: "Voice file type support: PCM, MP3",
            size_limit: "Voice file size limit: PCM file <= 5M, MP3 file <= 1M",
            file_limit: "Voice file name suffix limit: .pcm .mp3 .MP3",
            filename_limit: "Voice file name only supports letters, numbers, underscores and Chinese",
            audioConfig: "Audio Config",
            isBindAudio: "Whether to link voice binding",
            audioFile: "Audio File",
            alarmNum: "Alarm (Times)",
            triggerInterval: "Continuous Trigger Interval(ms)",
            alarmRange: "（Range：1-1000）",
            triggerRange: "（Range：500-50000）",
            priority: "Priority",
            high: "High",
            middle: "Middle",
            low: "Low",
            saveSuccess: "Save Successfully",
            addAudio: "Add Audio",
            addRule: "Audio file addition rules:",
            maximum20: "Only up to 20 audio files can be added"
        },

        algorithm: {
            noneReduction: 'No reversible algorithm package at this time',
            upload: "Upload",
            upgradeFile: 'Upgrade File',
            algName: "Name",
            downloadTime: "Upload Time",
            installTime: "Install Time",
            status: "Status",
            result: "Result",
            operation: "Operation",
            delete: "Delete",
            confirmDelete: "Confirm delete?",
            install: "Install",
            installed: "Installed",
            uninstalled: "Not installed",
            confirmInstall: "Confirm installation?",
            upgrade: "Upgrade",
            uninstall: "Uninstall",
            confirmUninstall: "Confirm to uninstall?",
            failUninstall: "Uninstall failed",
            refresh: "Refresh",
            tips1: "The algorithm package version is the same, no need to refresh",
            tips2: "Current algorithm package version:",
            tips3: "Firmware algorithm package version:",
            tips4: "Algorithm package refresh:",
            tips5: "This algorithm package does not exist in the firmware and cannot be restored",
            tips6: 'Non-whitelist, does not support restore',

            status1: "Download started",
            status2: "Download finished",
            status3: "Installation started",
            status4: "Installation completed",
            status5: "Uninstallation started",
            status6: "Uninstallation completed",
            status7: "Upgrade started",
            status8: "Upgrade completed",
            result1: "success",
            result2: "System call failed",
            result3: "Algorithm adapter interface call failed",
            result4: "Duplicate algorithm package type",
            result5: "Power off during process",
            result6: "Failure due to insufficient space",
            result7: "The algorithm package version does not match the pack name",
            result8: "Industry ID verification failed",
            result9: "The upgrade package file MD5 value verification failed",
            result10: "Decryption failed",
            result11: "Failed to mount directory",
            result12: "Algorithm package occupation",
            result13: "The path of the installation package needs to be mounted",
            result14: "Failed to remove file",
            result15: "The version of the new algorithm package and the upgraded algorithm package do not match",
            selectFile: "Select File",
            progress: "Progress",
            algorithmUpload: "Algorithm package upload",
            algorithmUpgrade: "Algorithm package upgrade",
            alg_version: "version",
            alg_description: "Description",
            alg_identification: "Algorithm package version",

            reset: "Reset",
            confirmReset: "Are you sure you want to restore the factory algorithm?",
            auth_channel: 'No. of licensed channel',
            auth_duedate: 'Expiry date of license',
            auth_status: 'License Status',
            unauthorized: 'Unauthorized',
            authorized: 'Authorized',
            expired: 'Expired',
            view: 'View',
            notSupportTip: 'The current algorithm warehouse version does not support viewing algorithm description files.',
            devDescriptionFile: 'Developer description file',
            algDescriptionFile: 'Algorithm description file',
            outInParams: 'In/Out Parameters',
        },
        authorize: {
            switchAuthorization: 'Switching authorization mode will invalidate the original authorization, do you want to confirm the switch? ',
            customAuthorization: 'customAuthorization',
            standardAuthorization: 'Standard Authorization',
            import: "Import",
            exportSecretKey: "Export authorization key",
            importAuthorize: 'Import license',
            identification: 'Industry ID',
            status: 'License Status',
            activate: 'Activate',
            frozen: 'Frozen',
            name: 'License file name',
            version: 'Industry version',
            time: 'License activate time',
            time1: 'License expiry time',
            algo_name: 'Algorithm package name',
            description: 'License Description',
            routes: 'No. of licensed channel',
            date: 'License valid time',
            activate_authorize: 'Activate License',
            frozen_authorize: 'Froze License',
            frozenTips: 'After the industry is frozen, the algorithm control tasks authorized by the industry will be invalidated, and the corresponding algorithm control tasks can no longer be added. Do you confirm the freeze?',
            activateTips1: 'The device can only activate one authorized SKU at a time, confirm the activation?',
            activateTips2: 'Whether to confirm freezing',
            authorize_info: 'License Information',
            authorize_type: 'License type',
            authorize_name: 'License file',
            authorize_confirm: 'Confirm to authorize',
            has_frozen: 'Frozen',

            force: 'Frozen',
            active: 'Valid',

            permanent: 'Permanent',
        },
        container: {
            containerManagement: "Container Management",
            upload: "Upload",
            addContainer: "Add Container",
            containerFile: "Container File",
            confirm: "Ok",
            containerName: "Container Name",
            containerStatus: "Container Status",
            runStatus: "Running Status",
            resourceStatus: "Resource Usage",
            dockerManagement: "Docker Management",
            dockerControl: "Docker Control",
            startDownload: "Start Download",
            downloadEnd: "Download End",
            resourceFirst: "Start of the first allocation of resources",
            resourceEnd: "End of resource allocation",
            startInstall: "Installation started",
            endInstall: "Installation finished",
            startDelete: "Delete Start",
            endDelete: "End of delete",
            startUpgrade: "Upgrade started",
            endUpgrade: "Upgrade end",
            uninstall: "Not installed",
            installed: "Installed",
            stopRun: "stopped running",
            runNormal: "Running normally",
            appError: "The program in the sandbox is abnormal",
            install: "install",
            upgrade: "Upgrade",
            upgradeContainer: "Upgrade Container",
            delete: "Delete",
            start: "Start",
            stop: "Stop",
            set: "set",
            containerSet: "Container Settings",
            memory: "Memory",
            storage: "Storage",
            sumbit: "Submit",
            restartCount: "Number of restarts",
            deleteSuccess: "Delete successful",
            installSuccess: "Installation succeeded",
            stopSuccess: "Stop succeeded",
            upgradeSuccess: "Upgrade succeeded",
            startSuccess: "Startup successful",
            setSuccess: "Set successfully",
            containerSelect: "Please select a container file",
            failed: "Failed",
            installIng: "Installing, please wait",
            success: "Operation succeeded",
            container_amount_limit: "The container has reached the limit of 4",
            img_verify: "Image verification failed",
            img_duplicate: "Duplicate image upload",
            not_intalled: "Container not installed",
            img_unpack: "Image unpack failed",
            img_not_found: "Found container image record",
            not_running: "Container is not running",
            running: "Container is running",
            name_illegal: "The container image name is illegal",
            state_get: "Failed to get container state",
            source_not_enough: "Insufficient resources such as allocated storage, cpu, memory, etc.",
            // installed: "Container installed",
            upgrade_mismatch: "The container name has no affinity, upgrade is prohibited",
            install_match: "Container name affinity, upload is prohibited",
            delete_container: "Failed to delete container",
            install_container: "Container installation failed",
            upload_container: "Container upload failed",
            upgrade_container: "Container upgrade failed",
            start_container: "The container failed to start",
            stop_container: "Failed to stop the container",
            sourceset_container: "Container resource allocation failed",
            query_container: "Container status query failed",
            stageset_container: "Container stage setup failed",
            cancelUpload: 'Cancel Upload',
        },
        logs: {
            logs: "Log",
            dw: "Export Log",
            dwc: "Export Current Page",
            search: "Search",
            description: "Description",
            timestamp: "Time",
            logSeq: "Log ID",
            type: "Type",
            type0: "All",
            type1: "Operation",
            type2: "Exception",
            type3: "System",
            type4: "Custom",
            getLogsEmpty: "The query data is empty, the log cannot be exported",
            ip: "User IP address",
            dateRangeEx: "Incorrect date range",
            ExportNull: "Exportable data is empty",
            device_reboot: 'Device reboot',
            user_login: 'User login',
            user_logout: 'User logged out',
            client_connect_ok: "Client connection successful",
            exception_event: "Exception event",
            exportSucceeded: "Export succeeded",
            exportFailed: "Failed to export",
            device_access_operation: "Remote device management operation",
            system_operation: "System operation",
            data_operation: "Data operation",
            alg_warehouse: "Algorithm warehouse operation",
            log_content: "Log content",
            log_detail_content: "log_detail_content",
            // Log content includes
            // Exception events include:
            disk_full: 'Disk full exception',
            disk_error: 'Disk error exception',
            network_disconnect: 'Network disconnect exception',
            video_loss: 'Video loss exception',
            ip_conflict: 'IP conflict',
            mac_conflict: 'MAC conflict',
            sd_card_abnormal: 'SD card abnormal',
            network_recovery: 'Network recovery',
            ftp_alarm_error: " ftp failed to upload alarm logs",
            gat1400_alarm_error: "gat1400 failed to upload alarm records",
            http_alarm_error: 'http upload alarm record failed',
            // System operations include:
            app_start: "application_start",
            app_reboot: "Should reboot with program",
            app_exception_exit: "Application exited abnormally",
            sys_reboot: "System reboot",
            sys_upgrade: "System upgrade",
            sys_modify_time: "Modify system time",
            alarm_ftp_enable: "Enable alarm FTP server",
            alarm_ftp_disable: "Disable alarm FTP server",
            enable_http_server: "Enable http server",
            enable_https_server: 'Turn off http server',
            enable_digest_auth: 'Enable authentication',
            disable_digest_auth: 'disable authentication',
            enable_dynamic_face: 'Enable dynamic underlayer',
            disable_dynamic_face: 'Disable dynamic base',
            disable_ntp: 'disable ntp timekeeping',
            enable_ntp: 'enable ntp timing',

            // Configuration operations include:
            configuration_operation: 'Configuration operation',
            import_cfg_file: 'Import configuration file',
            export_cfg_file: "export_configuration_file",
            modify_face_cfg: "Modify face configuration",
            modify_video_codes_cfg: "Modify video encoding configuration",
            modify_network_cfg: "Modify network configuration",
            plug_in_sd_card: "Insert sd memory card",
            plug_out_sd_card: "unplug sd memory card",
            // Data operations include:
            memory_card_formatting: "Format memory card",
            clear_all_date: "Clear all data",
            clear_face_lib: "Clear the face database",
            build_personnel_data: "New personnel data",
            modify_personnel_data: "modify_personnel_data",
            hot_swap: "Hot swap",
            remove_personnel_group: 'Remove personnel group',
            delete_alarm_record: 'Delete Alarm Record',
            // Video operations include:
            file_access_error: "File access error",
            file_query: 'file_query',
            // Image operations include:
            access_file_error: "File access error",
            query_file: "file_query",
            // Algorithm bin operations include:
            upload: "upload",
            install: "install",
            uninstall: "uninstall",
            remove: 'remove',
            upgrade: 'Upgrade',
            reset: 'Algorithm bin restore factory',
            single_reset: "single_algorithm_bin_restore",
            // User management includes:
            add_user: "add_user",
            remove_user: "remove_user",
            modify_user: "modify_user",
            // Security management includes:
            security_manager: "security_manager",
            reset_password: "Reset password",
            ip_not_authorized: "Client IP address is not authorized",
            violent_login: "Brute force login (user lockout occurred 3 times or more)",
            // Remote device management operations include:
            add_device: 'Add a remote device',
            remove_device: 'remove_remote_device',
            modify_device: 'modify_remote_device',
            // 标注状态
            calibrate_correct: 'Correct annotation',
            calibrate_error: 'Annotation error',
            calibrate_unrecogized: 'Unrecognized annotation',
            calibrate_timeout: 'Callout timeout',
            // 日志类型
            cloud_edge_data_verify: 'Cloud edge data verification',
        },
        singleCompare: {
            singleCmp: "1:1 Compare",
            notice: "Note：",
            imgType: "Image type: JPG, JPEG, PNG, JFIF or BMP",
            imgSize: "Image size ≤ 4MB",
            imgMax: "Maximum image size ≤ 4096*4096",
            imgMin: "Minimum image size ≥ 50*50",
            imgNum: "There can only be one face in each picture, if there are multiple faces, take the largest",
            imgFace: 'There can only be one face in each picture, if there are multiple faces take the largest',
            dragPut: "Drag or click to upload portrait",
            cmp: "Start matching",
            liveness: "Liveness Score",
            faceAnaly: "Face Analysis Parameters",
            score: "Live Score",
            face: "Face",
            leftFace: "Left Face",
            rightFace: "Right Face",
            left: "Left coordinate",
            right: "Right coordinate",
            top: "Top coordinates",
            bottom: "Bottom coordinate",
            roll: "Roll",
            yaw: "Yaw",
            pitch: "Pitch",
            pictureQuality: 'Image quality',
        },
        multiCompare: {
            search: "1:N Search",
            result: "Face search results",
            searchGroup: "Face Group",
            featureUpload: "Feature data"
        },
        structures: {
            targetImage: "Target thumbnail",
            maxTarget: "Detect up to 12 targets per image",
            humanInfo: "Human Information",
            num: "Number",
            upClothesColor: "Top Style and Color",
            downClothesColor: "Lower body styles and colors",
            faceInfo: "Face Information",
            types: "Categories",
            cycleInfo: "Non-motor vehicle information",
            plateInfo: "License plate information",
            male: "male",
            female: "Female",
            unkonwn: "gender unknown",
            unkonwn1: "Unknown",
            color0: "Color unknown",
            unknown1: "The long or short sleeves of the shirt is unknown",
            long: "Tops with long sleeves",
            short: "Top Short Sleeve",
            pants1: "black pants",
            pants2: "white pants",
            pants3: "grey pants",
            pants4: "red pants",
            pants5: "orange pants",
            pants6: "yellow pants",
            pants7: "green pants",
            pants8: "Dark blue pants",
            pants9: "Light blue pants",
            pants10: "Purple Pants",
            pants11: "Pink Pants",
            pants12: "brown pants",
            pants13: "Colorful pants",
            color1: "Color",
            color2: "Silver Gray",
            color3: "Dark Gray",
            color4: "orange",
            color5: "cyan",
            color6: "pink",
            color7: "Purple",
            color8: "Champagne",
            exit: "Exit",
            picture: "Picture Analysis",
            plateNo: "License plate number",
            imageId: "imageId",
            faceToken: "faceToken"
        },
        paramSystem: {
            activeAccess: 'Active mode access method',
            digestClose0: "Close Digest",
            digestClose:
                "Turning off the authentication mode is a security risk and the device will restart. Are you sure to turn off the authentication mode?",
            setting: "System Parameter Settings",
            settings: "Function Settings",
            state: "State",
            mode: "Working Mode",
            sysname: "System name",
            logoUpload: 'Platform Logo',
            resetDefault: 'Restore default',
            sysnamePlaceholder: 'Please fill in the system name',
            sysnameEmptyTips: 'The system name cannot be empty',
            sysnameLenTips: 'The system name cannot be greater than 32 bytes',
            upload: 'Upload',
            logoPreview: 'Logo preview',
            imgType: 'Image type: JPG, JPEG, PNG, JFIF or BMP',
            imgSize: 'Image size ≤ 4MB',
            security: "Communication security",
            http: "HTTP",
            https: "HTTPS",
            wl: "Whitelist",
            bl: "Blacklist",
            devMode: "Device Mode",
            video: "Video Stream",
            image: "Image Stream",
            pushMode: "Push Mode",
            fast: "Fast Push",
            best: "Best Push",
            interval: 'Interval Push',
            storage: "Capture Storage",
            largeStorage: "Capture Background",
            fullPicDrawRectEnabled: "Frame in Background",
            liveness: "Liveness",
            feature: "Face Feature",
            retry: "Recognition Retry Times (0-5000)",
            retryInterval: "Recognition Retry Interval(0-60s)",
            strangerRetryCount: "Stranger Recognition Retry (0~5)",
            minPushInterval: "Minimum Interval Of Push (500-10000 ms)",
            pictureRetryInterval: "Minimum Recognition Interval of Capture (0-60s)",
            pictureRetryCount: "Recognition Retry Times of Image Stream (0~5000)",
            pictureStrangerRetryCount: "Stranger Recognition Times of Image Stream (0-5)",
            advancedParamsConfig: "Advanced parameter configuration",
            advancedParamsConfigTip: 'After enable, [Video Settings] will display the full algorithm parameters.',
            bigCap: "Background Quality (10-100)",
            alertReportBufferSize: "Recognition Records Cache (0-10000)",
            captureReportBufferSize: "Capture Records Cache (0-50000)",
            algorithm: "Algorithm Version",
            detect: "Face detection model version",
            track: "Model version for face detection and tracking",
            pose: "Model version of face angle quality judgment",
            blur: "Face Blur Quality Judgment Model Version",
            search: "Face Recognition Model Version",
            live: "Face Liveness Model Version",
            reboot: "The device will reboot",
            rebootTips: "Change the authentication state, the device will reboot",
            delRbt: "This operation will delete all original photos, face photos, and all associations stored in the library, and restart the device!",
            delRbt10: "More than 100,000 base libraries,",
            warning: "Warning",
            relogin: "The setting is successful, the system is restarting, please do not close the current page",
            success: "Set successfully",
            auth: "Access page authentication",
            modifyPwd: "Change the authentication password",
            input: "Please enter a new password",
            inputConfirm: "Please confirm new password",
            inputError: "The passwords entered twice do not match!",
            modifyOk: "Modified successfully!",
            lenTip: "The maximum password length is 64 bits",
            tip: "Enable liveness requires the minimum face to be greater than 80",
            capStored: "The background image is captured and stored at the same time when the face is captured, which will increase the amount of reported data.",
            bgCompress:
                "The quality factor will affect the clarity of the background image, mainly for the compressed image storage space. Let [100] be the original image, the highest quality, occupy the largest storage space, and let [10] be the maximum compression, the lowest quality, and occupy the smallest storage space",
            text1: "Password must be at least 8 characters long",
            text2: "Password must contain a combination of at least two of the following characters",
            text3: "At least one uppercase letter",
            text4: "At least one lowercase letter",
            text5: "At least one number",
            text6: "At least one special character: `~@#$%^&*()-_=+|[{}];:'\",<.>/?",
            digestExit: "Exit Digest",
            digestTips: "When the authentication exits, the current page will be closed",
            globalAlgorithm: "Global Algorithm Settings",
            sysConfig: "System Settings",
            accessAuth: "Access Authentication",
            loginOp: "Login Option",
            digestMaxAuthTries: "Login times limit (3-10)",
            digestUnlockTime: "Unlock time (5-30 minutes)",

            megConnect: "Private Protocol",
            megConnectEnable: "Whether active mode is enabled",
            megConnectUrl: "Active Mode Address",
            megConnectPort: "Passive mode port number",

            megConnectUrlTip: "For example: tcp://127.0.0.1:34567",
            formatError: "Format Error",

            guiSettings: "Local GUI Config",
            guiEnable: "On/Off",
            yes: "Yes",
            no: "No",
            window: "Window",
            windowNum: "No. of Window",
            windowChannel: "Video channel corresponding window",
            windowNo: "Window ID",
            noChannel: "None",
            channelDeleted: "No Channel",

            videoStructure: "Structure",
            attributeEnable: "Attribute Analyse",
            detectTargetList: "Target",
            detectTargetList0: "Face",
            detectTargetList1: "Body",
            detectTargetList2: "Motor Vehicle",
            detectTargetList3: "Non-Motor Vehicle",
            detectTargetList4: "License Plate",

            captureMode: "Capture Mode",
            modeType0: "All",
            modeType1: "High Quality",
            modeType2: "Custom",

            faceQuality: "Face Quality",
            humanQuality: "Body Quality ",
            motorVehicleQuality: "Motor Vehicle Quality",
            nonMotorVehicleQuality: "Non-Motor Quality",
            plateQuality: "License Plate Quality",

            pushStrage: "Push Strategy",
            pushMode1: "Best Push",
            pushMode2: "Fast Push",
            pushModes0: "Interval Push",
            pushModes1: "Best Push",
            pushModes2: "Fast Push",
            maxPushNumber: "Maximum Number of Push(1~10)",
            pushInterval: "Push Interval (500-10000 ms)",

            smartModes: "Smart Mode Settings",
            smartMode: "Smart Mode",
            smartMode0: "Full face channel",
            smartMode1: "Mixed Channels",
            detectChange: "The smart mode changes, the system will clear all video channels",
            portTip: "port: RTSP port, default 554; channel: channel, 1 to N; after the access authentication is turned off, no need to enter the username and password.",
            example: "Example:",
            enableConnect: "Enable access authentication:",
            disableConnect: "Disable access authentication:",
            rtspMention: "Note: One-way deployment supports only one client connection rtsp stream at the same time",
            dtdk: "Dynamic Base Library",
            tip1: "Open or close the dynamic base library, the original base library (group + face) will be deleted, and restart at the same time",
            tip2: "Open the dynamic base library, all face channels must open the parsing target attribute",
            tip3: "Open the dynamic base library, the maximum number of people in the base library is reduced from 30W to 15W, but it does not affect the grouping",
            enableSSL: 'Start SSL or not',
        },
        deviceSystem: {
            testVerifySystemname: "Please enter up to 32 digits of Chinese or 48 digits of letters",
            hwConfig: "Hardware Settings",
            fwUp: "Upgrade",
            reboot: "Reboot",
            isSyncAlgorithmWarehouse: "Algorithm Pack Upgrade Synced",
            reset: "Reset",
            hwInfo: "Hardware Information",
            hwVer: "Hardware Version",
            fwVer: "Firmware Version",
            ram: "RAM Size",
            ramUsed: "RAM Used",
            devNo: "Device No.",
            devSN: "Device SN",
            megConnectVersion: "MegConnect Version",
            AiotapVersion: 'Aiotap Version',
            storage: "Storage Space",
            cpu: "CPU Usage",
            storageUsed: "Storage Used",
            cpuTemp: "CPU Temp.",
            time: "System Time",
            board: "Board Temp.",
            soft: "Web Version",
            deviceModel: "Device Model",
            licenseKey: "Device License Code",
            network: "Network Settings",
            wan: "WAN",
            lan: "LAN",
            ethIpAddr: "IP Address",
            ethSubmask: "Subnet Mask",
            ethGateway: "Gateway",
            ethDns: "DNS",
            mac: "MAC",
            ethBondMode: "Bond Mode",
            ethBondMode1: "Active-standby mode",
            ethBondMode0: "Balance Mode",
            ethBondMode2: "Dual NIC independent",
            ethBondTips: "Active-standby mode and balance mode, only WAN takes effect",

            ftpUpgrade: "OTA Settings",
            enableAuto: "Auto Upgrade",
            dayOfWeek: "Date",
            startTime: "Time",
            timeStart: "Start Time",
            timeEnd: "End Time",
            serverIp: "Server IP",
            serverPath: "Server Path",
            username: "Username",
            password: "Password",
            upgrade: "Upgrade Now",
            ftpUpConfirm: "Confirm upgrade? Query upgrade package:Confirm upgrade? Query upgrade package: ",
            ftpUpTitle: "FTP Upgrade",
            upDoneFeat: "Feature extraction complete",

            remote: "3rd Server Setting",
            hb: "Heartbeat Server",
            serverAdd: "Server address (separate ports with ':')",
            htInterval: "Heartbeat interval (1-3600 s)",
            NTP: "NTP Server",
            NTPEnable: "NTP On/Off",
            ntpServer: "Domain name or IP",
            NTPRetry: "The number of retries for verification time failure",
            ntpInterval: "NTP Interval (1-65535 m)",
            deviceTime: "Manual Set Time",
            timeZone: "Set Timezone",
            chooseTime: "Select Date/Time",
            dataUpload: "Message Push",
            uploadServer: "Server address (separate ports with ':')",
            alarmServer: "Server address (separate ports with ':')",
            captureServer: "Server address (separate ports with ':')",
            alarmUpload: "Recognition Message Push",
            alarmInterval: "Message Resend Interval (0-10s)",
            failureRetry: "Message Resend Times",
            upImage: "Upload Image",
            capImage: "Capture Message Push",
            wait: "100% uploaded, waiting for response",
            choose: "Click to upload",
            noFile: "No upgrade file selected",
            submit: "Upgrade",
            upDoneWait: "Uploaded successfully, the device is being upgraded, please do not close the current page",
            serverErr: "Server connection error, please try again later",
            upFail: "Upgrade failed",
            confirmReset: "Confirm factory reset?",
            savesetting: "Save network settings",
            resetWait: "Do not close the current page when the system is factory reset",
            confirmReboot: "Are you sure you want to reboot the device?",
            rebootWait: "The system is rebooting, please do not close the current page",
            deviceTimeErr: "The device system time is incorrect!",
            loadingDone: "Done",
            loadingDown: "Getting the upgrade package, please wait... ",
            loadingVerify: "Verify file",
            loadingFeat: "The algorithm is being upgraded, and the base library features are being re-extracted",
            loadingBatUp: "Batch storage",
            checkUpgrade: "Last upgrade status",
            upgradeType0: "Web upgrade",
            upgradeType1: "FTP manual upgrade",
            upgradeType2: "FTP automatic upgrade",
            upgradeType3: "p2p upgrade",
            upgradeType4: "MegConnect Upgrade",
            upgradeTime: "Upgrade Time",
            noUpgradeTime: "Unknown",
            upgradeResult0: "Success",
            upgradeResult1: "Verification failed",
            upgradeResult2: "Download failed",
            upgradeResult3: "No upgrade files available",
            upgradeResult4: "Error writing to disk",
            upgradeResult5: "Internal error",
            upgradeResult6: "System busy",
            upgradeStatus: "Inquiring device status, please wait...",
            exportFingerprint: 'Export fingerprint',
        },
        switchSystem: {
            title: "Smart Mode Switch",
            subTitle: "Toggle Settings",
            label: "Mode",
            mix: "Face & Structured Mix",
            alert: "Alert",
            tip: "Tip: The smart application mode switch will clear the channel, clear the history snapshot, identify the clear, keep the base library, keep the IP, and keep the activation information",
            yes: "Yes",
            no: "No",
            confirmMsg: "The smart application mode switching device will restart, some data need to be restored to factory settings, please confirm whether to switch",
            confirmTitle: "Smart Mode Switch",
            gettingFailed: "Failed to get smart mode",
            settingFailed: "Setup failed",
            loadingTip: "The system is restarting, please wait for 20 seconds to refresh the webpage and log in to the device again"
        },
        access: {
            ruleMgmt: "Access Permission",
            timeSetting: "Timespan",
            holiday: "Holiday",
            editRule: "Edit Rule",
            newRule: "New Rule",
            newTime: "New Timespan",
            newHoliday: "New Holiday",
            timeMgmt: "Timespan Setting",
            holidayMgmt: "Holiday Setting",
            rule: "Name",
            channels: "Channel",
            group: "Face Group",
            comment: "Remark",
            timeSpan: "Timespan",
            calendarNames: "Holidays",
            enable: "On/Off",
            start: "Start Time",
            end: "End Time",
            calendarName: "Name",
            repeat: "Repeat",
            noRepeat: "No Repeat",
            yearly: "Repeat Year",
            monthly: "Repeat Month",
            enabled: "Enabled",
            disabled: "Disabled",
            channel: "Channel",
            new: "New",
            edit: "Edit",
            newEditTime: "Timespan",
            scheduleName: "Name",
            range: "to",
            startDate: "Start Date",
            endDate: "End Date",
            fmt: "yyyy  MM  dd ",
            time: "Time",
            timeRange: "Timeslot",
            date: "Date",
            startRange: "Start",
            endRange: "End",
            copyAbove: "Copy Above",
            calendar: "Holiday",
            selected: "Selected",
            recentWeek: "Last Week",
            recentMonth: "Last month",
            recentSeason: "The last three months",
            one: "Mon",
            two: "Tue",
            three: "Wed",
            four: "Thu",
            five: "Fri",
            six: "Sat",
            seven: "Sun",
            dateFmt1: "MM dd",
            dateFmt2: "yyyy MM dd",
            equipment: 'Device',
        },
        relay: {
            relay: "Relay Settings",
            videoNo: "Channel",
            triggerChannel: "Relay Channel",
            relayIP: "Relay IP",
            relayMAC: "Relay MAC",
            relayMode: "Word Mode",
            relayWorkPara: "Parameters",
            timeUnitTips: "Modifying the unit time will cause the working state of the relay to change",
            AESKeyTips: "Relay 16-bit communication password",
            netMode: "Network Mode",
            timeUnit: "Time Unit",
            AESKey: "Password",
            triggerTime0: "Trigger Duration(ms)",
            triggerTime1: "Trigger Duration(s)",
            delayTime: "Trigger Delay(s)",
            triggerInterval: "Trigger Interval(ms)",
            triggerCondition: "Event",
            status: "Status",
            enable: "On/Off",
            online: "Online",
            operation: "Operation",
            edit: "Edit",
            delete: "Delete",
            recog: "Recognition",
            capture: "Capture",
            capture_recognition: "Recog+Capture",
            search: "Search",
            submit: "Submit",
            trigger: "Trigger Now",
            open: "Normal Open",
            close: "Normal Close",
            unbindFG: "Are you sure to unbind this relay?",
            unbind: "Unbind the relay",
            recogCap: "Capture + Recognition",
            success: "Unbind successfully!",
            triggerOk: "Trigger successful!",
            delayTimeTips: "(0-10 seconds)",
            triggerTimeTips0: "(100-50000ms)",
            triggerTimeTips1: "(0-50 seconds)",
            triggerIntervalTips: "(0-50000ms)",
            milliSec: "100 milliseconds",
            seconds: "1 second",
            relayType: "Relay function",
            relayTypeA: "Pass",
            relayTypeB: "Alarm",
            relayEdit0: "Add",
            relayEdit1: "Edit",
            relays: "Relays"
        },
        autoUpload: {
            autoUpload: "Message Push",
            wsMode: "Websocket Mode",
            httpMode: "HTTP Mode",
            dataUpload: "Message Push",
            alarmServer: "Server address (separate ports with ':')",
            captureServer: "Server address (separate ports with ':')",
            msgUpload: "Recognition Message Push",
            alarmInterval: "Recognition Message Push Interval(0-10s)",
            alarmUpload: "Recognition Message Push",
            capImage: "Capture Message Push",
            featureUpload: "Feature Data",
            upImage: "Image",
            upSmallImage: "Thumbnail",
            upBigImage: "Background",
            persistReport: "Resend after failure",
            failureRetry: "Recognition Message Resend Times (0-2)",
            httpServer: "Main server address (separate ports with ':')",
            subServer: "Address of slave server (separate ports with ':')",
            httpRetry: "Resend Times (0-3)",
            httpInterval: "Resend Interval(1-5s)",
            dataType: "Data Type",
            recog: "Recognition",
            capture: "Capture",
            recogCap: "Capture+Recognition",
            success: "Unbind successfully!",
            triggerOk: "Trigger successful!",
            autoConnect: "Active Integration Mode",
            megEnable: "Active Integration",
            megServer: "Server IP",
            megPort: "Server Port",
            megAPPID: "APP_ID",
            megAPPKEY: "APP_KEY",
            megDevice: "Device Name",
            megSerial: "Device SN",
            megState: "Connection",
            megConnected: "Connected",
            megDiscon: "Not Connected",
            retransmission: 'Upload failed, data resent',
        },
        gat: {
            gat: "GA/T 1400 Settings",
            httpsEnable: "HTTPS",
            authEnable: "Authentication",
            authMode: "Authentication Method",
            basic: "basic",
            digest: "digest",
            fullPicEnable: "Capture",
            gatServer: "Gat Server",
            gatClient: "Gat Client",
            enable: "On/Off",
            devInfo: "Device Info",
            serverAddr: "Server IP",
            serverPort: "Port",
            username: "Username",
            password: "Password",
            keepInterval: "Keepalive Interval (30~300s)",
            backgroundUpload: "Upload Background",
            submit: "Submit",
            exit: "Exit",
            status: "Status",
            noCon: "Not connected",
            conn: "Connected",
            unCon: "Not enabled",
            disconn: "Logout",
            warning: "Are you sure you want to log out?",
            labelDevInfo: "Please consult your local authority for a registration number",
            labelIP: "Please enter a domain name or IPv4 address",
            labelUser: "Please enter a user name",
            labelPWD: "Please enter your password",
            syncTimeEnable: "Whether to enable school time",
            syncTimeInterval: "Sync time interval (1~65535s)",
            retransmissionTimes: "Number of retransmissions (0~3)",
            retransmissionInterval: "Retransmission interval (1~5s)",
            platformNo: "View library ID (1~100)",
            gaUsername: 'The maximum length of the user name is 32 characters',
        },
        ftpUpload: {
            ftpUpload: "FTP Push Settings",
            ftpEnable: "On/Off",
            enableBackground: "Upload Background",
            serverIP: "Server IP",
            port: "Port",
            user: "Username",
            pwd: "password",
            remoteDir: "remote directory",
            dir1: "First-level directory",
            dir2: "Secondary directory",
            dir3: "third-level directory",
            test: "Test connection",
            customDir: "Custom Directory",
            name0: "None",
            name1: "name",
            name2: "Number",
            name3: "date",
            name4: "Custom",
            testOk: "Connection test succeeded",
            testFail: "Connection test failed"
        },
        error: {
            sorry: "Sorry...",
            pageDown: "The page you are looking for does not exist...",
            return: "back to the home page",
            isFloat: "Cannot be a floating point number (decimal)",
            commonTips: "Submission error, please check the parameters",
            noVideoData: "Currently no video stream is playing",
            apiRetry: "Failed to obtain data request, please press F5 to refresh and try again later",

            e104000: "json structure parsing error",
            e104001: "Parameter format error",
            e104002: "Missing required parameter",
            e104003: "System restart",
            e104004: "No access rights",
            e104005: "Incorrect password format",
            e104006: "Data processing, please try again later",
            e104007: "Accessed resource does not exist",
            e104008: "Exceeds the limit of video channels",
            e104009: "No channel specified, not created or channel does not match name",
            e104010: "Video stream access failed",
            e104011: "Channel already exists",
            e104012: "The processing capacity has reached the upper limit",
            e104013: "The channel is bound to a permission rule",
            e104014: "Device ID already exists",
            e104015: "Channel name already exists",
            e104016: "Failed to get stream",
            e104017: "Video channel does not exist",
            e104018: "Empty the video channel, please wait",
            e104019: "Algorithm Capability Exceeded",
            e104020: "A channel is not allowed to enable face/structure at the same time",
            e104021: "Incorrect username or password",
            e104022: "People count already exists, one channel and multiple rules are not allowed to set people count",
            e104023: "A rule is not allowed to use the same alarm rule",
            e104024: "The device SN number already exists",
            e104025: "The device ip already exists",
            e104026: "Insufficient disk space",

            e104100: "Not activated, please activate first",
            e104101: "Login frozen, please try again later",

            e104200: "No face detected",
            e104201: "Face failed quality judgment",
            e104202: "Face token does not exist",
            e104203: "Picture does not exist",
            e104204: "Face already exists",
            e104205: "The component is full or the number of faces exceeds the limit",
            e104206: "Picture md5 verification failed",
            e104207: "Picture decoding failed",
            e104208: "Image resize failed",
            e104209: "Failed to read file",

            e104210: "Invalid resolution",
            e104211: "Invalid type",
            e104212: "Invalid size",
            e104213: "Too blurry",
            e104214: "The angle is too large",
            e104215: "B/W ratio is too large",
            e104216: "The face is too small",
            e104217: "No target detected",
            e104312: "face_token already exists",

            e104300: "The group name already exists or the group name conflicts",
            e104301: "Group does not exist",
            e104302: "The number of groups exceeds the upper limit",
            e104303: "Binding relationship does not exist",
            e104304: "The group is in use and cannot be deleted",
            e104305: "The feature version is inconsistent with the system",
            e104306: "Face database synchronization, please try again later...",
            e104307: "The system is processing the face database, please try again later...",
            e104308: "The number of stored pictures exceeds the upper limit",
            e104309: "Group is bound to permission rules",

            e104400: "Capture storage is not enabled",
            e104401: "Server is busy",
            e104402: "Work mode mismatch",
            e104403: "Upgrade package file size/too big",
            e104404: "Error receiving upgrade file",
            e104405: "The format of the upgrade package is incorrect",
            e104406: "Update package MD5 check failed",
            e104407: "Upgrade package not found",
            e104408: "Upgrade in progress",
            e104409: "Failed to download upgrade package",
            e104410: "FTP upgrade username and password verification failed",
            e104411: "FTP server connection failed",
            e104412: "FTP server path does not exist",
            e104430: "Algorithm bin version verification failed",
            e104431: "The filename of the algorithm warehouse upgrade contains illegal characters",
            e104432: "Duplicate file name for algorithm warehouse upgrade",
            e104433: "Failed to decompress the algorithm warehouse upgrade file",
            e104434: "The content of the algorithm warehouse upgrade file is incorrect",
            e104435: "Algorithm warehouse operation",
            e104436: "The algorithm ID is duplicated, please do not install it again",
            e104437: "Another version of the algorithm has been installed, it is recommended to upgrade",
            e104439: "Algorithm package dependency version error",
            e104460: "Container manager exception",
            e104461: "Container operation",
            e104462: "The number of containers has reached the upper limit of 4",
            e104463: "Mirror verification failed",
            e104464: "Duplicate upload image",
            e104465: "Image decompression failed",
            e104466: "Insufficient container allocatable resources",
            e104467: "Containers have no affinity, no upgrade is allowed",
            e104468: "Affinity container, upload prohibited",

            e104469: "Container image name is not compliant",
            e104470: "The container is not mounted, the operation is not supported",
            e104471: "The container is not running, the operation is not supported",
            e104472: "The container is running, this operation is not supported",
            e104473: "The container is already installed, this operation is not supported",
            e104474: "Container deletion failed",
            e104475: "Container installation failed",
            e104476: "Container upload failed",
            e104477: "Container upgrade failed",
            e104478: "Container startup failed",
            e104479: "Failed to stop container",
            e104480: "Container setup failed",
            e104481: "Container query failed",
            e104482: "Failed to set container state",
            e104483: 'Incorrect container format',

            e104501: "The channel number is invalid",
            e104502: "Channel not created",
            e104503: "The channel has no relay configured",
            e104504: "The relay failed to trigger",
            e104505: "NTP conflicts with GA1400 or GB28181",
            e104506: "Set IP address conflict",
            e104507: "Channel relay not enabled",
            e104508: "Relay working mode conflict, please check the channel",
            e104509: "The IP address and gateway are not in the same network segment",
            e104510: "Dual network card independent mode does not allow setting the same network segment IP",
            e104511: "GB28181 school time conflicts with GA/T1400 school time",
            e104540: 'License file name does not comply',
            e104541: 'The license file size is too large, exceeding 1MB.',
            e104542: 'License file management program exception',
            e104543: 'License file operation',
            e104544: 'License file verification failed',
            e104550: 'License file operation failed',
            e104551: 'This activation requires a device restart',

            e104601: "calendar has bound rules",
            e104602: "schedule has bound rules",
            e104603: "calendar does not exist",
            e104604: "schedule does not exist",
            e104605: "rules does not exist",
            e104606: "calendar table is full",
            e104607: "schedule table is full",
            e104608: "rules table is full",
            e104609: "calendar entry already exists",
            e104610: "schedule entry already exists",
            e104611: "rules entry already exists",
            e104612: "Invalid timerange parameter input",
            e104613: "Invalid daterange parameter input",
            e104614: "Invalid date parameter input",
            e104615: "The channel that rules tries to bind to does not exist",
            e104616: "The group that rules tries to bind to does not exist",
            e104617: "The offset used when requesting rules, calendar, and schedule is greater than the number in the current database",
            e104618: "FTP test failed",
            e104619: "Inconsistent Smart Mode",

            e104903: "GB28181 occupies LAN card and does not support mode switching",
            e104904: "Registering, do not operate, try again later",

            e104700: "Voice file number exceeds limit",
            e104701: "No voice file is configured for this number",
            e104702: "Voice file exists event binding",
            e104703: "Event type does not exist",
            e104704: "The channel is not bound to voice",
            e104705: "Voice file format error",
            e104706: "The length of the voice file name exceeds the limit",
            e104707: "Voice file is playing",
            e104708: "Failed to stop playing voice",
            e104709: "No voice is playing",
            e104710: "The voice is not playing from this video channel",
            e104711: "Not an event-triggered voice is played",
            e104712: "The number of voice play task lists exceeds the limit",
            e104800: "Failed to initialize local GUI",
            e104801: "The number of GUI playback video channels exceeds the limit",
            e104802: "Window cannot be set to the same video channel",

            e105000: "Other internal error",
            e105100: "The database is being upgraded",
            e105202: "Image file operation",
            e104438: "The algorithm does not support uninstallation",

            e106000: "The request failed, the interface does not exist",
            e404: "The request failed, the interface does not exist",
            e500: "Other internal error",

            e104552: 'Exceeded the number of licensed channels of the algorithm package',
        },
        gbt: {
            gbt: "GB/T 28181 Settings",
            client: "GB Client",
            server: "GB Server",
            sipServerId: "SIP server ID",
            sipServerDomain: "SIP Domain",
            sipServerAddr: "SIP server IP",
            sipServerPort: "SIP server port number",
            sipEnableAuth: "Enable Authentication",
            username: "registered username",
            password: "Register password",
            deviceId: "Device ID",
            localSipPort: "Local SIP Port",
            localSipIP: "local IP",
            expiration: "Registration Validity Period",
            heartBeatInterval: "Heartbeat Interval",
            heartBeatCount: "	Heartbeat Count",
            civilCode: "Administrative division code",
            transProtocol: "Signaling Transport Protocol",
            number: "",
            streamTransProtocol: "Stream Transport Protocol",
            syncTimeEnable: "Enable timing",
            chnInfo: "Channel Info",
            channel: "Channel",
            videoNo: "Video channel number",
            chnId: "Channel ID",
            alarmLevel: "Alarm Level",
            sipWan: "NIC",
            registerStatus: "Register Status",
            notEnabled: "Not Enabled",
            registerFailed: "Registration failed",
            registerSuccess: "Registration succeeded",
            setting: "Settings",
            integer: "Please enter an integer",
            error: {
                e107001: "Please enter the SIP server number",
                e107002: "The length of the SIP server ID is 20 characters",
                e107003: "Please enter a SIP domain",
                e107004: "Please enter the SIP server IP",
                e107005: "SIP server IP format error",
                e107006: "Please enter the SIP server port",
                e107007: "SIP server port (1-65535)",
                e107008: "Please enter the device number",
                e107009: "The device number is 20 characters long",
                e107010: "Please enter the local SIP port",
                e107011: "Local SIP Port (1-65535)",
                e107012: "Please enter the registered user name",
                e107013: "The maximum length of the registered user name is 32 characters",
                e107014: "The maximum length of the registered password is 32 characters",
                e107015: "Please enter the registration validity period",
                e107016: "Registration validity period (3600-65535 seconds)",
                e107017: "Please enter the heartbeat period",
                e107018: "Heartbeat Interval (30-180 seconds)",
                e107019: "Please enter the maximum heartbeat timeouts",
                e107020: "Maximum heartbeat timeouts (3-5 times)",
                e107021: "Please fill in the network card",
                e107022: "Please fill in the streaming protocol",
                e107023: "Please select a local IP",
                e107024: "Please enter an even number",
                e107025: "Please enter an integer",
                e107026: "SIP server number and device number cannot be the same"
            }
        },

        person: {
            countRecord: "People Counting Records",
            monitorRecord: "Real-time Statistics",
            refresh: "Refresh",
            areaPerson: "Number of Person in Area",
            in: "In",
            leave: "Out",
            reset: "Clear",
            all: "All",
            day: "Day",
            month: "Month",
            hour: "Hour",
            days: 'Days',
            months: 'Months',
            hours: 'Hours',
            enterPersonNumber: "In Number",
            leavePersonNumber: "Out Number",
            areaPersonNumber: "Area Number",
            unit: "Unit：",
            people: "People",
            success: "Success",
            fail: "Fail",
            statisticalData: "Statistics",
            dailyStatement: "Daily Report",
            monthlyStatement: "Monthly Report",
            annualStatement: "Yearly Report",
            previewArea: "Area",
            previewIn: "In",
            previewOut: "Out",
            statisticalReport: "Statistics Report",
            total: 'Total',
            StatisticalTrends: 'Total Trend',
            operation: 'Operation',
            EquipmentSelection: 'Device Selection',
            ReportTime: 'Report Time',
            DataDimension: 'Data Dimension',
            search: 'Search',
            Reset: 'Reset',
        },

        // 3.0 新加入的词条
        newError: {
            fail: 'Fail',
            invalidParams: 'Invalid Parameter',

            // 通用错误码
            error1: 'General error',
            error2: 'Invalid parameter, protocol or interface parameter is invalid',
            error3: 'Insufficient storage space',
            error4: 'The resource cannot be accessed (this error code is used when the resource exists but the permission is insufficient)',
            error5: 'disconnected',
            error6: 'Repeated operations, such as repeated opening, repeated closing',
            error7: 'Unsupported method',
            error8: 'The resource is invalid, that is, the resource does not exist or is abnormal',
            error9: 'Access timeout',
            error10: 'Insufficient resources',
            error11: 'The resource/device already exists',
            error12: 'Invalid username or password',
            error13: 'Device is locked',
            error14: 'Out of device specification',
            error15: 'The system is busy (reaching the upper limit of processing capacity)',
            error16: 'The number of users exceeds the upper limit',
            error17: 'User already exists',
            error18: 'The number of online users exceeds the upper limit',
            error19: 'The remote device is offline',
            error20: 'The remote device already exists',
            error21: 'The remote device does not exist',
            error22: 'The device is not activated',
            error23: 'User data link broken',
            error24: 'Cache full',
            error25: 'The user actively aborted the operation',
            error26: 'Version mismatch',
            error27: 'Unsubscribing from stream',
            error28: 'The data is incomplete',
            error29: 'The amount of data is too large',
            error30: 'Missing required parameter',
            error31: 'Configuration parameter conflict',
            error32: 'Device restarting',
            error33: 'Request message body is too large',
            error34: 'Username format error',

            // 通用业务错误码
            error500: 'The channel number does not exist (invalid video stream, audio stream channel number)',
            error501: 'Decoding failed',
            error502: 'The picture does not exist',
            error503: 'md5 verification failed',
            error504: 'Failed to read file',
            error505: 'Invalid Resolution',
            error506: 'Invalid type',
            error507: 'The feature version is inconsistent with the system (the algorithm extracts the target feature version number is inconsistent)',
            error508: 'The working mode does not match',
            error509: 'The upgrade package file is abnormal',
            error510: 'Error receiving upgrade package file',
            error511: 'The resource is not ready',
            error512: 'session not found',
            error513: 'upgrading',
            error514: 'ip conflict',
            error515: 'sn conflict',
            error516: 'IP address and gateway are not in the same network segment',
            error517: 'Dual network card independent mode does not allow setting the same network segment IP',
            error518: 'Time sync configuration conflict',
            error519: 'ip format is incorrect',
            error520: 'rtspurl format error',
            error521: 'The device name is invalid',
            error522: 'The file name is invalid',
            error523: 'The number of files exceeds the upper limit',
            error524: 'The file size is illegal',
            error525: 'The file type is invalid',
            error526: 'The file has been bound to a linkage service and cannot be deleted',
            error527: 'File format error',
            error528: 'The file does not exist',
            error529: 'Failed to establish connection',
            error530: 'Snapshot storage is not enabled',
            error531: 'Audio file is playing',
            error532: 'The number of relays has reached the upper limit',
            error533: 'No matching upgrade file found',
            error534: 'File acceptance failed',
            error535: 'The file directory does not exist',

            error537: 'rtsp address contains illegal characters',

            // 远程设备接入错误码
            error601: 'Exceeded the limited number of video channels',
            error602: 'Device access failed',
            error603: 'The device name already exists',
            error604: 'The device has been bound to task configuration and cannot be deleted',

            // 智能业务错误码
            error10000: 'The size of the face image is abnormal (too large, too small)',
            error10001: 'No face detected',
            error10002: 'The face has not passed the quality judgment',
            error10003: 'The face does not exist',
            error10004: 'The face already exists',
            error10005: 'Personnel group does not exist',
            error10006: 'Personnel group already exists',
            error10007: 'Personnel grouping exceeds the upper limit',
            error10008: 'Personnel group binding relationship does not exist',
            error10009: 'The personnel group is in use and cannot be deleted',
            error10010: 'Personnel group binding relationship already exists',
            error10011: 'The face library is synchronizing, please wait a moment (when the device is just started, when adding data, related operations of the face library are prohibited)',
            error10012: 'The face library is being imported, please wait a moment (modification of personnel information, importing and other operations are prohibited during the process of face storage)',
            error10013: 'The face library picture exceeds the upper limit',
            error10014: 'The face library is stored in the database, and the upper limit of the cached pictures on the device side needs to be retransmitted',
            error10015: 'Face library import has stopped',
            error10016: 'The operation of the face library timed out, and the device automatically stops entering the database',
            error10017: 'Personnel information conflict',
            error10018: 'personnel uuid conflict',
            error10019: 'Personnel name conflict',
            error10020: 'Personnel number conflict',
            error10021: 'Personnel access card number conflict',
            error10022: 'Dynamic bottom library is not enabled',
            error10023: 'The data of the feature value of the personnel is invalid',
            error10024: 'The default personn group does not allow deletion',
            error10025: 'The default person group does not allow modification',
            error10026: 'Target not detected',
            error10027: 'Face angle is too large',
            error10028: 'Poor image quality',
            error10029: 'The black and white ratio of the image is too high',
            error10030: 'This person is only associated with one person group and cannot be removed',

            error11001: 'Channel computing power is not enough',
            error11002: 'The channel does not support this algorithm for repeated control',
            error11003: 'This algorithm has been associated with a linkage plan, please cancel the linkage before deleting',

            error11005: 'The device has been bound to a smart task',
            error11014: 'There is an algorithm that is not installed, please install the algorithm first',

            // 算法仓管理
            error12001: 'Algorithm package ID verification failed',
            error12002: 'Algorithm package upgrade file name contains illegal characters',
            error12003: 'The algorithm package upgrade file name is duplicated',
            error12004: 'Failed to decompress the algorithm package upgrade file',
            error12005: 'The content of the algorithm package upgrade file is incorrect',
            error12006: 'Algorithm package is in operation',
            error12007: 'The algorithm ID is repeated, please do not install it repeatedly',
            error12008: 'Another version of the algorithm has been installed, it is recommended to upgrade',
            error12009: 'Algorithm package does not support uninstallation',
            error12010: 'Algorithm package dependency version error',
            error12011: 'Algorithm packages only support upgrades',
            error12012: 'This algorithm library is not authorized. Please import the authorization first, and the algorithm library will be automatically installed after authorization activation',
            error12013: 'Algorithm warehouse status error, please refresh and try again',

            // 授权文件管理
            error13001: 'Authorization file name contains illegal characters',
            error13002: 'Authorization file is too large or too small',
            error13003: 'Duplicate authorization file name',
            error13004: 'The current authorization file already exists',
            error13005: 'Authorization file key does not match this device',
            error13006: 'Authorization file decryption error',
            error13007: 'Authorization file verification parameter error',
            error13008: 'The current authorization mode does not support temporary file import',
            error13009: 'The number of authorized files exceeds the upper limit',
            error13010: 'Authorization file industry identification error',
            error13011: 'Authorization file name length error',
            error13012: 'Illegal authorization file type',
            error13013: 'Illegal authorization file dimension',
            error13014: 'Illegal authorization control granularity',
            error13015: 'Illegal authorization object type',
            error13016: 'Authorization SKU is illegal',
            error13017: 'Authorization SKU duplicate',
            error13018: 'The current authorization mode does not match',
            error13019: 'No authorization file, this operation is not supported',
            error13020: 'The target SKU already has a permanent authorization, and the temporary authorization file cannot be imported',
            error13021: 'The number of authorized file writing channels does not match the hardware specifications of the current device model',

            // 规划模块
            error20001: 'The time plan is in use (it cannot be deleted when it is bound to a personnel group)',
            error20002: 'The time plan already exists (the name of the new time plan is duplicated)',
            error20003: 'The time plan exceeds the upper limit of the specification',
            error20004: 'Time plan does not exist',
            error20005: 'The time plan has been bound to linkage business and cannot be deleted',
            error20006: 'The time plan has been bound to the linkage task, it is not allowed to delete',
            error20007: 'The default plan does not allow deletion',
            error20008: 'Holidays have been bound to regular plans',

            // 容器管理模块
            error21001: 'The number of containers has reached the upper limit',
            error21002: 'Image verification failed',
            error21003: 'Duplicate image file',
            error21004: 'The container is not installed',
            error21005: 'Image decompression failed',
            error21006: 'Container does not exist',
            error21007: 'The container is not running',
            error21008: 'The container is already running',
            error21009: 'Container image name is illegal',
            error21010: 'Insufficient resources',
            error21011: 'The container is already installed',
            error21012: 'Container name has no affinity, upgrade is prohibited',
            error21013: 'Container name affinity, prohibit upload',
            error21014: 'Container resource allocation failed',

            // 联动计划
            error22001: 'The linkage plan name is invalid',
            error22002: 'Linkage plan already exists',
            error22003: 'Linkage plan does not exist',
            error22004: 'The linkage plan exceeds the upper limit',
            error22005: 'The device has been bound to a linkage plan and cannot be deleted',
            error22006: 'The relay has been bound to a linkage plan, it is not allowed to delete',
            error22007: 'The personnel group has been bound to a linkage plan, and deletion is not allowed',
            error22008: 'Personnel group has been bound to the control task, it is not allowed to delete',
            error22009: 'The relay bound to the linkage plan does not exist',
            error22010: 'The file bound to the linkage plan does not exist',
            error22011: 'The alarm output of the linkage plan is disabled',
            error22012: 'Relay not selected',
            error22013: 'Audio not selected',
            error22014: '485 RS485 not selected',
            error22015: 'Alarm out not selected',

            error23001: 'Relay trigger failure',
            error23002: 'The number of voice playback task lists exceeds the limit',

            // main.js中axios单独处理的错误码提示语
            sameTaskSelectTips: 'The same device does not allow simultaneous control of facial features and structured features',
            restartTips: 'The system is restarting, please wait...',
            upgradeTips: 'System upgrade in progress, please wait...',
            groupIsUseTips: 'Personnel grouping is in use and cannot be modified',
            groupImportTips: 'Importing face library, please do not upgrade',
            paramFormatError: 'Parameter format error',
            registeringTips: 'Please do not operate during registration period',
            outTimeRangeTips: 'The system time cannot be earlier than 20190801, please reset it'
        },
        intelRecord: {
            identityRecord: 'Recognition Record',
            alarmRecord: 'Alarm Record',
            captureRecord: 'Capture Record',
            peopleCount: 'People Counting',
            recordDeduplicate: 'Record Deduplication',
            createTaskConfig: 'New Task',
            export: 'Export',
            exitBatchOperate: 'Batch Cancel',
            batchOperate: 'Batch Operation',
            reset: 'Reset',
            expand: 'Expand',
            putAway: 'Collapse',
            captureTime: 'Capture Time',
            captureDevice: 'Capture Device',
            identityResult: 'Recognition Result',
            all: 'All',
            identitySuccess: 'Success',
            identityFailed: 'Fail',
            personName: 'Name',
            similarity: 'Similarity',
            capturePic: 'Capture Image',
            personFace: 'Face',
            panoramic: 'Panoramic',
            person: 'Person',
            bottomLibraryFace: 'Base Image',
            name: 'Name',
            belongingGroup: 'Group',
            pleaseInput: 'Please input',
            identityInfo: 'Recognition Info',
            bottomLibaryPic: 'Base Image',
            panoramicPic: 'Panoramic',
            otherComparisonResult: 'Other Result',
            recordInfo: 'Record details',
            targetMatting: 'Target Image',
            alarmType: 'Alarm Type',
            alarmTask: 'Alarm Task',
            alarmArea: 'Alarm Area',
            alarmScore: 'Alarm Score',
            alarmInformation: 'Alarm Info',
            personnelDetails: 'Personnel Details',
            faceImage: 'Face Image',
            selectPersonnel: 'Select Personnel',
            falseAlarm: 'False Alarm',
            UploadFiles: 'Upload File',
            UpdateTime: "Update Time",
            PreviousVersion: "Previous Version",
            fileFormat: "File Foramt: .tar or .pkg",
            uploadFileFormat: "File format: .tar",
            batchExport: "Batch Export",
            selectExportAction: 'Select Export Format',
            exportExcel: 'Only export table',
            excelTips: 'Without pictures, export in excel form, the file name is identification record_[export time stamp]',
            exportImage: 'Only export images',
            imageTips: 'Images are exported as compressed packages. When exporting only the picture, the picture does not have any drawn lines',
            excelAndImage: 'table+picture',
            allTips: 'Records are exported in the form of excel sheets, and pictures are exported in the form of compressed packages. Add the image address in the excel sheet to display the name of the image in the compressed package. ',
            selectExportRecord: 'Please select the record to export first',
            selectDeleteItem: 'Please select the content to delete',
            deleteSingleRecordTips: 'Are you sure to delete this record? ',
            deleteSelectRecordTips: 'Do you want to delete the selected record? ',
            notBindInfo: 'No binding data found',
            device: 'device',
            selectedDevice: 'Please select a device! ',
            targetType: 'target type',
            allType: 'all types',
            livingFraction: 'living fraction',
            area: 'Area',
            bindVehicle: 'Bind vehicle',
            galass: 'Glasses',
            mask: 'Mask',
            hat: 'Hat',
            bindFace: 'Bind Face',
            bindPlates: 'Bind license plates',
            plateRecog: 'license plate recognition',
            bindNonMotors: 'Bind non motor vehicles',
            image: 'Picture',
            areaPerson: 'Number of people in the region',
            target: 'target',
        },

        // 人员管理
        resourceManage: {
            changeFaceGroups: 'Please select an associated group',
            personnelManage: 'Personnel Management',
            personnelGroup: 'Person Group',
            photo: 'Photo',
            name: 'Name',
            group: 'Group',
            newPerson: 'New Person',
            editPerson: 'Edit Person',
            addPerson: 'Add Person',
            newPersonGroup: 'New Group',
            personGroupName: 'Group Name',
            fillPersonGroupName: 'Please fill in the name of the personnel group (support Chinese, English case, numbers, special characters (-_), the maximum support is 32 characters, Chinese accounts for 3 characters',
            editPersonGroup: 'Editor Group',
            confirmDelete: 'Confirm to delete the personnel group? ',
            batchRemove: 'Batch remove',
            personName: 'Person Name',
            personId: 'Person ID',
            pleaseInput: 'Please enter',
            searchPeopleGroupName: 'Search people group name',
            personList: 'Person List',
            searchPeople: 'Please search for people',
            selectedList: 'Selected list',
            selected: 'Selected',
            num: 'Count',
            personPhotos: 'Personnel Photos',
            remarks: 'Remarks',
            uploadPicture: 'Please upload a picture',
            inputName: 'Please enter your name according to the rules',
            selectGroup: 'Please select the group you belong to',
            groupName: 'Group Name',
            import: 'Import',
            deleteAllConfirm: 'Delete all personnel? ',
            mode: 'Mode',
            byPersonName: 'By Person Name',
            byPersonId: 'By Personnel ID',
            byPersonNameAndId: 'By Person Name and Id',
            personTotal: 'Total number of persons',
            successNum: 'Number of successful storage',
            failNum: 'Number of storage failures',
            exceptionFile: 'exception file',
            pictureSize: 'Please reselect the number of files is less than 10,000! ',
            selectFolder: 'Please select a folder for storage! ',
            selectOneGroup: 'Please select at least one group! ',
            selecteds: 'Selected',
            files: 'files',
            pleaseInputText: 'Please enter text content',
            newAdd: 'Add new',
            preserve: 'Save',
            return: 'Return',
            deleteSinglePersonTips: 'Are you sure to delete the person? ',
            deleteSelectPersonTips: 'Are you sure to delete the selected person? ',
            removeSelectPersonByGroup: 'Are you sure to remove the binding relationship between the selected person and this group? ',
            imageName: 'Image name',
            failedResult: 'Failure reason',
            time: 'Time',
            clearFailed: 'Clear the list',
            failedEntry: 'List of failures in storage',
            deleteGroupTip1: 'Unbind the personnel in the group and delete the personnel group',
            deleteGroupTip2: 'Delete the personnel group and the personnel in the personnel group. If the personnel are bound to other groups, they need to be unbound',
            deleteGroupTip3: 'Clear all the personnel in the group, keep the group, if the personnel are bound to other groups, only the personnel will be unbound',
            personInfo: 'Personnel information',
            removeSuccess: 'Remove successfully',
            clearFailureList: 'Are you sure to clear the failed storage list',
            addSuccess: 'Added successfully',
            addFail: 'Add failed',
            maxGroupTips: 'Maximum support for creating 64 personnel groups, the upper limit has been reached',
            pleaseSelectGroup: 'Please select a personnel group first',
            timeUsed: 'Time Used',
            expectedRemainder: 'Remaining time',
            somePersonRemoveFail: 'Some personnel removal failed',
            onlyGroupTips: 'Item related personnel are only associated with one personnel group and cannot be removed',
            personRemoveSuccess: 'Successfully removed',
            tryAgain: 'Try again',
            prompt: 'Prompt',
            batchImportTips1: 'After entering the name of the facial image file, it is the name of the person. Please follow the rules',
            batchImportTips2: '(1-32 Chinese and English, numbers, · and spaces cannot be entered separately)',
            uploadCompleted: 'Upload completed',
            uploadError: 'Upload error',
            facialPosition: 'Facial position',
            readFaceImg: 'Reading facial base images',
            uploadProgress: 'Upload progress',
            handleProgress: 'Processing progress',
            uploadSuccess: 'Upload successful',
            uploadFail: 'Upload failed',
            imageTotal: 'Total number of images',
            preUploadTotal: 'Total number of pre uploads',
            realityReceiveCount: 'Actual number of received items',
            receivedSuccess: 'Received successfully',
            receivedFail: 'Warehousing failed',
        },
        // 离线解析
        offlineParsing: {
            parseContent: 'Parse Content',
            parseResult: 'Parse Result',
            toOne: '1:1',
            toN: '1:N',
            featureExtraction: 'Feature Extraction',
            structuredParsing: 'Structured Parsing',
            intelligentParsing: 'Intelligent Parsing',
            uploadPic: 'Please upload the picture first',
            imageOne: 'Photo 1',
            imageTwo: 'Photo 2',
            clickUpload: 'Click to upload',
            uploadImage: 'Upload photo',
            livingScore: 'Liveness score',
            search: 'Search',
            Reset: 'Reset',
            uploadFormat: 'Upload image format',
            similarity: 'Similarity result TOP5',
            invalidFace: 'No valid face detected',
            targetType: 'Target type',
            face: 'Face',
            bodyCapture: 'Body',
            vehicle: 'Motor vehicle',
            nonMotor: 'Non-motor vehicle',
            plate: 'License Plate',
            offlineParsing1: 'Face',
            offlineParsing2: 'Body',
            offlineParsing3: 'Motor vehicle',
            offlineParsing4: 'Non-motor vehicle',
            offlineParsing5: 'License plate',
            info: 'Information',
            bodyInfo: 'Body information',
            topStyle: 'Top style',
            topColor: 'Top Color',
            lowerBodyStyle: 'Lower body style',
            lowerBodyColor: 'Lower body color',
            algorithmType: 'Algorithm Type',
            targetMap: 'Target Image',
            targetTypeTips_1: 'No face detected, please upload the picture again',
            targetTypeTips_2: 'No body detected, please upload the picture again',
            targetTypeTips_3: 'Motor vehicle not detected, please upload the picture again',
            targetTypeTips_4: 'No non-motor vehicle detected, please upload the picture again',
            targetTypeTips_5: 'The license plate is not detected, please upload the picture again',
            uploadPicture: 'Please upload a picture',
            selectedTargetType: 'Please select the target type',
            selectedGroup: 'Please select a personnel group',
            analyzing: 'Analyzing...',
            notDetected: 'Not detected in the picture',
            notDetectedSelectType: 'The selected type is not detected in the image'
        },
        menus: { // 新版菜单，老版菜单字段用menu
            preview: 'Preview',
            intelRecord: 'Records',
            intelConfig: 'Setting',
            taskConfig: 'Task Setting',
            newTask: 'New Task',
            timePlan: 'Time Plan',
            linkagePlan: 'Linkage Plan',
            offlineAnalysis: 'Offline Analyse',
            resourceManage: 'Resource',
            personManage: 'Personnel',
            deviceManage: 'Device',
            algorithmManage: 'Algorithm',
            authorManage: 'Authorization',
            containerManage: 'Container',
            docManage: 'File',
            systemManage: 'System',
            systemConfig: 'Settings',
            logManage: 'Logs',
            devOpsManage: 'Operation',
            offlineParsing: 'Offline Parsing',
            dataRecv: 'Data Integration',
            authorizationManagement: "Authorization",
            unnamed: 'Unnamed',
            falseAlarmLibrary: 'False positive library management',
        },
        preview: {
            deviceList: 'Device List',
            videoPreview: 'Video Preview',
            emptyVideoTips: 'Devices can be dragged to the video box',
            loadVideoTips: 'Loading...',
            timeoutTips: 'Video playback timeout',
            ruleConfig: 'Rule Setting',
            rule: 'Rule',
            allSelect: 'Select All',
            detail: 'Details',
            videoDevice: 'Video Stream Camera',
            imageDevice: 'Image Stream Camera',
            watching: 'Previewing'
        },
        taskConfig: {
            targetPolygon: 'Target size',
            taskName: 'Task Name',
            taskType: 'TaskType',
            taskSchedule: 'Task schedule',
            taskDetail: 'Task details',
            addTask: 'New task',
            editTask: 'Edit task',
            algCardType: 'Rule type',
            taskType_single_point_task: 'Single task',
            taskType_mutil_point_task: 'Polling task',
            deviceName: 'Device Name',
            drawArea: 'Drawing Area',
            normalArea: 'Polygon',
            normalAreaLabel: 'Detection Area',
            fullArea: 'Full Screen',
            maskArea: 'Masking Area',
            drawLine: 'Drawing Tripwire',
            singleLine: 'One-way Tripwire',
            singleLineBoth: 'Double-way Tripwire',
            preAlarmLine: 'Double-way Tripwires',
            preAlarmOneDireLine: 'One-way Tripwires',
            outInLine: 'Entry/Exit Tripwire',
            preLine: 'Prewarning Line(Double-Way)',
            alarmLine: 'Alarm Line(Double-Way)',
            preOneDireLine: 'Prewarning Line(One-Way)',
            alarmOneDireLine: 'Alarm Line(One-Way)',
            targetPreview: 'Target Frame Preview',
            clearCurrent: 'Clear Selected',
            clearAll: 'Clear All',
            emptyVideoTips: 'No device selected yet',
            noSelectToDeleteTips: 'Please select a rule first, then delete it',
            noVideoLoadedToDrawTips: 'Please wait for the video to load before drawing',
            maxNormalAreaTips: 'A single task supports drawing up to 8 detection areas',
            maxMaskAreaTips: 'A single task supports drawing up to 4 masking areas',
            maxSingleLineTips: 'A single task supports drawing up to 2 one-way single tripwires',
            maxSingleLineBothTips: 'A single task supports drawing up to 2 bidirectional single tripwires',
            maxPreAlarmLineTips: 'A single task supports drawing up to 2 sets of bidirectional double tripwires',
            maxPreAlarmOneDireLineTips: 'A single task supports drawing up to 2 sets of one-way double tripwires',
            maxOutInLineTips: 'A single task supports drawing up to 1 set of entrance and exit tripwires',
            editAttrSuccess: 'Parameter saved successfully',

            //------任务配置相关字段----------
            targetTypes: 'Target Type',
            duration: 'Delay alarm',
            targetSize: 'Target size',
            cooldownDuration: 'Alarm interval',
            group: 'Group',
            schedulePlan: 'Time Plan',
            attrAnalyze: 'Attribute Analysis',
            isOncall: 'whether to report',
            isOncallTips1: 'Report the global best stranger',
            isOncallTips2: 'Do not report the global best stranger',
            retryTimes: 'Recognition times',
            retryDuration: 'Secondary identification interval',
            strangerRetryCount: "Stranger retries",
            minPushInterval: "Minimum push interval",
            basicSettings: 'Basic Configuration',
            advancedSettings: "Advanced Configuration",
            ruleSettings: "Rule Configuration",
            algoSettgins: "Algorithm Configuration",
            regularPlan: "Regular plan",
            holidayPlan: "Festival Plan",
            createRegularPlan: "New regular plan",
            regularTime: 'Daily period',
            createTimeplan: 'New Time Plan',
            cancel: 'Cancel',
            save: 'Submit',
            relatedHolidayPlans: 'Related holiday plans',
            endDraw: 'End Draw',
            noSelected: 'Not selected',
            operateGuide: 'Operation guidance',
            operateGuide_draw_prompt: 'Note: Please draw the tripwire within the drawing area',
            operateGuide_area: 'Drawing area',
            operateGuide_area_tips1: 'Drawing: Left click to start drawing, 3-8 points can be drawn. Automatically close when the number of points reaches 8',
            operateGuide_area_tips2: 'Close: connect the starting point or right-click to close the area',
            operateGuide_area_tips3: 'Quantity: up to 8 in the detection area, up to 4 in the shielding area',
            operateGuide_line: 'Draw line',
            operateGuide_line_tips: 'Left click to start drawing, 2-4 points can be drawn. It is automatically completed when the number of points reaches 4. The alarm direction of the one-way double trip line defaults from the warning line to the warning line, which has nothing to do with the direction of the preview arrow when drawing the line. It is recommended to keep the direction of the arrow as the warning line towards the warning line when drawing to avoid misunderstanding',
            operateGuide_alg: 'Algorithm Setting',
            operateGuide_alg_tips: 'After selecting the drawn area/mixing line, click the algorithm type to be configured; click the icon in the upper right corner to configure the properties',
            noneBasicsTips: 'This algorithm has no basic configuration items. For more configurations, please click on Advanced Configuration to view',
            EngineerSurveyDoc: 'Engineering survey documents',
            taskNameTips: 'Please enter the correct task name: supports Chinese, English upper and lower case, numbers, special characters (-_), supports up to 32 characters, Chinese takes up 3 characters',
            taskScheduleTips: 'Please select a task plan',
            taskDeviceTips: 'Please select a device',
            prompt: 'Tip',
            configCardTips: 'Please configure at least one algorithm card before submitting',
            notConfigCardTips: 'Algorithm card not configured',
            finishTips: 'Please complete first',
            draw: 'Draw',
            onlyBindTips: 'Face Body &The structured algorithm can only bind one detection area',
            gotIt: 'Got it.',
            onlyBindWiresTips: 'Algorithm cards only allow binding a set of single/double tripwires',
            drawFirstTips: 'Please draw the detection area/tripwire first',
            notDrawLineTips: 'Algorithm does not draw rule lines',
            negativeFilter: 'False Sample Filter',
            useIntelRect: 'Enable target bounding box overlay',
            intelRect: 'Target Bounding Box Overlay',
            intelRectTips: 'Only face/body, structured, and perimeter algorithms support target frame overlay. If the number of targets in the picture is too high, the overlay will fail. It is recommended that there be no more than 5 targets in a single channel. If the perimeter algorithm is enabled, the detection frame will be superimposed according to the target detected by the alert algorithm. The real-time target frame will not be displayed after face/structured and other algorithms are applied at the same time.',
        },
        // 容器管理新加字段
        containerManage: {
            Running: "Running",
            Stopped: "Stopped",
            Abnormaloperation: "Abnormal operation",
            uploadContainer: "Upload container",
            uploadContaifile: "Upload container file",
            upgradeContainer: "upgrade container file",
            containeRfileFormat: "File format: .img",
            filePlay: "Play",
            fileFormat: "File format: .pcm .mp3 .MP3",
            addTo: "Add",
            cancel: "Cancel",
            operationErr: "Operation failed",
            uninstallSuccee: "Uninstallation succeeded",
            maxUploadTips: 'Up to 4 container files can be uploaded',
        },
        // 系统管理
        systemManagement: {
            exportExc: 'Export Excel',
            logManagement: "Operation Log",
            systemLog: "System Log",
            DebugLog: "Debug Log",
            dataLog: 'Data Log',
            timestamp: 'Record Time',
            operationType: "Operation Type",
            logNo: "Log ID",
            operatingUser: "User",
            logModule: "Log Module",
            logType: "Log Tyype",
            logDetails: "Details",
            logLoading: "Exporting logs, please do not close the page",
            exportLog: "Export Log",
            basicsConfig: 'Basic Setting',
            networkConfig: 'Network Setting',
            timeConfig: 'Time Setting',
            loginConfig: 'Login Setting',
            storageConfig: 'Storage Setting',
            hardwareConfig: 'Hardware Setting',
            algorithmConfig: 'Algorithm Setting',
            softwareConfig: 'Software Setting',
            configActions: 'Config Operation',
            configSystem: 'Config System',
            systemUpgrade: 'System Upgrade',
            restart: 'Restart',
            LOGOPic: 'LOGO',
            wanConfig: 'WAN Setting',
            lanConfig: 'LAN Setting',
            heartbeatServerConfig: 'Heartbeat Server Setting',
            interval: 'Time Calibration Interval',
            reset: 'Reset',
            preserval: 'Save',
            selectDate: 'Select Date',
            manualUpgrade: 'Manual Upgrade',
            upgradeMethod: 'Upgrade Method',
            systemUpgradeFile: 'system upgrade file',
            enableStatus: 'Enable Status',
            savePanorama: 'Whether to save the panorama',
            videoStorageAddr: 'Video storage address',
            accountName: 'account name',
            loginTimeOut: 'login timeout',
            loginLimit: 'login limit',
            unlockTime: 'Unlock time',
            minute: 'minutes',
            second: 'second',
            timeFrame: '1-3600s',
            configInfo: 'Setting Info',
            uploadTip: 'Upload suggestion: .jpg .png and the size does not exceed 5M',
            platformAccess: "Platform",
            alarmReporting: "Alarm Push",
            client: "Client",
            serverword: "Server",
            hasUsedAlgorithm: "Used computing power",
            algorithmStatistics: "Available computing power",
            cancelUpgrade: 'Cancel upgrade',
            decompression: 'Need to upgrade after decompression',
            formatError: 'Upload file format error',
            upgrading: 'upgrading...',
            // waitForUpgrade: '请稍等！设备升级中',
            waitForUpgrade: 'Upgrade package uploaded successfully, please wait!',
            deviceRestart: 'The device is restarting! It will jump back to the login page, please wait...',
            hardwareConfigTitle: '485 Configuration',
            alertConfig: 'alarm relay configuration',
            mode: 'mode',
            isEnable: 'whether to enable',
            timeout: 'timeout',
            timeoutRepeatNum: 'Number of timeout retransmissions',
            channel: 'channel',
            channel_1: 'channel 1',
            channel_2: 'channel 2',
            channel_3: 'channel 3',
            channel_4: 'channel 4',
            isUse: 'whether to enable',
            workMode: 'work mode',
            millisecond: 'milliseconds',
            millisecond1: 'milliseconds (100-50000)',
            second2: 'Second (0-10)',
            millisecond2: 'milliseconds (0-50000)',
            test: 'Test',
            testSuccess: 'Test succeeded',
            normalOpen: 'normally open',
            normalClose: 'normally closed',
            heartbeat: 'heartbeat packet',
            heartbeatCommandInfo: 'heartbeat command information',
            heartbeatResponseInfo: 'heartbeat response information',
            upgradeOkTips: 'Uploaded successfully, please save',
            defaultTipes: 'The default has been restored, please save! ',
            saveFail: 'Failed to save, please try again! ',
            aesKeyTips: 'Communication password must be 16 bytes',
            count: 'count',
            captureCamera: 'Image stream(Capture Cam) parameter configuration',
            globalAlgorithm: 'Global algorithm configuration',
            pictureRetryCount: 'Image stream recognition retry times',
            pictureMinInterval: 'Min recognition interval',
            pictureStrangerRetryCount: 'Strangers in the picture stream judged multiple times',
            advancedParamsConfig: "Advanced parameter configuration",
            advancedParamsConfigTip: 'Display full algorithm parameters after opening, some parameters are more complicated, it is recommended to contact technical support for help. ',
            recordStorage: 'Record storage',
            targetBoxDraw: 'Target frame drawing',
            bgcMassCoefficient: 'Background image quality coefficient',
            bgcMassCoefficientTip: 'The configuration range is 10-100. When the coefficient is set to 100, it is the original image. The smaller the coefficient, the worse the quality of the background image, but the smaller the storage space occupied',
            recordStorageNum: 'Recognition record storage number',
            nonRecordStorageNum: 'Number of non-identifying record storage',
            rtspPullFlow: 'rtsp streaming',
            rtspPullFlowTips: 'When enabled, it supports pulling the video channel on the box through the rtsp stream address; example stream address: rtsp://username:password@IP:port/channel number. ',
            dynamicBaseLibrary: 'Dynamic base library',
            dynamicBaseLibraryTips_maxNum_15: 'After opening, the base library capacity will be reduced to 15W, and the grouping will remain unchanged. ',
            dynamicBaseLibraryTips_maxNum_5: 'After opening, the base library capacity will be reduced to 5W, and the grouping will remain unchanged. ',
            dynamicBaseLibraryTips_15: 'The original base library data will be cleared and restarted (the base library will not be cleared if it does not exceed 15W). Are you sure to enable/disable it? ',
            dynamicBaseLibraryTips_5: 'The original base library data will be cleared and restarted (the base library will not be cleared if it does not exceed 5W). Are you sure to enable/disable it? ',
            realtimePreview: 'External screen real-time preview configuration',
            windowCount: 'Number of windows',
            windowChannel: 'Video channel corresponds to the window',
            window: 'Window',
            commSafe: 'Communication security',
            parityNone: 'No parity',
            parityOdd: 'Odd parity',
            parityEven: 'Even parity',
            parityMark: 'mark',
            paritySpace: 'space',
            error: 'Failed to save, please try again',
            timePlan: 'Time plan',
            lanTips: 'Active/standby mode and balanced mode, only WAN takes effect',
            intervalTips: 'The school time interval is 1-65535 minutes',
            pleaseEnterUpgradeAddr: 'Please enter the upgrade server address',
            confirmUpgrade: 'Confirm upgrade?',
            queryUpgradePackage: 'Query upgrade package',
            upgradeConfirmSuccess: 'Upgrade configuration successful',
            upgradeConfirmFail: 'Upgrade configuration failed',
            requestFail: 'request failure',
            upgradePackageNotFound: 'Upgrade package not found',
            selectTimePlan: 'Please select a time plan',
            configSuccess: 'Successfully configured',
            configFail: 'configure failed',
            logoUploadTips: 'The logo has not been uploaded yet. Please wait and resubmit',
            usernameEmptyTips: 'User name cannot be empty',
            deviceRestartTips: 'The device is restarting, please wait...',
            empty: 'Empty',
            singlePointNum: 'Number of single point tasks',
            queryWayNumTips: 'Please check the algorithm type to view the remaining number of channels that can be deployed',
            countResidueWay: 'Calculate the remaining number of tasks that can be added',
            availableWay: 'Available video channels',
            videoStream: 'video stream',
            imageStream: 'picture stream',
            or: 'or',
            selectAlgorithmTips: 'Please select algorithm type',
            sameTimeSelectTips: 'Face person and structured cannot be selected at the same time!',
            commSuccess: 'Successfully configured communication security',
            commFail: 'Communication security configuration failed',
            wanLanSuccess: 'WAN/LAN configuration successful',
            wanLanFail: 'WAN/LAN configuration failed',
            cloudAssist: 'Cloud–Edge Collaboration',
            serveStatus: 'Server Connection Status',
            successConnect: 'Connection Successful',
            failConnect: 'Connection Failure',
            dataReportType: 'Data Push Type',
            timeoutMechanism: 'Timeout Strategy',
            selectedDataReportType: 'Please select the data push type',
            policyNotice: 'Policy Notice',
            populationStatistics: 'Enter/Exit People Counting',
            enable: 'On/Off',
            statisticsInterval: 'Interval',
            alarmIoConfig: 'Alarm IO',
            breaker: {
                alarmOutput: 'Alarm Out',
                outputPort: 'Output Port',
                mode: 'Mode',
                alarmTime: 'Alarming Duration',
                isEnable: 'Off',
                open: 'Normally Open',
                close: 'Normally Close',
                msg: 'Supports up to 10 lines of expansion',
                alarmIO: 'Alarm IO'
            },
            jumpToLoginPage: 'Will be redirected to login page',
            maxBytesTips: 'Maximum support is 32 bytes',
            cnMaxBytesTips: 'Maximum support is 32 bytes (Chinese occupies 3 bytes)',
            userNameTips: 'Supports uppercase and lowercase letters and numbers, up to 32 bits',
        },
        // 数据对接新增字段
        dataDocking: {
            serverSucessIP: 'Please enter the correct server IP',
            registerName: "Registered user name",
            registerPassword: "Registered password",
            FTPtransmission: 'FTP',
            enabled: "Enabled",
            closed: "Disabled",
            serverIP: "SIP Server IP",
            ip: 'Server IP',
            pleaseEnter: "Please enter",
            serverport: "Server Port",
            connectionStatus: "connection state test",
            test: "test",
            timeInterval: "Resend interval",
            retransmissions: "Resend Times",
            uploadSnapshot: "Upload capture thumbnail",
            uploadBackground: "Upload capture background",
            transferCharacteristic: "Feature value transmission",
            reportingMode: "Push Mode",
            protocolType: "Protocol Type",
            EquipmentcodeID: "Device Code ID",
            devicedomainID: "Device Domain ID",
            transportProtocol: "Transport Protocol",
            deviceCard: "NIM",
            keepInterval: 'Keepalive Interval',
            private: "Private SDK",
            servercodeID: "SIP Server Code",
            ServerdomainID: "SIP Domain",
            ServerPort: "SIP Server Port",
            maxHeart: "Maximum Heartbeat",
            equipmentCode: "Device Code",
            addRow: "Add Row",
            serverIPaddress: "Server IP ",
            viewLibrary: "Media Library ID",
            synchronization: "Sync Interval",
            passiveMode: "Passive Mode Port",
            maxThirtyTwo: "Up to 16 items can be added",
            FtpUsername: 'The maximum length of the user name is 32 characters',
            FtpPassward: 'The maximum password length is 32 characters',
            deviceIDLength: 'Device ID length should be 20 or 0 bytes',
            alertReportMode: "Active reporting of identification information",
            captureReportMode: "Actively report the capture information",
            registerStatus_1: 'Not connected',
            registerStatus_13: 'Heartbeat timed out',
            registerStatus_14: 'Connection Failure',
            registerStatus_21: 'Registering',
            registerStatus_31: 'Connection Successful',
            gat1400Port: 'Please input the port correctly (1024-65535)',
            username: 'User Name',
            password: 'Password',
            mainServerTips: 'Please complete the main server username and password!',
            slaveServerTips: 'Please complete the slave server username and password!',
            persistReportTips: 'To enable retransmission of failed upload data, you need to fill in the main server address.',
        },
        deviceManage: {
            basicDevice: 'Basic device',
            externalDevice: 'External device',
            deviceName: 'Device name',
            deviceType: 'Device type',
            deviceAddr: 'Device address',
            relayIP: 'Relay IP',
            agreementType: 'Agreement Type',
            deviceStatus: 'Device Status',
            newDevice: 'New device',
            editDevice: 'edit device',
            findDevice: 'Discover device',
            batchDelete: 'Batch delete',
            deviceID: 'Device ID',
            manufacturer: 'Vender',
            manufacturer_unknown: 'Unknown',
            manufacturer_megvii: 'Megvii',
            ip: 'IP',
            port: 'port',
            userName: 'Username',
            password: 'password',
            autoDistribution: 'Automatic distribution',
            autoObain: 'Obtain automatically',
            enterDeviceName: 'Please enter the device name (2-32 characters in Chinese and English)',
            enterDeviceType: 'Please enter the device type',
            enterAgreementType: 'Please enter the agreement type',
            enterIp: 'Please enter a valid IP',
            enterPort: 'Please enter the port (1-65535)',
            enterUserName: 'Please enter the user name (16 characters)',
            enterPassword: 'Please enter a password (32 characters support English, numbers, and special characters)',
            enterRtspUrl: 'Please enter the correct RTSP address (a maximum of 256 characters is supported, and the username and password each support a maximum of 32 characters)',
            enterTransportType: 'Please select a transport protocol',
            enterChannelNum: 'Please enter the device channel number',
            enterGatDeviceId: 'Please enter 1400 device ID',
            enterGbDeviceID: 'Please enter the system code ID',
            enterInternationalCode: 'Please enter a 20-digit international code',
            enterSnCode: 'Please enter a 20-digit SN code',
            deviceAbility: 'Device Ability',
            batchAdd: 'Batch Add',
            exitBatchAdd: 'Exit Batch Add',
            add: 'Add',
            addDevice: 'Add device',
            rtspUrl: 'RTSP stream address',
            getDeviceInfo: 'Get device information',
            deviceState_0: 'Online',
            deviceState_1: 'Offline',
            deviceState_2: 'Authentication failed',
            deviceState_true: 'Online',
            deviceState_false: 'Offline',
            deviceState_basics0: 'Online',
            deviceState_basics1: 'Offline',
            deviceState_basics2: 'Authentication failed',
            deviceState_basics3: 'Video stream format error',
            deviceState_basics4: 'The resolution is too high',
            deviceState_basics5: 'Decoding failed',
            deviceState_basics6: 'Exceeded maximum decoding capability',
            deviceState_basics7: 'h265 multi-title mode does not support',
            agreementType_megconnect: 'megconnect',
            agreementType_onvif: 'onvif',
            agreementType_rtsp: 'rtsp',
            agreementType_gat1400: 'gat1400',
            agreementType_gb28181: 'gb28181',
            agreementType_aiotap: 'aiotap',
            channelType_0: 'Sensor',
            channelType_1: 'Video',
            channelType_2: 'Audio',
            channelType_3: 'Picture',
            channelType_4: 'Mixed', // interface enumeration 0,1,2,3 values
            transportType: 'transport protocol',
            delete: 'It will not be restored after deletion! ',
            channelNum: 'device channel number',
            gatDeviceId: '1400 Device ID',
            gbDeviceID: 'System encoding ID',
            internationalCode: 'International Code',
            modifySuccess: 'Modification succeeded',
            modifyError: 'Modification failed',
            submitSuccess: 'Submitted successfully',
            addSuccess: 'Created successfully',
            addError: 'Failed to create new',
            peripheralType: 'peripheral type',
            peripheralID: 'peripheral ID',
            enable: 'Enable switch',
            outChannel: 'Output channel',
            mode: 'work mode',
            interval: 'Trigger Interval',
            duration: 'Trigger duration',
            delay: 'Trigger delay',
            timeUnit: 'Time unit',
            serialId: 'Serial port ID',
            serialType: 'Serial port type',
            baudrate: 'Baud rate',
            dataBits: 'Data bits',
            stopBits: 'Stop bits',
            parity: 'Parity digit',
            peripheralType_relay: 'Relay',
            peripheralType_serial: 'Serial port',
            mode_0: 'Normally open',
            mode_1: 'Normally closed',
            mode_2: '485-2',
            timeUnit_0: '100 milliseconds',
            timeUnit_1: 'Second',
            parity_none: 'No parity',
            parity_odd: 'Odd parity',
            parity_even: 'Even parity',
            enterUsnAndPaw: 'Please enter account and password',
            deviceSwitch_true: 'Enable',
            deviceSwitch_false: 'Disable',
            relayIp: 'Relay IP',
            macAddr: 'MAC address',
            workMode: 'work mode',
            triggerOften: 'Trigger duration',
            triggerDelay: 'Trigger delay',
            triggerInterval: 'Consecutive trigger interval',
            startUse: 'Enable',
            gettingTips: 'Getting, please wait...',
            relayMaxTips: 'The relay device has reached the upper limit! ',
            netVideoMaxTips: 'The network camera device has reached the upper limit! ',
            intelVideoMaxTips: 'The smart camera device has reached the upper limit! ',
            selectOutChannel: 'Please select an output channel',
            selectMode: 'Please select the working mode',
            selectPeripheralType: 'Please select the peripheral type',
            enterNumber: 'Please enter a number',
            actionMode: 'Active mode',
            snCode: 'SN code',
            findDeviceMaxTips: 'The buildable device has reached the upper limit and there is no available device ID available',
            millisecond: 'ms',
            second: 's',
            triggerSuccess: 'Successfully triggered',
            triggerFail: 'Trigger failed',
            partDeviceDeleteFail: 'Partial device deletion failed',
            batchDeleteFailTips: 'The removal of the following ID devices failed. Please try again',
        },
        // 设备激活新增字段
        deviceActivation: {
            verifyPassword: "Password length is not allowed to be less than 8 characters,",
            verifySupplement: "The password must consist of three combinations of lowercase letters, uppercase letters, numbers, and special charactersa maximum of 32 characters are supported",
            learnMore: "Log in to the official website to learn more",
            firstLogin: "For the first login, please set a new password for the default account admin and activate the device",
            active: "The device is being activated",
        },
        // 授权管理
        authorizationManagement: {
            empowerSKU: "Authorization SKU",
            industryIdentification: "Industrial Identification",
            authorizationAlgorithm: "Authorization Algorithm",
            authorizationStatus: "Authorization Status",
            fileDetails: "Authorization file details",
            expireTime: "expiration time",
            onlyUpload: "Only upload the corresponding format .lic file",
            fileErr: "The file is wrong",
            authorizationActivation: "Activating, please do not close the current page",
            currentAuthorization: "Current authorization:",
            standardAuthorization: "Standard Authorization",
            customAuthorization: "Custom Authorization",
            switch: "switch",
            switchTitle: "Click to switch between standard authorization and custom authorization modes",
            switchSuccess: 'Switching successful',
        },
        // 误报库管理
        falseAlarmLibrary: {
            selectActionTips: 'Please select action tips',
            revoke: 'Cancel',
            linkSample: 'Link false sample',
            addSample: 'Add Sample',
            falseAlarmHandle: 'Handle False Alarm',
            falseAlarmTips: 'Are you sure to handle false alarm, put the records into the false sample library and delete the records? Deleted records cannot be recovered',
            filterScore: 'Filter Score',
            negativeSampleLibrary: 'False Sample Library',
            filterRecord: 'Filter Record',
            batchRevoke: 'Batch Cancel',
            sampleType: 'Sample Type',
            filterThreshold: 'Filter Threshold',
            libraryConfig: 'Library Config',
            addSampleTips: 'Do you want to add the selected records to the false sample?',
            saveSuccess: 'Saved',
            revokeRecodeTips: 'Are you sure you want to deselect the record?',
            revokeSuccess: 'Canceled',
            revokeFail: 'Failed to Cancel',
            notFindLinkTips: 'The associated false samples have been deleted and the association failed.',
            sampleInfo: 'Sample Info',
            notActionTips: 'There are no operational false alarm in the selected items.',
            removeSingleRecordTips: 'Are you sure to remove the selected records?',
            removeSuccess: 'Removed',
            removeFail: 'Failed to remove',
            notLinkSampleTips: 'The current record algorithm type no longer exists, and the operation associated negative samples is not supported.',
            notBatchLinkSampleTips: 'There are no operable records currently selected.',
        },
        // 联动计划
        linkagePlan: {
            enterTimeName: "Please enter the correct plan name",
            enableFilling: "Enable audio linkage. Please fill in the algorithm type and audio file",
            notDeployed: '【This algorithm is not deployed】',
            timeCientTip: "If you don't fill in the validity period, it will be permanent by default",
            deviceNotConfigured: "The device has not been configured with tasks, please complete the configuration under the [Task Configuration] menu first",
            handchangeAlgorithm: "Please select an algorithm",
            changeChannels: "Please choose to bind the device",
            changeTimeplan: "Please select a time plan",
            changePeople: "Please select a people group",
            ChangeType: "Please select an algorithm type",
            enterDeviceName: 'Please enter the name of the linkage plan (support Chinese, English uppercase and lowercase, numbers, special characters (including -_), a maximum of 32 characters are supported, and Chinese accounts for 3 characters)',
            clickSearch: "Click to start searching",
            deleteAllPlan: "Are you sure to delete all linkage plans?",
            deleteSelectPlan: "Are you sure to delete the selected plan?",
            deleteSelectTask: "Are you sure to delete the selected linkage plan?",
            planName: "Plan Name",
            linkageAction: "Linkage action",
            newPlan: "New Plan",
            editPlan: 'Edit Plan',
            rulesName: "Linkage plan name",
            changeDevice: "Select Device",
            changeAlgorithm: "Select Algorithm",
            audioLinkage: "Audio Linkage",
            alarmNum: 'Number of alarms',
            triggerInterval: "Trigger Interval",
            policeNum: "Alarm switch output",
            testSuccess: "Test succeeded",
            delayTime: 'Trigger delay',
            triggerTime: 'Trigger duration',
            millisecond: "milliseconds",
            continuityInterval: 'continuous trigger interval',
            bindChannel: "Bind device",
            validityPassage: "Validity Passage",
            onSubmit: "Confirm",
            cancel: "Cancel",
            interfaceOne: "Interface 1",
            interfaceTwo: "Interface 2",
            foundSucess: 'created successfully',
            err22001: 'The linkage plan name is invalid',
            err22002: 'Linkage plan already exists',
            err22003: 'Linkage plan does not exist',
            err22004: 'The linkage plan exceeds the upper limit',
            err22005: 'The device has been bound to a linkage plan and cannot be deleted',
            err22006: 'The relay has been bound to a linkage plan, it is not allowed to delete',
            err22007: 'The personnel group has been bound to a linkage plan, and deletion is not allowed',
            err22008: 'Personnel group has been bound to deployment and control tasks, it is not allowed to delete',
            instruct: 'Instruct',
            interface: 'RS485',
            commandConfig: 'Command Config',
            submitTips: 'The current device does not have a configurable algorithm and linkage cannot be configured.',
            perfectAudioTip: 'Please complete the audio file corresponding to the algorithm type within audio linkage',
            selectRelayTip: 'To enable relay configuration, please select the algorithm type and binding device.',
            perfectRelayTip: 'Please complete the binding device corresponding to the algorithm type within relay configuration.',
            perfectRsTip: 'Please complete the 485 interface corresponding to the algorithm type within RS485',
            selectIoTip: 'To enable alarm output, please select the algorithm type and binding device.',
            perfectIoTip: 'Please complete the alarm IO port corresponding to the alarm output algorithm type.',
            configLeastOneTip: 'Please configure at least one linkage task',
        },
        schedulePlan: {
            Defaultschedule: 'Default Plan',
            modifyTimePlanName: 'Modify Time Plan Name',
            planName: 'Plan name',
            newHolidayPlan: 'New holiday plan',
            editPlan: 'Edit plan',
            repeatPlan: 'Repetitive Plan',
            noRepeat: 'No repeat',
            annually: 'Annually',
            HolidayPeriod: 'Holiday period',
            selectTime: 'Selection time',
            add: 'Add',
            deleteTimePlan: 'Delete Time Plan',
            deleteConfirmTips: 'Are you sure to delete the current time plan?',
            notDelete: 'Not deleted temporarily',
            loading: 'Loading',
            inputPlanName: 'Please enter a plan name',
            planNameTips: 'Supports Chinese, English capitalization, numbers, special characters (including - _), up to 32 characters',
            selectRepeatPlan: 'Please select a duplicate plan',
            timeOverlap: 'Time periods overlap',
            minSelectTips: 'Please select at least one holiday period',
            minDrawTips: 'Please draw at least one time period',
            maxPlanConfigTips: 'Planned configuration limit of 50',
            maxHolidayTips: 'The set holiday time period cannot exceed 16 periods',
            addAgain: 'Do not add again',
            none: 'None',
            startDate: 'Start date',
            endDate: 'End date',
            timePicker: 'Time Picker',
            confirm: 'Confirm',
            Mon: 'Mon',
            Tues: 'Tues',
            Wed: 'Wed',
            Thur: 'Thur',
            Fri: 'Fri',
            Sat: 'Sat',
            Sun: 'Sun',
            copyTo: 'Copy to',
            deleteTimePeriod: 'Delete time period',
            confirmDelete: 'Are you sure to delete the current time period?',
            maxConfigTimeTips: 'The set time schedule time period cannot exceed 8 periods',
            selectTimeRangeTips: 'Please select the correct time range',
            success: 'Success'
        },

        // 报警类型关系表
        alarmType: {
            // perimeter_alert
            park: 'No Parking',
            exit: 'Vehicle Leave',
            person_wander: 'Personnel Wandering',
            wander: 'Personnel wandering',
            Overwall: 'Personnel crossing - over wall detection',
            intrusion: 'Intrusion',
            tripwire: 'Out of bounds',
            electric_bike_in_elevator: 'Electirc scooter in elevator',
            mask_area: 'Masking area',
            // headcount_alarm
            head_count: 'Regional People Counting',
            cross_line: 'Entrance/Exit People Counting',
            // behaviour_alert
            fall: 'Fall',
            smoke: 'Smoking',
            call: 'Call',
            watch_phone: 'Watching at the mobile',
            run: 'Run',
            sleep: 'Sleep on duty',
            person_off_duty_querying: 'Personnel On duty/Off duty-Off duty',
            gathering: 'Personnel Gathering',
            fight: 'Fight',
            person_over_querying: 'Overstaffed',
            person_less_querying: 'Understaffed',
            holdweapon: 'Weapon',
            // mask_area: '屏蔽区域',

            // goods_alarm
            sundry_detect: 'Unattended Objects',
            goods_forget: 'Objects left',
            goods_guard: 'Ojbects Guard',
            // face_basic_business
            face_capture: '',
            body_capture: '',
            face_comparison_successful: '',
            stranger: '',
            non_living_attack: '',
            no_wear_respirator: '',
            suspicious_target: '',
            update_feature: '',
            // access_control
            // non_living_attack: '',
            import_person_try_to_pass: '',
            stranger_try_to_pass: '',
            normal_access_of_staff: '',
            abnormal_access_of_staff: '',
            normal_access_of_visitor: '',
            abnormal_access_of_visitor: '',
            // no_wear_respirator: '',
            fire_control: '',
            door_always_open: '',
            doorbell_rings: '',
            // suspicious_target: '',
            // equipment_operation

            structure: 'Structure',
            face: 'Face',
            faces: 'Faces',
            pedestrian: 'Body',
            pedestrians: 'Body',
            vehicle: 'Motor Vehicle',
            vehicles: 'Motor Vehicles',
            non_motor: 'non-motor vehicle',
            non_motors: 'non-motor vehicles',
            plate: 'license plate',
            plates: 'license plate',
            // skeleton_alarm
            situps: 'sit-ups',
            situps_ready_to_start: 'Sit-ups preparation action',
            situps_complete_action: 'Sit-ups complete action',
            situps_incomplete_action: 'Sit-ups illegal action',
            pullups: 'pull up',
            pullups_ready_to_start: 'Pull-up preparation action',
            pullups_complete_action: 'Pullup complete action',
            pullups_incomplete_action: 'Pullup violation action',
            longjump: 'Standing long jump',
            longjump_ready_to_start: 'Standing long jump preparation action',
            longjump_complete_action: 'Standing long jump complete action',
            longjump_incomplete_action: 'Standing long jump illegal action',
            // safety_alarm
            no_safetycap: 'Not wearing helmet',
            no_uniform: 'Not wearing uniform',
            no_safetybelt: 'Not wearing safety belt',
            flame: 'Flame',
            smog: 'Smoke',
            oil_spill: 'Liquid Leak',
            no_vest: 'Not wearing reflective vest',
            lose_fire_equipment: 'Fire protection facilities (including fire extinguishers and fire extinguishing boxes)',
            no_respirator: 'Mask',
            // city_alarm
            hawker: 'traveling hawker',
            outstore: 'outside store operation',
            roadside: 'Occupy Road Operation',
            sundrystack: 'Sundries occupy the road',
            muck: 'Pile up muck',
            exposed_garbage: 'exposed garbage',
            outdoor_adv: 'outdoor advertisement',
            watergather: 'road water',
            // mclz_alarm
            trashbin: 'Trash bin not covered',
            discover_mice: '',
            no_chef_cloth: 'Not wearing chef clothes',
            no_chef_hat: 'Not wearing a chef hat',
            no_face_respirator: 'Not wearing a mask',
            no_rubber_glove: 'No rubber gloves',
            flame_without_human: 'Flame without human',
            // sdwe_alarm
            indicator_flag: 'Static line (flag detection) alarm',
            oil_pipe: 'oil unloading pipe alarm',
            oilpump_door_open: 'Oil pump door open',
            oil_gun_drag: 'The oil gun is broken',
            // flame: '火焰报警',
            // smog: '烟雾报警',
            // lose_fire_equipment: '消防设施检测',

            // fire_alarm
            overflowed_garbage: 'Garbage overflow',
            // exposed_garbage: '垃圾暴露',
            // flame: '',
            // smog: '',
            // lose_fire_equipment: '',

            // gkpw_alarm
            falling_goods: 'Throw from high building',
            // bzj_alarm
            hand: 'Hand',
            trash_exist: 'garbage detection',
            climb: 'climbing detection',
            image_cover_alert: 'The picture is abnormal',
        },
        newStructure: {
            gender_type1: 'Unknown',
            gender_type2: 'male',
            gender_type3: 'female',
            // beard type
            beard_class: 'beard type',
            beard_class_type1: 'Unknown',
            beard_class_type2: 'No beard',
            beard_class_type3: 'horizontal mustache',
            beard_class_type4: 'beard',
            beard_class_type5: 'one character mustache',
            beard_class_type6: 'Light beard',
            beard_class_type7: 'Goatee',
            beard_class_type8: 'White beard',
            beard_class_type9: 'A little Hu',
            // glasses style
            glasses_style: 'glasses style',
            glasses_style_type1: 'Unknown',
            glasses_style_type2: 'full frame',
            glasses_style_type3: 'half frame',
            glasses_style_type4: 'Frameless',
            glasses_style_type5: 'eyebrow frame',
            glasses_style_type6: 'Omnibox',
            glasses_style_type7: 'chromatic mirror',
            glasses_style_type8: 'Sunglasses',
            glasses_style_type9: 'No glasses',
            glasses_style_type10: 'transparent color',
            glasses_style_type11: 'Dark frame transparent glasses',
            glasses_style_type12: 'Ordinary transparent glasses',
            glasses_style_type13: 'Sunglasses',
            // hairstyle
            hair_style: 'hairstyle',
            hair_style_type1: 'Unknown',
            hair_style_type2: 'flat head',
            hair_style_type3: 'Center',
            hair_style_type4: 'Partial Partial',
            hair_style_type5: 'frontal baldness',
            hair_style_type6: 'Style bald',
            hair_style_type7: 'All bald',
            hair_style_type8: 'curly hair',
            hair_style_type9: 'Wavy hair',
            hair_style_type10: 'twist braid',
            hair_style_type11: 'hair style',
            hair_style_type12: 'Shawl',
            hair_style_type13: 'short hair',
            hair_style_type14: 'long hair',
            // hat type
            hat_style: 'hat type',
            hat_style_type1: 'Unknown',
            hat_style_type2: 'Wool hat',
            hat_style_type3: 'beret',
            hat_style_type4: 'baseball hat',
            hat_style_type5: 'flat hat',
            hat_style_type6: 'fisherman hat',
            hat_style_type7: 'Headed hat',
            hat_style_type8: 'cap',
            hat_style_type9: 'big brim hat',
            hat_style_type10: 'hard hat',
            hat_style_type11: 'Chef hat',
            // hat color
            hat_color: 'hat color',
            hat_color_type1: 'Unknown',
            hat_color_type2: 'Black',
            hat_color_type3: 'white',
            hat_color_type4: 'gray',
            hat_color_type5: 'red',
            hat_color_type6: 'orange',
            hat_color_type7: 'yellow',
            hat_color_type8: 'green',
            hat_color_type9: 'dark blue',
            hat_color_type10: 'light blue',
            hat_color_type11: 'purple',
            hat_color_type12: 'pink',
            hat_color_type13: 'brown',
            // mask type
            respirator_class: 'mask type',
            respirator_class_type1: 'Unknown',
            respirator_class_type2: 'Medical masks',
            respirator_class_type3: 'Smog mask',
            respirator_class_type4: 'Ordinary mask',
            respirator_class_type5: 'Transparent kitchen masks',
            // skin color
            skin_color: 'skin color',
            skin_color_type1: 'Unknown',
            skin_color_type2: 'Black',
            skin_color_type3: 'white',
            skin_color_type4: 'yellow',
            skin_color_type5: 'Dark',
            // top color
            coat_color: 'Coat color',
            coat_color_type1: 'Unknown',
            coat_color_type2: 'Black',
            coat_color_type3: 'white',
            coat_color_type4: 'gray',
            coat_color_type5: 'red',
            coat_color_type6: 'Orange',
            coat_color_type7: 'yellow',
            coat_color_type8: 'green',
            coat_color_type9: 'dark blue',
            coat_color_type10: 'light blue',
            coat_color_type11: 'purple',
            coat_color_type12: 'pink',
            coat_color_type13: 'brown',
            coat_color_type14: 'color',
            // top type
            coat_length: 'Coat type',
            coat_length_type1: 'Unknown',
            coat_length_type2: 'long sleeve',
            coat_length_type3: 'short sleeve',
            coat_length_type4: 'Sleeveless',
            // pants type
            pants_length: 'pants type',
            pants_length_type1: 'Unknown',
            pants_length_type2: 'Pants',
            pants_length_type3: 'shorts',
            // pants color
            pants_color: 'pants color',
            pants_color_type1: 'Unknown',
            pants_color_type2: 'Black',
            pants_color_type3: 'white',
            pants_color_type4: 'gray',
            pants_color_type5: 'red',
            pants_color_type6: 'Orange',
            pants_color_type7: 'yellow',
            pants_color_type8: 'green',
            pants_color_type9: 'dark blue',
            pants_color_type10: 'light blue',
            pants_color_type11: 'purple',
            pants_color_type12: 'pink',
            pants_color_type13: 'brown',
            pants_color_type14: 'blue',
            pants_color_type15: 'cyan',
            // 下衣类型
            wear_lower_skirt: 'Lower garment type',
            wear_lower_skirt_type1: 'unknown',
            wear_lower_skirt_type2: 'pants',
            wear_lower_skirt_type3: 'skirt',
            // car brand
            brand: 'car brand',
            brand1: "Other",
            brand2: "Volkswagen",
            brand3: "Buick",
            brand4: "BMW",
            brand5: "Honda",
            brand6: "Peugeot",
            brand7: "Toyota",
            brand8: "Ford",
            brand9: "Nissan",
            brand10: "Audi",
            brand11: "Mazda",
            brand12: "Chevrolet",
            brand13: "Citroen",
            brand14: "Modern",
            brand15: "Chery",
            brand16: "Kia",
            brand17: "Roewe",
            brand18: "Mitsubishi",
            brand19: "Skoda",
            brand20: "Auspicious",
            brand21: "China",
            brand22: "Volvo",
            brand23: "Lexus",
            brand24: "Fiat",
            brand25: "East Wind",
            brand26: "BYD",
            brand27: "Suzuki",
            brand28: "Gold Cup",
            brand29: "Seahorse",
            brand30: "Wuling",
            brand31: "Jianghuai",
            brand32: "Subaru",
            brand33: "The Great Wall",
            brand34: "Qingling (Isuzu)",
            brand35: "Southeast",
            brand36: "Chang'an",
            brand37: "Futian",
            brand38: "Benz",
            brand39: "FAW",
            brand40: "IVECO",
            brand41: "Lifan",
            brand42: "Reno",
            brand43: "MG",
            brand44: "Zotye",
            brand45: "Golden Dragon",
            brand46: "Yellow Sea",
            brand47: "Porsche",
            brand48: "Cadillac",
            brand49: "Jeep",
            brand50: "Land Rover",
            brand51: "Baojun",
            brand52: "Carry",
            brand53: "BAIC",
            brand54: "Luxgen",
            brand55: "Chrysler",
            brand56: "GAC",
            brand57: "Hafei",
            brand58: "Kama",
            brand59: "Changhe",
            brand60: "Shanghai Huizhong",
            brand61: "Hagrid",
            brand62: "Yutong",
            brand63: "Sinotruk",
            brand64: "Beiben Heavy Truck",
            brand65: "Valin Star Horse",
            brand66: "Leap Forward Car",
            brand67: "Infiniti",
            brand68: "Changfeng Cheetah",
            brand69: "Shaanqi Heavy Truck",
            brand70: "Ankai",
            brand71: "Zhongtong",
            brand72: "Landwind",
            brand73: "Beijing",
            brand74: "Weilin",
            brand75: "Opel",
            brand76: "Huapu",
            brand77: "Aura",
            brand78: "ZTE",
            brand79: "Jaguar",
            brand80: "Tang Jun Ou Ling",
            brand81: "Foday",
            brand82: "Lotus",
            brand83: "Double Ring",
            brand84: "Yongyuan",
            brand85: "Dodge",
            brand86: "Universal Car",
            brand87: "Northern Bus",
            brand88: "Bentley",
            brand89: "Huatai",
            brand90: "Jiangling",
            brand91: "Tesla",
            brand92: "Hummer",
            brand93: "Lincoln",
            brand94: "GMC",
            brand95: "SAIC",
            brand96: "Double Dragon",
            brand97: "Friendship",
            brand98: "Flying Saucer",
            brand99: "Nanjun CNJ",
            brand100: "Maserati",
            brand101: "Dafa",
            brand102: "Ferrari",
            brand103: "Balong Heavy Truck",
            brand104: "Lamborghini",
            brand105: "Sany Heavy Industry",
            brand106: "Golden Brigade",
            brand107: "Zhongshun",
            brand108: "United Trucks",
            brand109: "Red Rock",
            brand110: "MINI",
            brand111: "Hengtong",
            brand112: "Black Panther",
            brand113: "Five Signs",
            brand114: "Liberation",
            brand115: "Qingchen",
            brand116: "Asia Star",
            brand117: "Idea",
            brand118: "smart",
            brand119: "Shaolin",
            brand120: "Youth",
            brand121: "British",
            brand122: "Charade",
            brand123: "Time Car",
            brand124: "Shenlong",
            brand125: "Daewoo",
            brand126: "Qichen",
            brand127: "Mustang",
            brand128: "Ruilin",
            brand129: "Jiangnan",
            brand130: "Kowloon",
            brand131: "Schu Chi Bus",
            brand132: "Red Flag",
            // motor vehicle type
            vehicle_class: 'Motor vehicle type',
            vehicle_class_type1: 'Unknown',
            vehicle_class_type2: 'Small car',
            vehicle_class_type3: 'SUV',
            vehicle_class_type4: 'Van',
            vehicle_class_type5: 'Minibus / Bus',
            vehicle_class_type6: 'Pickup',
            vehicle_class_type7: 'Truck / Van',
            vehicle_class_type8: 'Dirt Vehicle',
            vehicle_class_type9: 'Concrete mixer truck',
            vehicle_class_type10: 'Fire Truck',
            vehicle_class_type11: 'school bus',
            vehicle_class_type12: 'Police Vehicle',
            vehicle_class_type13: 'Ambulance',
            vehicle_class_type14: 'Container Vehicle',
            vehicle_class_type15: 'Excavator',
            // vehicle color
            vehicle_color: 'vehicle color',
            vehicle_color_type1: 'Unknown',
            vehicle_color_type2: 'Black',
            vehicle_color_type3: 'white',
            vehicle_color_type4: 'Silver Gray',
            vehicle_color_type5: 'Dark Gray',
            vehicle_color_type6: 'brown',
            vehicle_color_type7: 'red',
            vehicle_color_type8: 'Orange',
            vehicle_color_type9: 'yellow',
            vehicle_color_type10: 'green',
            vehicle_color_type11: 'cyan',
            vehicle_color_type12: 'blue',
            vehicle_color_type13: 'pink',
            vehicle_color_type14: 'Purple',
            vehicle_color_type15: 'Champagne',
            vehicle_color_type16: 'color',
            //Motor vehicle orientation type
            vehicle_orientation: 'Motor vehicle orientation',
            vehicle_orientation_type1: 'Unknown',
            vehicle_orientation_type2: 'forward',
            vehicle_orientation_type3: 'Backward',
            vehicle_orientation_type4: 'sideways',
            //Non-motor vehicle type
            non_motor_class: 'Non-motor vehicle type',
            non_motor_class_type0: 'Unknown',
            non_motor_class_type1: 'bicycle',
            non_motor_class_type2: 'Electric Vehicle',
            non_motor_class_type3: 'Motorcycle',
            non_motor_class_type4: 'Tricycle',
            //type of license plate
            plate_class: 'Type of license plate',
            plate_class_type1: "Unknown",
            plate_class_type2: "Large car number plate",
            plate_class_type3: "Small car license plate",
            plate_class_type4: "Embassy car number plate",
            plate_class_type5: "Consulate car number plate",
            plate_class_type6: "Overseas car license plate",
            plate_class_type7: "Foreigner Cycling Number Plate",
            plate_class_type8: "Ordinary motorcycle license plate",
            plate_class_type9: "Moped license plate",
            plate_class_type10: "Embassy motorcycle plate",
            plate_class_type11: "Consulate motorcycle number plate",
            plate_class_type12: "Foreign motorcycle license plate",
            plate_class_type13: "Foreign motorcycle license plate",
            plate_class_type14: "Low-speed license plate",
            plate_class_type15: "Tractor plate",
            plate_class_type16: "Trailer plate",
            plate_class_type17: "Coach car number plate",
            plate_class_type18: "Coach Motorcycle Number Plate",
            plate_class_type19: "Temporary Entry Cycling Plate",
            plate_class_type20: "Motorcycle license plate for temporary entry",
            plate_class_type21: "Temporary Driving License Plate",
            plate_class_type22: "Police car number plate",
            plate_class_type23: "Police Motorcycle Number Plate",
            plate_class_type24: "Original Agricultural Machinery Plate",
            plate_class_type25: "Hong Kong Entry and Exit Number Plate",
            plate_class_type26: "Macau entry and exit number plate",
            plate_class_type27: "WJ number plate",
            plate_class_type28: "JD number plate",
            // license plate type
            plate_row: 'license plate type',
            plate_row_type1: 'Unknown',
            plate_row_type2: 'One-way license plate',
            plate_row_type3: 'Double row license plate',
            // license plate color
            plate_color: 'License plate color',
            plate_color_type1: "Unknown",
            plate_color_type2: "Black",
            plate_color_type3: "White",
            plate_color_type4: "Blue",
            plate_color_type5: "Yellow",
            plate_color_type6: "Green",
            plate_color_type7: "Small New Energy",
            plate_color_type8: "Large New Energy",
            // number plate
            plate_no: 'license plate number',
            // backpack state
            carry_bag: 'backpack status',
            carry_bag_type1: 'Unknown',
            carry_bag_type2: 'No backpack',
            carry_bag_type3: 'Backpack',
            // three-valued logic
            ternary_type1: 'Unknown',
            ternary_type2: 'none',
            ternary_type3: 'Yes',
            // Three-valued logic - wear/not wear
            wear_ternary_type1: 'Unknown',
            wear_ternary_type2: 'Not wearing',
            wear_ternary_type3: 'wear',
        },
        ALARMTYPE: {
            // alert - perimeter alarm
            PARK: 'Vehicles are not allowed to park',
            EXIT: 'Vehicle leaves',
            WANDER: 'Personnel wandering',
            OVERWALL: 'overwall detection',
            INTRUSION: 'Intrusion',
            TRIPWIRE: 'Out of bounds',
            CLIMB: 'Climbing Detection',
            ELECTRIC_BIKE_IN_ELEVATOR: 'Bike into the elevator',
            mask_area: 'shielded area',

            // People Counting
            MAX_NUMBER_PEOPLE: 'Area population statistics',
            CROSS_LINE: 'Entrance and exit statistics',

            // Behavior
            FALL: 'Fall detection',
            SMOKING: 'Smoking detection',
            CALL: 'call',
            WATCH_PHONE: 'watch phone',
            RUN: 'Personnel running',
            SLEEP: 'Sleep detection',
            LEAVE_POST: 'Leave',
            GATHERING: 'People Gathering',
            FIGHT: 'Personnel scuffle',
            PERSON_OVER_QUERYING: 'Over staff',
            PERSON_LESS_QUERYING: 'Less staff',
            HOLDWEAPON: 'Hold Weapon Detection',

            // thing
            SUNDRY_DETECT: 'Sundry piled up',
            GOODS_FORGET: 'Goods left behind',
            GOODS_GUARD: 'Goods Guard',

            structure: 'structured',
            face: 'face person',
            face_basic_business: 'face person',

            // safety supervisor
            SAFETY_CAP: 'Alarm if not wearing a safety helmet',
            SAFETY_UNIFORM: 'Alarm if not wearing safety overalls',
            SAFETY_BELT: 'Not wearing a seat belt alarm',
            FIRE: 'Fire Alarm',
            SMOKE: 'Smoke alarm',
            OIL_SPILL: 'Liquid leak detection',
            REFLECTIVE_VEST: 'Alarm if not wearing reflective clothing',
            FIRE_EQUIPMENT: 'Detection of fire facilities',
            RESPIRATOR: 'Mask',

            // urban management
            HAWKER: 'Traveler',
            OUTSTORE: 'outside store operation',
            ROADSIDE: 'Occupy Road Operation',
            SUNDRYSTACK: 'Debris occupying the road',
            MUCK: 'Stack muck',
            EXPOSED_GARBAGE: 'Exposed Garbage',
            OUTDOOR_ADV: 'Outdoor Advertisement',
            WATERGATHER: 'Water on the road',
            OVERFLOWED_GARBAGE: 'Garbage overflowing',
            MUCK_TRUCK: 'Identification of waste truck',
            OPENED_MUCKTRUCK: 'The garbage truck is not covered with tarpaulin',

            // bright kitchen
            TRASHBIN: 'Trash bin not covered alarm',
            MICE: 'mouse',
            CHEF_CLOTH: 'Not wearing chef clothes',
            CHEF_HAT: 'Not wearing a chef hat',
            CHEF_RESPIRATOR: 'Not wearing a mask',
            RUBBER_GLOVE: 'Not wearing rubber gloves',
            FLAME_WITHOUT_HUMAN: 'Human with fire',

            // Gas station
            INDICATOR_FLAG: 'Electrostatic line alarm',
            OIL_PIPE: 'Oil unloading pipe alarm',
            OILPUMP_DOOR_OPEN: 'The side cover of the oil pump is open',
            OIL_GUN_DRAG: 'The oil pipe is broken',

            // high altitude parabola
            FALLING_GOODS: 'High-altitude parabolic',
            // video diagnostics
            IMAGE_COVER_ALERT: 'The picture is abnormal',

            situps: 'sit-ups',
            situps_ready_to_start: 'Sit-ups preparation action',
            situps_complete_action: 'Sit-ups complete action',
            situps_incomplete_action: 'Sit-ups illegal action',
            pullups: 'pull up',
            pullups_ready_to_start: 'Pull-up preparation action',
            pullups_complete_action: 'Pullup complete action',
            pullups_incomplete_action: 'Pullup violation action',
            longjump: 'Standing long jump',
            longjump_ready_to_start: 'Standing long jump preparation action',
            longjump_complete_action: 'Standing long jump complete action',
            longjump_incomplete_action: 'Standing long jump illegal action',

            'face-face_capture': 'Face/Body-Face Capture',
            'face-body_capture': 'Face/Body-Body Capture',
            'face-face_comparison_successful': 'Face/Body-Face Recognition',
            'face-stranger': 'Face/Body-Stranger',
            
            'structure-face': 'Structure-Face Capture',
            'structure-pedestrian': 'Structure-Body Capture',
            'structure-vehicle': 'Structure-Motor Vehicle Capture',
            'structure-non_motor': 'Structure-Non-Motor Vehicle Capture',
            'structure-plate': 'Structure-License Plate Capture',
        },
        targetType: {
            faces: 'Face',
            pedestrians: 'Body',
            vehicles: 'Motor Vehicle',
            "non-motors": 'Non-motor vehicle',
            plate: 'License Plate',
            plates: 'License Plates', // 当前识别记录车牌返回plates
            frequency: "Recognition frequency",
            label: "Label",
            label2: "Label 2",
            lowNoHigh: 'The low threshold cannot be greater than the high threshold',
            highNoSmall: "The high threshold cannot be less than the low threshold",
        }
    }
};

export default en;
